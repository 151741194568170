// React
import React, { useState } from 'react';
// Firebase
import { auth, isLocal } from '../../services/firebase.config';
import { useSendPasswordResetEmail } from 'react-firebase-hooks/auth';
// Components
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Spinner from 'react-bootstrap/Spinner';
import EmailIcon from '../Icons/EmailIcon';
import DashboardAlert from '../Alert/DashboardAlert';

/**
 * The form to reset password by sending a reset email to the user.
 * @component
 * @param {Object} props - The component accepts props.
 * @param {React.JSX.Element} props.children - Child element.
 * @returns Password reset form component. 
 */
const PasswordResetForm = ({children}) => {

  const [email, setEmail] = useState('');
  const [sendPasswordResetEmail, sending, error] = useSendPasswordResetEmail(auth);
  const [passwordResetMessage, setPasswordResetMessage] = useState('');

  const actionCodeSettings = {
    url: isLocal ? 'http://localhost:3000' : 'https://app.hemsonanalytics.com',
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setPasswordResetMessage('');


    const success = await sendPasswordResetEmail(email, actionCodeSettings);

    if (success) {

      setPasswordResetMessage(`An email with instructions to reset your password has been sent to ${email}.`);
      setEmail('');

    }
  };

  const errorMessage = () => {
    if (error) {
      if (error.message.includes('auth/user-not-found')) {
        return 'Email not found.';
      } else {
        return error.message;
      }
    } else {
      return '';
    }
  }

  return (
    <Form onSubmit={handlePasswordReset}>
      <InputGroup className="mb-2">
        <InputGroup.Text id="password-reset-icon">
          <EmailIcon width={16}/>
        </InputGroup.Text>
        <Form.Control
          disabled={sending}
          required
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          aria-describedby="resetEmail"
        />
      </InputGroup>
      <Form.Text id="resetEmail" muted>
        If you have an account we'll email you a reset link.
      </Form.Text>
      <Form.Group className='mb-4 text-start'>
        {children}
      </Form.Group>
      <div className="d-grid mb-3">
        <Button variant="primary" className='fw-bold icon-link icon-link-hover' type="submit" disabled={sending}>
          {sending ? 
            <Spinner size="sm" className='ms-auto'/>
            :
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-return-right ms-auto" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5"/>
            </svg>
          }
          <span className='me-auto'>Reset Password</span>
        </Button>
      </div>
      <div className="mb-3">
        <DashboardAlert
          variant={error ? 'danger' : 'success'}
          text={error ? errorMessage() : passwordResetMessage}
          display={error || passwordResetMessage !== '' ? true : false}
        />
      </div>
    </Form>
  );
}

export default PasswordResetForm;