// React
import { useState } from "react";
// Firebase
import { db } from '../../services/firebase.config';
import { doc, updateDoc, deleteField } from "firebase/firestore";
import { useDocument } from 'react-firebase-hooks/firestore';
// Components
import Dropdown from 'react-bootstrap/Dropdown';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { useTooltipsContext } from "../Context/TooltipsContext";
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from "react-bootstrap/Form";
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';
import FolderMinusIcon from "../Icons/FolderMinusIcon";
import Collapse from 'react-bootstrap/Collapse';
// Helpers
import { dashboardType } from "../../configuration/dashboardNames";

/**
 * A modal component. The modal has Yes and Cancel buttons to verify a user's choice and call a callback once the Yes button is clicked.
 * @component
 * @param {Object} props - The component accepts props.
 * @param {boolean} props.show - Show modal.
 * @param {function(Event): void} props.handleClose - A callback to run when model is closed.
 * @param {function(Event): void} props.handleYes - A callback run when Yes button is clicked.
 * @param {React.JSX.Element | string} props.text - Body text in the modal.
 * @param {React.JSX.Element | string} props.title - Titel of the modal.
 * @returns A modal component.
 */
const YesNoModal = ({
  show,
  handleClose,
  handleYes,
  text,
  title
}) => {

  const confirmationHandler = () => {
    handleYes();
    handleClose();
  }

  return (
    <Modal 
      show={show} 
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton className="bg-primary-subtle bg-gradient">
        <Modal.Title className="icon-link">
          {title}
        </Modal.Title>
      </Modal.Header>
      
      <Modal.Body>
        {text}
      </Modal.Body>
      
      <Modal.Footer className="bg-primary-subtle bg-gradient">
        <Button variant="outline-success" className="fw-bold icon-link icon-link-hover" onClick={confirmationHandler}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
            <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
          </svg>
          Yes
        </Button>
        <Button variant="outline-danger" className="fw-bold icon-link icon-link-hover" onClick={handleClose}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
          </svg>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

/**
 * A dropdown menu with the list of saved presets.
 * @component
 * @param {Object} props - The component accepts props.
 * @param {string} props.dashboard - Dashboard selection value.
 * @param {Objects[]} props.municipality - Municipality selection value.
 * @param {Objects[]} props.municipalities - Municipalities selection value.
 * @param {number[]} props.years - Array of start and end years.
 * @param {boolean} props.dashboardLoading - Dashboard loading spinner state.
 * @param {boolean} props.user - User object.
 * @param {function(Event): void} props.handlePresetChange - Callback to handle preset municipalities change.
 * @returns The dropdown menu for preset municipalities.  
 */
const Preset = ({ 
  dashboard,
  municipality,
  municipalities,
  years,
  dashboardLoading,
  user,
  handlePresetChange
}) => {

  const presetRef = doc(db, 'preset', user.uid);

  const [presetName, setPresetName] = useState('');
  const [message, setMessage] = useState('');
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [memoryName, setMemoryName] = useState('');

  const [value, loading, error] = useDocument(presetRef,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  /* -------------------------------- Tooltips -------------------------------- */

  const { tooltips } = useTooltipsContext();

  const PresetTooltip = (props) => (
    <Tooltip id="presetTooltip" {...props}>
      <div className='text-start'>
        <p className='mb-0'>✔ Save a selection of municipalities. Save a selection by giving the preset list a name. Load a selection to the municipality menu by clicking a saved preset.</p>
      </div>
    </Tooltip>
  );
  
  /**
   * A callback function that saves presets to firestore.
   * @returns Saves new presets to firestore.
   */
  const handleSavePreset = async () => {

    const pattern = /[0-9!"#$%&'()*+,./:;<=>?@[\\\]^`{|}~]/; // Only - and _ allowed. Numbers or any other symbols not allowed.

    try {
      
      // Check presetName
      const length = presetName.length;
      if (length === 0) {
        setMessage(<small className="text-danger">Preset name cannot be blank.</small>);
        return;
      } else if (length < 5) {
        setMessage(<small className="text-danger">Preset name must be at least 5 characters.</small>);
        return;
      } else if (pattern.test(presetName)) {
        setMessage(<small className="text-danger">Only symbols allowed: - and _</small>);
        return;
      }
  
      await updateDoc(presetRef, {
        [`${presetName}`]: municipalities
      });

      setMessage(<small className="text-success">Preset saved!</small>);
    
    } catch (error) {

      setMessage(<small className="text-danger">Error occured while saving preset.</small>);
      
    } finally {
      setPresetName('');
    }
  }

  /**
   * Callback to handle loading of a preset.
   * @param {Event} event 
   */
  const handleLoadPreset = (event) => {
    const loadPresetName = event.currentTarget.name;
    const presetMunicipalities = value.data()[loadPresetName];
    handlePresetChange(presetMunicipalities);
    setMessage(<small className="text-success">Preset names loaded to selection!</small>)
  }

  /**
   * A list of saved preset names.
   * @component
   * @returns The list of saved preset municipalities.  
   */
  const PresetList = () => {
    
    /**
     * Gets the names of the presets from firestore and orders them alphabetically in an array.
     * @returns {string[]} Array of preset names.
     */
    const getPresetList = () => {
      if (value.data()) {
        const presetNames = Object.keys(value.data());
        if (presetNames.length) {
          return presetNames.sort();
        }
      }
      return [];
    }

    if (error) {

      return (
        <Row>
          <Col>
            {error && <strong>Error: {JSON.stringify(error)}</strong>}
          </Col>
        </Row>
      );

    } else if (loading) {

      return (
        <Row>
          <Col>
            {loading && <span>Document: Loading...</span>}
          </Col>
        </Row>
      );

    } else {

      return (
        <>
          { getPresetList().length !== 0 ?
            getPresetList().map(firestorePresetName => {
              return (

                <Row className="mb-3" key={Math.random()}>
                  <Col>
                    <Dropdown.ItemText>
                      <InputGroup>
                        <Dropdown.Item
                          as="button"
                          className="form-control text-start border rounded-start overflow-hidden"
                          
                          name={firestorePresetName}
                          onClick={event => handleLoadPreset(event)}
                          disabled={dashboardLoading}
                        >
                          {firestorePresetName}
                        </Dropdown.Item>
                        <Button 
                          variant="outline-danger" 
                          disabled={dashboardLoading}
                          onClick={() => {
                            setShow(true);
                            setMemoryName(firestorePresetName);
                          }}
                        >
                          <FolderMinusIcon width={16}/>
                        </Button>
                      </InputGroup>
                    </Dropdown.ItemText>
                  </Col>                
                </Row>
              );
            })
            :
            <Row>
              <Col>
                <Dropdown.ItemText>
                  <span>No saved presets.</span>
                </Dropdown.ItemText>
              </Col>
            </Row>
          }
        </>
      );
    
    }
  
  }
  
  /* -------------------------------- Component ------------------------------- */

  return (
    <>       
      {
        dashboardType(dashboard) === 'multi' ?
          <>
            <OverlayTrigger
              trigger={tooltips ? ['focus', 'hover'] : ''}
              placement="auto-end"
              delay={{ show: 250, hide: 400 }}
              overlay={PresetTooltip}
            >
              <Button
                variant={!open ? 'outline-primary' : 'success' }
                onClick={() => setOpen(!open)}
                aria-controls="collapse-menu"
                aria-expanded={open}
                className="w-100 icon-link icon-link-hover fw-semibold"
                disabled={dashboardLoading}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-folder-plus ms-auto" viewBox="0 0 16 16">
                  <path d="m.5 3 .04.87a2 2 0 0 0-.342 1.311l.637 7A2 2 0 0 0 2.826 14H9v-1H2.826a1 1 0 0 1-.995-.91l-.637-7A1 1 0 0 1 2.19 4h11.62a1 1 0 0 1 .996 1.09L14.54 8h1.005l.256-2.819A2 2 0 0 0 13.81 3H9.828a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 6.172 1H2.5a2 2 0 0 0-2 2m5.672-1a1 1 0 0 1 .707.293L7.586 3H2.19q-.362.002-.683.12L1.5 2.98a1 1 0 0 1 1-.98z"/>
                  <path d="M13.5 9a.5.5 0 0 1 .5.5V11h1.5a.5.5 0 1 1 0 1H14v1.5a.5.5 0 1 1-1 0V12h-1.5a.5.5 0 0 1 0-1H13V9.5a.5.5 0 0 1 .5-.5"/>
                </svg>
                <span className="me-auto">{!open ? 'Open Presets' : 'Close Presets'}</span>
              </Button>
            </OverlayTrigger>
            
            <Collapse in={open} onExited={() => setMessage('')}>
              <div id="collapse-menu">
                <Dropdown.Menu className="w-100 position-static border-0" show>
                  <Dropdown.Header>Save a new preset</Dropdown.Header>
                  <Dropdown.ItemText>
                    <InputGroup>
                      <Form.Control
                        placeholder="Enter preset label"
                        aria-label="Enter preset label"
                        value={presetName}
                        onChange={event => setPresetName(event.currentTarget.value)}
                        disabled={dashboardLoading}
                      />
                      <Button variant="outline-success" id="save-button" onClick={handleSavePreset} disabled={dashboardLoading}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-folder-plus" viewBox="0 0 16 16">
                          <path d="m.5 3 .04.87a2 2 0 0 0-.342 1.311l.637 7A2 2 0 0 0 2.826 14H9v-1H2.826a1 1 0 0 1-.995-.91l-.637-7A1 1 0 0 1 2.19 4h11.62a1 1 0 0 1 .996 1.09L14.54 8h1.005l.256-2.819A2 2 0 0 0 13.81 3H9.828a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 6.172 1H2.5a2 2 0 0 0-2 2m5.672-1a1 1 0 0 1 .707.293L7.586 3H2.19q-.362.002-.683.12L1.5 2.98a1 1 0 0 1 1-.98z"/>
                          <path d="M13.5 9a.5.5 0 0 1 .5.5V11h1.5a.5.5 0 1 1 0 1H14v1.5a.5.5 0 1 1-1 0V12h-1.5a.5.5 0 0 1 0-1H13V9.5a.5.5 0 0 1 .5-.5"/>
                        </svg>
                      </Button>
                    </InputGroup>
                    <span className="mt-5">{message}</span>
                  </Dropdown.ItemText>
                    
                  <Dropdown.Divider />
                  <Dropdown.Header>Saved presets</Dropdown.Header>
                  
                  <PresetList/>
                
                </Dropdown.Menu>
                  
                <YesNoModal
                  title={
                    <>
                      <FolderMinusIcon width={16}/>
                      Preset Deletion
                    </>
                  }
                  text={
                    (<>
                      <p>Are you sure you want to delete the saved preset?</p> 
                      <p>{memoryName}</p>
                    </>)
                  }
                  show={show}
                  handleClose={() => {
                    setShow(false);
                    setMessage('');
                  }}
                  handleYes={async () => {
                    await updateDoc(presetRef, {
                      [memoryName]: deleteField()
                    });
                    setMessage(<small className="text-success">Preset deleted.</small>);
                  }
                }
                />
              </div>
            </Collapse>
          </>
        : 
        ''
      }
    </>
  );
}

export default Preset;