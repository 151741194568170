/* -------------------------------------------------------------------------- */
/*                         Common Chart Configurtions                         */
/* -------------------------------------------------------------------------- */

export const chartConfig = {
  // Array of standard boostrap colors
  colorArray: [
    '#0d6efd',
    '#90762C',//Hemson secondary
    '#004C3A',//Hemson tertiary
    '#9D2235',//Hemson primary
    '#6610f2',
    '#6f42c1',
    '#d63384',
    '#dc3545',
    '#fd7e14',
    '#ffc107',
    '#198754',
    '#20c997',
    '#0dcaf0'
  ],
  // Configuration for fonts in labels (not tooltips).
  labels: {
    color: 'grey',
    darkThemeColor: 'rgb(222, 226, 230)',
    lightThemeColor: 'rgb(33, 37, 41)',
    font: {
      family: "'Segoe UI', 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      size: 12,
      weight: 'bold'
    },
  },
  chartGraphics: {
    borderColor: 'rgb(0, 0, 0)',
    borderWidth: 1,
    lineChartBorderWidth: 3,
  }
}

/* -------------------------------------------------------------------------- */
/*                                   Helpers                                  */
/* -------------------------------------------------------------------------- */

/**
 * A chartjs callback function to shorten y-axis labels.
 * @param {number} value - The y-axis tick label.
 * @param {number} index - The index of the tick label.
 * @param {number[]} values - The values object.
 * @param {boolean} [dollars] - Add dollar symbol to labels [default=true]. 
 * @returns {string} Formatted number label with dollar sign and shortened to thousands, millions or billions.
 */
const commarize = (value, index, values, dollars = true) => {
  
  const dollarSign = dollars ? '$' : '';

  if (value >= 1000000000 || value <= -1000000000 ) {
    return `${dollarSign}` + value / 1e9 + 'B';
  } else if (value >= 1000000 || value <= -1000000) {
    return `${dollarSign}` + value / 1e6 + 'M';
  } else  if (value >= 1000 || value <= -1000) {
    return `${dollarSign}` + value / 1e3 + 'K';
  }
  return `${dollarSign}` + value;
}

/**
 * A chartjs callback function to shorten y-axis labels to percent.
 * @param {number} value - The y-axis tick label.
 * @param {number} index - The index of the tick label.
 * @param {number[]} values - The values object.
 * @returns {string} Formatted number label with percent sign and shortened to one decimal place.
 */
const percentFormat = (value, index, values) => {
  // The order of formatting ensures that there are no infinite repeating decimals.
  let numberValue = Number(value)*100;
  let roundedNumberValue = numberValue.toFixed(1);
  return `${roundedNumberValue}%`;
}

/**
 * Formats the y-axis tick labels based on the provided symbol.
 * @param {number} value - The y-axis tick label.
 * @param {number} index - The index of the tick label.
 * @param {number[]} values - The values object containing all the tick labels.
 * @param {string} symbol - Specifies the format to be applied. Takes one of three values: 'none', 'dollar', or 'percent'. Default is 'dollar'.
 * 
 * @returns {string} - The formatted tick label.
 * 
 * The function uses the `symbol` parameter to determine the formatting of the y-axis tick labels:
 * - 'none': Formats the value without any symbol.
 * - 'percent': Formats the value as a percentage.
 * - 'dollar' (default): Formats the value as a dollar amount.
*/
export const yAxisFormat = (value, index, values, symbol = 'dollar') => {

  switch (symbol) {
    case 'none':
      return commarize(value, index, values, false);

    case 'percent':
      return percentFormat(value, index, values);
  
    default:
      return commarize(value, index, values, true);
  }

}

/**
 * Formats the title in tooltip (top part of tooltip).
 * @param {object} context - ChartJS context object.
 * @returns {string} Formatted label with commas removed.
 */
export const tooltipTitleFormat = (context) => {
  let formattedLabel = context[0].label.replace(/,/g, " ");
  return formattedLabel;
}

/**
 * Function to convert data to CSV format.
 * @param {Objects[]} data - A Hemson Analytics ChartJS data array.
 * @param {string} title - Title of the chart.
 * @param {object | boolean} [filterText] - Object of text indicating the filters chosen. Default is false.
 * @returns {string} Returns a string representation of the data.
 */
const convertDataToCSV = (data, title, filterText = false) => {

  // Extract labels and all unique keys from "values"
  const labels = ['Label', ...Array.from(new Set(data.flatMap(entry => Object.keys(entry.values))))].join(',');

  // Convert each entry to a CSV row
  const csvRows = data.map(entry => {
    const values = Object.keys(entry.values).map(key => entry.values[key]);
    return `${entry.label},${values.join(',')}`;
  });

  // Combine rows into a CSV string
  const dataCSV = `${labels}\n${csvRows.join('\n')}`;

  // Add filterText if applicable
  let filterString = '';
  if (filterText) {
    const filterNames = Object.keys(filterText);
    filterNames.forEach(filterName => {
      filterString += `${filterName}: ${filterText[filterName].join('/')}\n`
    })
  }
  
  // Add Footnotes
  const dataCSVWithFootNotes = dataCSV + 
    `\n\nData: ${title}\n${filterString}Exported on ${new Date()} from app.hemsonanalytics.com\nPowered by Hemson`;

  return dataCSVWithFootNotes;
};

/**
 * Function to handle CSV download
 * @param {Event} event - The onClick event object.
 * @param {object} chartRef - A React useRef reference to a chart.
 * @param {string} filterText - Text indicating the filters chosen.
 * @returns Creates a link to download data in CSV format.
 */
export const downloadCSV = (event, chartRef, filterText) => {
  // Get the chart data
  const data = chartRef.current.config._config.data.datasets[0].data;
  const title = chartRef.current.$context.chart.config._config.options.plugins.title.text;
  
  // Convert data to CSV format
  const csvContent = "data:text/csv;charset=utf-8," + encodeURIComponent(convertDataToCSV(data, title, filterText));

  // Create a download link
  const link = document.createElement('a');
  link.href = csvContent;
  link.download = `${title}.csv`;
  link.click();
};

/**
 * A chartjs callback function to shorten x-axis labels. The label is shortened if longer than 25 characters,
 * @param {number} value - The x-axis tick label.
 * @param {number} index - The index of the tick label.
 * @param {number[]} values - The values object.
 * @param {string} label - The label to shorten.
 * @returns {string} Formatted x-axis label shortened if too long.
 */
export const shortenXAxisLabel = (value, index, values, label) => {
  
  // Function is noted to cover MOST cases, there may be some edge cases not covered
  const parts = label.split(/of\s+(.*)/); // array of strings
  const name = parts.length > 1 ? parts[1].trim() : parts[0];

  if (name.length >= 25) {
    return name.substr(0, 15) + '...';
  } else {
    return name;
  }

}

/**
 * Checks if all data is null, if so returns false. Otherwise true.
 * @param {object[]} dataArray - Chart dataArray.
 * @returns {boolean}
 */
export const checkDataArrayForAllNullValues = (dataArray) => {
  try {

    // Check if dataArray is not null
    if (dataArray === null) {
      return false;
    }

    return dataArray.some(item => {
        return Object.values(item.values).some(value => value !== null);
    });
  } catch (error) {    
    console.log(error);
    console.log('The dataArray has caused an error when checking for null values. The dataArray is provided:');
    console.log(dataArray);
  }
}