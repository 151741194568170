// Styles
import styles from './Charts.module.css'

/**
 * A No Data placeholder.
 * @component
 * @param {Object} props - The component accepts props.
 * @param {string} [props.message] - Accepts an error code message.
 * @returns A chart div with No Data label.
 */
const NoDataChart = ({message}) => {
 
  return (
    <div className={styles.chartBoxHeight}>
      <div className='d-flex justify-content-center align-items-center h-100 w-100'>
        <div className='text-center'>
          <p>No Data</p>
          <p>{message}</p>
        </div>
      </div>      
    </div>
  );
}

export default NoDataChart;