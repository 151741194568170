import SwitchButtonTemplate from './SwitchButtonTemplate';

/**
 * A switch button for color modes light and dark.
 * @component
 * @param {Object} props - The component accepts theme and handleThemeChange as props.
 * @param {boolean} props.theme - Theme selection value.
 * @param {function(Event): undefined} props.handleThemeChange - Callback to handle theme change.
 * @returns A switch button to control light and dark theme.
 */
const ColorModeSwitchButton = ({
  theme,
  handleThemeChange
}) => {
 
  return (
    <>
      <SwitchButtonTemplate
        offLabel={'Light ☀'}
        onLabel={'Dark 🌙'}
        defaultChecked={theme}
        onChange={(event) => handleThemeChange(event.currentTarget.checked)}
        keyName='theme'
      />
    </>
  );
}

export default ColorModeSwitchButton;