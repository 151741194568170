// Components
import SwitchButtonTemplate from './SwitchButtonTemplate';
import { useTooltipsContext } from '../Context/TooltipsContext';

/**
 * A switch button to control display of tooltips.
 * @component
 * @returns A switch button to control tooltips.
 */
const TooltipsSwitchButton = () =>  {
  
  // Extract the context setting function to set context state
  const { tooltips, handleTooltipsChange } = useTooltipsContext();
  
  // On change, set the context state
  const handleSwitchChange = (e) => {
    let checked = e.currentTarget.checked;
    handleTooltipsChange(checked);
  }

  return (
    <>
      <SwitchButtonTemplate
        offLabel={'Off ' + '❌'}
        onLabel={'On ' + '💬'}
        defaultChecked={tooltips}
        onChange={handleSwitchChange}
        keyName='tooltips'
      />
    </>
  );
}

export default TooltipsSwitchButton;