// Components
import Offcanvas from 'react-bootstrap/Offcanvas';
import Stack from 'react-bootstrap/Stack';
import DashboardOptions from './DashboardOptions';
import DashboardMenu from './DashboardMenu';
import HemsonAnalyticsBrand from '../Icons/HemsonAnalyticsBrand';
import Preset from '../Dropdown/Preset';
import GenerateButton from '../Buttons/GenerateButton';
// Styles
import styles from './Navbars.module.css';

/**
 * The left side navigation bar and offcanvas bar.
 * @component
 * @param {Object} props - The component accepts props.
 * @param {function(Event): void} props.show - Props to handle show functionality.
 * @param {function(Event): void} props.handleClose - Callback to handle close functionality.
 * @param {string} props.dashboard - Dashboard selection value.
 * @param {function(Event): void} props.handleDashboardChange - Callback to handle dashboard change.
 * @param {Objects[]} props.municipality - Municipality selection value.
 * @param {function(Event): void} props.handleMunicipalityChange - Callback to handle municipality change.
 * @param {Objects[]} props.municipalities - Municipalities selection value.
 * @param {function(Event): void} props.handleMunicipalitiesChange - Callback to handle municipalities change.
 * @param {number[]} props.years - Array of start and end years.
 * @param {function(Event): void} props.handleStartYearChange - Callback to handle start year change.
 * @param {function(Event): void} props.handleEndYearChange - Callback to handle end year change.
 * @param {boolean} props.constant - Inflation selection value.
 * @param {function(Event): void} props.handleConstantChange - Callback to handle inflation change.
 * @param {boolean} props.footnote - Footnote selection value.
 * @param {function(Event): void} props.handleFootnoteChange - Callback to handle footnote change.
 * @param {boolean} props.theme - Theme selection value.
 * @param {function(Event): void} props.handleThemeChange - Callback to handle theme change.
 * @param {boolean} props.dashboardLoading - Dashboard loading spinner state.
 * @param {boolean} props.user - User object.
 * @param {boolean} props.admin - Admin state.
 * @param {Objects[]} props.preset - The preset municipalities array.
 * @param {function(Event): void} props.handlePresetChange - Callback to handle preset municipalities change.
 * @param {function(boolean): void} props.handleGenerate - Callback to handle the data fetch state.
 * @param {object} props.filter - A custom filter object.
 * @param {function(Event): void} props.handleFilterChange - Callback to handle filter change.
 * @returns Dashboard left side navigation bar and offcanvas bar component.
 */
const LeftNavbar = ({ 
  show, 
  handleClose,
  dashboard,
  handleDashboardChange,
  municipality,
  handleMunicipalityChange,
  municipalities,
  handleMunicipalitiesChange,
  years,
  handleStartYearChange,
  handleEndYearChange,
  constant,
  handleConstantChange,
  footnote,
  handleFootnoteChange,
  theme,
  handleThemeChange,
  dashboardLoading,
  user,
  admin,
  preset,
  handlePresetChange,
  handleGenerate,
  filter,
  handleFilterChange
}) => {

  return (
    <Offcanvas show={show} onHide={handleClose} responsive="xl" className='col-2 bg-primary-subtle border-end border-primary position-fixed' style={{height: '100svh'}}>
      <Offcanvas.Header closeButton>        
        <HemsonAnalyticsBrand/>
      </Offcanvas.Header>
      <Offcanvas.Body className='flex-column h-100'>
        {/* This is the content within the offcanvas */}        
        <Stack gap={3} className='h-100'>
          <div className='pt-3 d-none d-xl-block'>
            <HemsonAnalyticsBrand/>
          </div>
          <div className={`mt-0 overflow-y-auto overflow-x-hidden px-1 py-3 border-top border-bottom border-1 border-primary ${styles.menu}`}>
            <DashboardMenu
              dashboard={dashboard}
              handleDashboardChange={handleDashboardChange}
              municipality={municipality}
              handleMunicipalityChange={handleMunicipalityChange}
              municipalities={municipalities}
              handleMunicipalitiesChange={handleMunicipalitiesChange}
              years={years}
              handleStartYearChange={handleStartYearChange}
              handleEndYearChange={handleEndYearChange}
              dashboardLoading={dashboardLoading}
              preset={preset}
              filter={filter}
              handleFilterChange={handleFilterChange}
            />

            <Preset
              dashboard={dashboard}
              municipality={municipality}
              municipalities={municipalities}
              years={years}
              dashboardLoading={dashboardLoading}
              user={user}
              handlePresetChange={handlePresetChange}
            />
          </div>
         
          <div className='d-flex justify-content-end mt-3'>
            <div>
              <div>
                <GenerateButton
                  dashboard={dashboard}
                  constant={constant}
                  filter={filter}
                  municipality={municipality}
                  municipalities={municipalities}
                  years={years}
                  dashboardLoading={dashboardLoading}
                  handleGenerate={handleGenerate}
                />
              </div>
              <div>
                {dashboardLoading ? <small className='fw-semibold mb-0'>Loading...</small> : <small className='text-success-emphasis fw-semibold mb-0'>Dashboard Ready</small>}
              </div>
            </div>
          </div>

          <div className="mt-auto mb-3">
            <DashboardOptions
              constant={constant}
              handleConstantChange={handleConstantChange}
              footnote={footnote}
              handleFootnoteChange={handleFootnoteChange}
              theme={theme}
              handleThemeChange={handleThemeChange}
              user={user}
              admin={admin}
            />
          </div>
        </Stack>                
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default LeftNavbar;