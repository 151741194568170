import SwitchButtonTemplate from './SwitchButtonTemplate';

/**
 * A switch button to control inflation.
 * @component
 * @param {Object} props - The component accepts constant and handleConstantChange as props.
 * @param {boolean} props.constant - Inflation selection value.
 * @param {function(Event): undefined} props.handleConstantChange - Callback to handle inflation change.
 * @returns A switch button to control inflation.
 */
const InflationSwitchButton = ({
  constant,
  handleConstantChange
}) => {
  
  return (
    <>
      <SwitchButtonTemplate
        offLabel={'Nominal ' + '💲↗'}
        onLabel={'Constant ' + '💲↔'}
        defaultChecked={constant}
        onChange={(event) => handleConstantChange(event.currentTarget.checked)}
        keyName='constant'
        disabled={false}
      />
    </>
  );
}

export default InflationSwitchButton;