// Components
import Collapse from 'react-bootstrap/Collapse';
import GeneralDashboardCard from "../Cards/GeneralDashboardCard";

/**
 * A collapsable footnote box with text.
 * @component
 * @param {Object} props - The component accepts props.
 * @param {string} props.text - The text to display.
 * @param {boolean} props.footnote - The footnote display state.
 * @returns A collapsable footnote box with text.
 */
const GeneralFootnote = ({
    text = 'No text provided.',
    footnote = true 
}) => {

  return (
    <Collapse in={footnote}>
      <GeneralDashboardCard className="mt-3">
        <p className='mb-0'>{text}</p>
      </GeneralDashboardCard>
    </Collapse>
  );
}

export default GeneralFootnote;