// React
import React, { useState, useEffect } from 'react';
// Components
import SignInForm from "../components/Forms/SignInForm";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import HemsonAnalyticsLogo from '../components/Icons/HemsonAnalyticsLogo';
import HemsonLogo from '../components/Icons/HemsonLogo';
import PasswordResetForm from '../components/Forms/PasswordResetForm';
import Button from 'react-bootstrap/Button';
// Assets
import TorontoImage from '../assets/toronto-skyline.webp';
import ErinImage from '../assets/erin-skyline.webp';
import WoodstockImage from '../assets/woodstock-skyline-2400.webp';
import HamiltonImage from '../assets/hamilton-skyline-2400.webp';
import ThunderBayImage from '../assets/thunderbay-skyline-2400.webp';
import ResidentialImage from '../assets/residential-development.webp';
// Styles
import styles from "./Pages.module.css";

/* -------------------------------------------------------------------------- */
/*                            Login Page Component                            */
/* -------------------------------------------------------------------------- */

/**
 * The login page.
 * @component
 * @returns The login page component. 
 */
const LoginPage = () => {

  const [passwordReset, setPasswordReset] = useState(false);

  /* ---------------------------- Background Image ---------------------------- */
  
  const backgroundImages = [
    TorontoImage,
    ErinImage,
    WoodstockImage,
    HamiltonImage,
    ThunderBayImage,
    ResidentialImage
  ];
  
  const [currentBackground, setCurrentBackground] = useState('');

  useEffect(() => {
    // Set a random background when the component mounts
    setRandomBackground();
  }, []); // Empty dependency array ensures it runs only once when mounted

  const setRandomBackground = () => {
    const randomIndex = Math.floor(Math.random() * backgroundImages.length);
    const selectedBackground = backgroundImages[randomIndex];
    
    // Log the selected background to the console for debugging
    console.log('Selected Background:', selectedBackground);

    setCurrentBackground(selectedBackground);
  };

  /* -------------------- Login or Reset Password Component ------------------- */
  
  /**
   * A button with link to the contact page.
   * @component
   * @returns Button with link to contact page. 
   */
  const ContactButton = () => {
    return (
      <div className='d-grid'>
        <Button as='a' variant="outline-primary" className='icon-link icon-link-hover' href="https://www.hemson.com/contact-us/" target="_blank" rel="noopener noreferrer">
          <span className='mx-auto'>Don't have an account? Contact us</span>
        </Button>
      </div>
    );
  }

  /**
   * A bootstrap Card body element holding the sign in form.
   * @component
   * @returns Bootstrap Card body element holding the sign in form. 
   */
  const FormContainer = () => {

    return (
      <Card.Body>
        <div className="d-flex justify-content-center mb-4">
          <HemsonAnalyticsLogo width={150} height={72}/>
        </div>

        {passwordReset ? 
          <>
            <PasswordResetForm>
              <Button variant='link' size='sm' onClick={() => setPasswordReset(false)} className='ps-0'>
                Sign in?
              </Button>
            </PasswordResetForm>
            <ContactButton/>
          </>
          :
          <>
            <SignInForm>
              <Button variant='link' size='sm' onClick={() => setPasswordReset(true)} className='ps-0'>
                Forgot your password?
              </Button>
            </SignInForm>
            <ContactButton/>
          </>
      
        }
        
      </Card.Body>
    );
  }

  /* -------------------------- Login Page Component -------------------------- */
  
  return (
    <Container fluid className='d-flex flex-column' style={{
      position: 'relative',
      height: '100svh',
      backgroundImage: `url('${currentBackground}')`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover'
    }}>
      
      <div className={styles.overlay}></div>
      
      <Row className="d-flex align-items-center h-100 mb-auto">
        <Col md={7} lg={6} xl={4} xxl={3} className="mx-auto">
          <Card className="shadow">
            <Card.Header as='h3' className="bg-primary-subtle bg-gradient text-center">Welcome to Hemson Analytics</Card.Header>
              <FormContainer/>
            <Card.Footer className="text-center bg-primary-subtle bg-gradient">
              <div className="d-flex align-items-center justify-content-center">
                <p className="mb-0 me-2 fw-semibold">Powered by</p>
                <a href="https://www.hemson.com/" target="_blank" rel="noopener noreferrer" className="d-flex align-items-center mb-1">
                  <HemsonLogo width={80}/>
                </a>
              </div>
            </Card.Footer>
          </Card>
        </Col>
      </Row>

      <Row className='z-0'>
        <Col className='text-center'>
          <p className="text-light"><small>© Hemson Consulting 2024</small></p>
        </Col>
      </Row>    
    </Container>
  );
}

export default LoginPage;