import SwitchButtonTemplate from './SwitchButtonTemplate';

/**
 * A switch button to control footnote display.
 * @component
 * @param {Object} props - The component accepts footnote and handleFootnoteChange as props.
 * @param {boolean} props.footnote - Footnote selection value.
 * @param {function(Event): undefined} props.handleFootnoteChange - Callback to handle footnote change.
 * @returns A switch button to control footnote display.
 */
const FootnoteSwitchButton = ({
  footnote,
  handleFootnoteChange,
}) => {

  return (
    <>
      <SwitchButtonTemplate
        offLabel={'Hide ' + '❌'}
        onLabel={'Show ' + '📑'}
        defaultChecked={footnote}
        onChange={(event) => handleFootnoteChange(event.currentTarget.checked)}
        keyName='footnote'
      />
    </>
  );
}

export default FootnoteSwitchButton;