// Components
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import HemsonAnalyticsBrand from '../Icons/HemsonAnalyticsBrand';
import HemsonLogo from '../Icons/HemsonLogo';

/**
 * The top navbar of the dashboard.
 * @component
 * @param {Object} props - The component accepts props. 
 * @param {function(Event): void} props.handleShow - Callback to handle showing collapse button for LeftNavbar.
 * @param {boolean} props.constant - Inflation selection value.
 * @param {function(Event): void} props.handleConstantChange - Callback to handle inflation change.
 * @param {boolean} props.footnote - Footnote selection value.
 * @param {function(Event): void} props.handleFootnoteChange - Callback to handle footnote change.
 * @param {boolean} props.theme - Theme selection value.
 * @param {function(Event): void} props.handleThemeChange - Callback to handle theme change.
 * @param {boolean} props.user - User object.
 * @param {boolean} props.admin - Admin state.
 * @returns Dashboard top navbar component.  
 */
const TopNavbar = ({ 
  handleShow,
  constant,
  handleConstantChange,
  footnote,
  handleFootnoteChange,
  theme,
  handleThemeChange,
  user,
  admin 
}) => {
  
  return (
    <>
      <Navbar className='bg-primary-subtle border-bottom border-primary z-3' style={{minHeight: '5svh'}}>
        <Container fluid>          
          <Button variant="outline-primary" className="d-xl-none" onClick={handleShow}>
            <span className='navbar-toggler-icon'></span>
          </Button>
          <Navbar.Brand className='d-xl-none me-0'>
            <HemsonAnalyticsBrand/>
          </Navbar.Brand>
          <div className="ms-auto me-3 d-none d-xl-block">
            <HemsonLogo width={110}/> 
          </div>
        </Container>
      </Navbar>
    </>
  );
}

export default TopNavbar;
