// React
import React from 'react';
import { useEffect, useState, Fragment } from 'react';
// Components
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useTooltipsContext } from '../Context/TooltipsContext.js';
import { Typeahead, Menu, MenuItem, Highlighter } from 'react-bootstrap-typeahead';
// Styles
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';

/**
 * A typeahead filter selection form. This form must include 'Total' as one of the options in the typeaheadOptions array. Used for dashboards that have additive options.
 * @components
 * @param {Object} props - The component accepts props.
 * @param {Object} props.filter - A custom filter object.
 * @param {function(Event): void} props.handleFilterChange - Callback to handle filter change. 
 * @param {string} props.dashboard - Dashboard selection value.
 * @param {boolean} props.dashboardLoading - Dashboard loading spinner state.
 * @param {string} props.uniqueId - A unique html element id. Can not be the same as any other typeahead. This id must be matched in with its corresponding query in querySchema.js.
 * @param {string[]} props.typeaheadOptions - The options to show in the typeahead.
 * @param {string} props.formLabel - A label for the typeahead.
 * @param {string} props.placeholder - Placeholder text for the typeahead.
 * @param {string} props.tooltipText - Text for the tooltip.
 * @param {boolean} props.category - If true will generate a custom menu with categories and dividers. typeaheadOptions must have a "category" key for all options.
 * @param {boolean} props.multiple - Indicates whether the typeahead allows multiple selection. Defaults to true.
 * @returns Typeahead filter form component.  
 */
const FilterTypeahead = ({
  filter,
  handleFilterChange,
  dashboard,  
  dashboardLoading,
  uniqueId,
  typeaheadOptions,
  formLabel,
  placeholder,
  tooltipText,
  category = false,
  multiple = true
}) => {

const { tooltips } = useTooltipsContext();
const [key, setKey] = useState(0);

useEffect(() => {
  handleFilterChange(filter);
}, []);

useEffect(() => {
  setKey((prevKey) => prevKey + 1);
}, [dashboard]); // Reset key whenever dashboard prop changes

const handleTypeaheadChange = (options, id) => {
  // Ensures that the 'Total' option is mutually exclusive with all other options
  // Also ensures that the value is never set to an empty array

  // If the options array does not contain value 'Total', throw error
  if (!typeaheadOptions.some((optionObject) => optionObject.option === 'Total')) {
    throw new Error("Error: typeaheadOptions must include value 'Total'");
  }

  // The value saved for the filter must be an array of strings
  const fullOptionsArray = typeaheadOptions.filter(optionObject => optionObject.option !== 'Total');

  let optionData = [fullOptionsArray[0].option];

  if (options.length === 0) {
    optionData = optionData;
  } else if (options.some((optionObject) => optionObject.option === 'Total')) {
    optionData = fullOptionsArray.map(optionObject => optionObject.option);
  } else {
    optionData = options.map(optionObject => optionObject.option);
  }

  const updatedFilter = {
    ...filter,
    [id]: optionData
  }
  handleFilterChange(updatedFilter);
}

const renderFilterTooltip = (props) => (
  <Tooltip id="renderFilterTooltip" {...props}>
    <div className='text-start'>
      <p className='mb-0'>{tooltipText}</p>
    </div>
  </Tooltip>
);

// If the options array does not contain value 'Total', render error message
if (!typeaheadOptions.some((optionObject) => optionObject.option === 'Total')) {
  return <p className='text-danger'>Error: typeaheadOptions must include value 'Total'</p>
}

/**
 * Generates a custome menu for the typeahead with categories and dividers.
 * @param {Object} results - Typeahead.js results object.
 * @param {Object} param1 - Typeahead.js props object.
 * @param {Object} state - Typeahead.js state object.
 * @returns A custom typeahead menu.
 */
const categoryHeaders = (
  results,
  {
    newSelectionPrefix,
    paginationText,
    renderMenuItemChildren,
    ...menuProps
  },
  state
) => {
  let index = 0;

  // Get the selected options
  const selectedOptions = state.selected;

  // Group options by category and filter out selected options
  const categories = Object.groupBy(typeaheadOptions, ({ category }) => category);
  const items = Object.keys(categories)
    .map((category) => {
      const filteredOptions = categories[category].filter(option => 
        !selectedOptions.some(selected => selected.option === option.option)
      );

      if (filteredOptions.length === 0) {
        return null;
      }

      return (
        <Fragment key={category}>
          {index !== 0 && <Menu.Divider />}
          <Menu.Header>{category}</Menu.Header>
          {filteredOptions.map((i) => {
            const item = (
              <MenuItem key={index} option={i} position={index}>
                <Highlighter search={state.text}>{i.option}</Highlighter>
              </MenuItem>
            );

            index += 1;
            return item;
          })}
        </Fragment>
      );
    })
    .filter(Boolean); // Remove null categories

  return <Menu {...menuProps}>{items}</Menu>;
};


return (
  <Row className='mx-0'>      
    <Col className='px-0'>
      <OverlayTrigger
        trigger={tooltips ? ['focus', 'hover'] : ''}
        placement="auto-end"
        delay={{ show: 250, hide: 400 }}
        overlay={renderFilterTooltip}
      >
        
        <Form onSubmit={(event) => event.preventDefault()}>
          <fieldset disabled={dashboardLoading}>
            <Form.Group className="mb-4">
              <Form.Label className='fw-semibold icon-link'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-funnel" viewBox="0 0 16 16">
                  <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2z"/>
                </svg>
                {formLabel}
              </Form.Label>
              <Typeahead
                key={key}
                clearButton
                caseSensitive={false}
                id={uniqueId}
                ignoreDiacritics={true}
                labelKey={'option'}
                options={typeaheadOptions}
                placeholder={placeholder}
                multiple={multiple}
                onChange={(options) => handleTypeaheadChange(options, uniqueId)}
                selected={
                  typeaheadOptions.filter(
                    optionObject => {
                      return filter[uniqueId].includes(optionObject.option);
                  })
                }
                renderMenu={category ? categoryHeaders : undefined}
                positionFixed
              />
            </Form.Group>
          </fieldset>
        </Form>
      
      </OverlayTrigger>
    </Col>
  </Row>     
);
};

export default FilterTypeahead;