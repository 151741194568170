import React, { createContext, useContext, useState } from 'react';
// Firebase
import { auth, db } from '../../services/firebase.config.js';
import { useAuthState } from 'react-firebase-hooks/auth';
import { doc, updateDoc } from "firebase/firestore";
// Components
import { getStorage, setStorage } from '../Dashboards/DashboardHelpers';

/* -------------------------------------------------------------------------- */
/*                       Instructions to Create Context                       */
/* -------------------------------------------------------------------------- */

// Step 1: Create Context.js file like here.
// Step 2: Wrap the <App/> component in index.js with the <ContextProvider/> component.
// Step 3: Add state logic to component that determines and sets the context state. Import useTooltipsContext and extract as const { setTooltipsContext } = useTooltipsContext();
// Step 4: Import the context in components that need it. Import useTooltipsContext and extract as const { tooltips } = useTooltipsContext();
// Note make sure that all components that set and use the context are inside the <ContextProvider/> at all times across all routes or errors will occur.

/* -------------------------------------------------------------------------- */
/*                              Tooltips Context                              */
/* -------------------------------------------------------------------------- */

// Create a context to hold the selected tooltips value
const TooltipsContext = createContext();

export const useTooltipsContext = () => useContext(TooltipsContext);

/**
 * Context component to hold tooltips state and provide it to components. Tooltips is a boolean.
 * @component
 * @param {Object} props - The component accepts props.
 * @param {React.JSX.Element} props.children - A child React component. 
 * @returns Tooltips context component.
 */
export const TooltipsContextProvider = ({ children }) => {

  const [user] = useAuthState(auth);

  const [tooltips, setTooltips] = useState(getStorage('tooltips'));

  const handleTooltipsChange = async (tooltips) => {

    const userRef = doc(db, 'users', user.uid);

    setTooltips(tooltips);
    setStorage(tooltips);

    await updateDoc(userRef, {
      "settings.tooltips": tooltips
    });
  };

  // Note that the "value" prop is a required name in the Context.Provider
  return (
    <TooltipsContext.Provider value={{ tooltips, handleTooltipsChange }}> 
      {children}
    </TooltipsContext.Provider>
  );
}
