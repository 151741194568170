// Components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GeneralDashboardCard from './GeneralDashboardCard';
import DashboardAlert from '../Alert/DashboardAlert';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useTooltipsContext } from '../Context/TooltipsContext';
// Helpers
import { dashboardType, dashboardObject } from '../../configuration/dashboardNames.js';

/**
 * A title display card.
 * @component
 * @param {Object} props - The component accepts props.
 * @param {string} props.dashboard - The dashboard name.
 * @param {array} props.formattedMunicipalName - An array of formatted municipal names.
 * @param {string} props.dataStatus - The data status: 'Data Available', 'Data Missing' or 'Error'
 * @param {boolean} props.dashboardLoading - Dashboard loading spinner state.
 * @param {boolean} props.constant - Inflation indicator selection.
 * @returns A title card component for the dashboard.
 */ 
const TitleCard = ({
  dashboard = 'Dashboard Name Required',
  formattedMunicipalName = ['Please choose municipalities from the left menu.'],
  dataStatus,
  dashboardLoading,
  constant,
  dashboardError,
  dashboardErrorMessage,
  filterText
}) => {

  /* -------------------------------- Tooltips -------------------------------- */
  
  const { tooltips } = useTooltipsContext();

  const InflationTooltip = (props) => (
    <Tooltip id="inflationTooltip" {...props}>
      <div className='text-start'>
        <p className='mb-0'>✔ Indicates if values are in nominal or constant dollars. Note that some dashboards are not affected by this option and are maintained nominal or are in other units ie. not dollars. You can change this under Dashboard Options &gt; Settings.</p>
      </div>
    </Tooltip>
  );

  const DataAvailableTooltip = (props) => (
    <Tooltip id="dataAvailableTooltip" {...props}>
      <div className='text-start'>
        <p className='mb-0'>✔ Indicates if all municipal data is available. Used as a general indicator of the completeness of the data. Message will indicate missing data if some municipal data is not fully available.</p>
      </div>
    </Tooltip>
  );

  const municipalNameList = formattedMunicipalName.map(((name, index) => index === formattedMunicipalName.length - 1 ? `${name}` : `${name}, `))

  const textColor = () => {
    if (dataStatus === 'Data Available') {
      return 'text-success';
    } else if (dataStatus === 'Missing Data') {
      return 'text-warning-emphasis';
    } else if (dataStatus === 'Error') {
      return 'text-danger-emphasis';
    } else {
      return '';
    }
  }

  const ConstantText = () => {

    const ignoreInflation = dashboardObject[dashboard].ignoreInflation;

    if (dashboardLoading) {
      return <span></span>;
    } else if (dataStatus === 'Error') {
      return <span></span>;
    } else {
      return (
        <OverlayTrigger
          trigger={tooltips ? ['focus', 'hover'] : ''}
          placement="auto-start"
          delay={{ show: 250, hide: 400 }}
          overlay={InflationTooltip}
        >
          <span className={`${ignoreInflation ? 'd-none' : ''}`}>
            {`Values: ${constant ? 'Constant' : 'Nominal'}`}
          </span>
        </OverlayTrigger>
      );
    }
  }

  const DashboardStatus = () => {
    return (
      <OverlayTrigger
        trigger={tooltips ? ['focus', 'hover'] : ''}
        placement="auto-start"
        delay={{ show: 250, hide: 400 }}
        overlay={DataAvailableTooltip}
      >
        <span className={textColor()}>
          {dataStatus}
        </span>
      </OverlayTrigger>
    )
  }

  const FilterTextComponent = () => {
    
    if (filterText) {
      
      const keys = Object.keys(filterText);

      return (
        <div>
          {
            keys.map((key) => {

              return <p key={key} className='fw-light mb-0'><small><span className='fw-semibold'>{`${key}: `}</span>{`${filterText[key].join(', ')}`}</small></p>
            })
          }
        </div>
      )

    }

    return '';
    
  }

  const MessageHandler = () => {

    if (dashboardError) {
      return (
        <DashboardAlert
          variant='danger'
          text={dashboardErrorMessage}
          display={dashboardError}
        />
      );
    }

    if (dashboardLoading) {
      return <span className='fw-medium'>Loading...</span>
    }

    return (
      // Determines municipal names to list depending on dashboard. Removes any ending commas or spaces.
      <>
        <p className='fw-medium mb-0'>
          {dashboardType(dashboard) === 'multi'
            ? municipalNameList
            : municipalNameList[0].replace(/,\s*$/, '')}
        </p>
        <FilterTextComponent/>
      </>
    );
  }

  const ExperimentalTag = () => {
    const experimental = dashboardObject[dashboard].experimental;
    
    if (experimental) {
      return (
        <span className="badge rounded-pill text-bg-warning align-top" style={{fontSize: '0.75rem'}}>
          Experimental
          <span className="visually-hidden">Experimental</span>
        </span>
      );
    }

    return '';
  }

  return (
    <GeneralDashboardCard>
      <Row>
        <Col lg={6}>
          <h3>{`${dashboard}`}<ExperimentalTag/></h3>          
          <MessageHandler/>
        </Col>
        <Col lg={6} className='d-flex flex-column justify-content-end text-end'>
          <span className='fw-medium'>
            <ConstantText/>
          </span>
          <span className="fw-medium">
            {dashboardLoading ? '' 
              : (
              <DashboardStatus/>
            )}
          </span>
        </Col>
      </Row> 
    </GeneralDashboardCard>
  );
}

export default TitleCard;