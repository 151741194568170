// React
import React, { useState } from 'react';
// Firebase
import { db } from '../../services/firebase.config.js'
import { doc, updateDoc } from "firebase/firestore";
// Styles
import 'bootstrap/dist/css/bootstrap.css';
// Components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import LeftNavbar from './LeftNavbar';
import TopNavbar from './TopNavbar';
import Dashboards from '../Dashboards/Dashboards.js';
// Helpers
import { setStorage, getStorage } from '../Dashboards/DashboardHelpers.js';
import { municipalityNames } from '../../configuration/MunicipalityNames.js';
import { firFirstYear, firLastYear, dashboardObject } from '../../configuration/dashboardNames.js';
import { masterFilterDefaultObject } from '../../configuration/filterCategories.js';

/**
 * The main dashboard template.
 * @component
 * @param {Object} props - The component accepts props.
 * @param {boolean} props.user - User object.
 * @param {boolean} props.admin - Admin state.
 * @returns The dashboard template component. 
 */
const DashboardTemplate = ({user, admin}) => {

  /* -------------------------------------------------------------------------- */
  /*                                DEFINE STATES                               */
  /* -------------------------------------------------------------------------- */

  const userRef = doc(db, 'users', user.uid);
  
  
  const [generate, setGenerate] = useState(false);

  /**
   * Callback to handle the data fetch state.
   * @param {boolean} generate - The generate value. 
   */
  const handleGenerate = (generate) => {
    setGenerate(generate);
  }

  //const [settings, setSettings] = useState({});

  const [show, setShow] = useState(false);
  
  // useEffect(() => {
  //   const getSettings = async () => {
      
  //     const docSnap = await getDoc(userRef);

  //     if (docSnap.exists()) {
  //       setSettings(docSnap.data().settings);
  //     } else {
  //       setSettings(null);
  //     }
  //   }
    
  //   getSettings();
  
  // }, []);

  
  // Dashboard name
  const storageDashboard = getStorage('dashboard');
  const [dashboard, setDashboard] = useState(storageDashboard ? storageDashboard : dashboardObject['Overview'].name);

  // Single municipality
  const storageMunicipality = getStorage('municipality');
  const [municipality, setMunicipality] = useState(storageMunicipality ? storageMunicipality : [municipalityNames[0]]); // Defaults to first municipality Township of Addington Highlands

  // Multiple municipalities
  const storageMunicipalities = getStorage('municipalities');
  const [municipalities, setMunicipalities] = useState(storageMunicipalities ? storageMunicipalities : [municipalityNames[0]]); // Defaults to first municipality Township of Addington Highlands

  const [dashboardLoading, setDashboardLoading] = useState(false);

  // Local storage for switch type buttons is set at the button level
  const [constant, setConstant] = useState(getStorage('constant') ? getStorage('constant') : false);

  // Note this method is needed becuase the default option is set to "true"
  const storageFootnote = getStorage('footnote');
  const initialFootnote = (storageFootnote !== null) ? storageFootnote : true;
  const [footnote, setFootnote] = useState(initialFootnote);

  // Local storage for switch type buttons is set at the button level
  const [theme, setTheme] = useState(getStorage('theme') ? getStorage('theme') : false);

  /* ----------------------- HANDLE CANVAS NAVBAR STATES ---------------------- */
  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  /* ---------------------------- DASHBOARD STATES ---------------------------- */

  /**
   * Callback that sets the dashboard state and local storage value.
   * @param {string} dashboard - The name of the dashboard selected.
   */
  const handleDashboardChange = async (dashboard) => {
    // Set state and local storage of selected dashboard
    setDashboard(dashboard);
    setStorage('dashboard', dashboard);
    
    await updateDoc(userRef, {
      "selection.dashboard": dashboard
    });
  };
    
  /**
   * Callback that sets the dashboardLoading state.
   * @param {boolean} boolean - The required value for dashboardLoading state.
   */
  const handleDashboardLoading = (boolean) => {
    setDashboardLoading(boolean);
  };

  /* --------------------------- MUNICIPALITY STATES -------------------------- */

  /**
   * Callback that sets the municipality state and local storage for single municipality dashboards.
   * @param {Objects[]} municipality - The municipality array. Municipality is an array of objects in the form {name: string, formattedName: string, MUNID: number}.
   * @returns 
   */
  const handleMunicipalityChange = async (municipality) => {
    // municipality is an array of objects in the form {name: string, formattedName: string, MUNID: number}
    
    if (municipality.length > 0) {

      setMunicipality(municipality);
      setStorage('municipality', municipality);

      await updateDoc(userRef, {
        "selection.municipality": municipality
      });
    
    } else {
      // Do not set state to empty arrays
      return;  
    }
  };

  /**
   * Callback that sets the municipalities state and local storage for multi municipality dashboards.
   * @param {Objects[]} municipalities - The municipalities array. Municipality is an array of objects in the form {name: string, formattedName: string, MUNID: number}.
   * @returns 
   */
  const handleMunicipalitiesChange = async (municipalities) => {
    // municipalities is an array of objects in the form {name: string, formattedName: string, MUNID: number}
    if (municipalities.length > 0) {
      
      setMunicipalities(municipalities);
      setStorage('municipalities', municipalities);

      await updateDoc(userRef, {
        "selection.municipalities": municipalities
      });
    
    } else {
      // Do not set state to empty arrays
      return; 
    }
  };

  /* ------------------------------- YEAR STATES ------------------------------ */
  
  const storageYears = getStorage('years');
  const [years, setYears] = useState(storageYears ? storageYears : [firFirstYear, firLastYear]);

  /**
   * Callback that sets the years state and local storage when startYear changes.
   * @param {string} startYear - The start year value.
   */
  const handleStartYearChange = async (startYear) => {
    const selectedStartYear = parseInt(startYear);
    const queryYears = [selectedStartYear, Math.max(selectedStartYear, years[1])];
    setYears(queryYears);
    setStorage('years', queryYears);

    await updateDoc(userRef, {
      "selection.years": queryYears
    });
  };

  /**
   * Callback that sets the years state and local storage when endYear changes.
   * @param {string} endYear - The end year value.
   */
  const handleEndYearChange = async (endYear) => {
    const selectedEndYear = parseInt(endYear);
    const queryYears = [Math.min(selectedEndYear, years[0]), selectedEndYear];
    setYears(queryYears);
    setStorage('years', queryYears);

    await updateDoc(userRef, {
      "selection.years": queryYears
    });
  }; 

  /* ------------------------------ FILTER STATES ----------------------------- */

  const storageFilter = getStorage('filter');
  const [filter, setFilter] = useState(storageFilter ? storageFilter : masterFilterDefaultObject);

  /**
   * Callback that sets the filter state.
   * @param {object} filter - A custom filter object.
   */
  const handleFilterChange = async (filter) => {
    //console.log(filter)
    setFilter(filter);
    setStorage('filter', filter);

    await updateDoc(userRef, {
      "selection.filter": filter
    });
  };

  
  /* ---------------------------- INFLATION STATES ---------------------------- */

  /**
   * Callback that sets the constant state.
   * @param {boolean} constant - The required value for constant state. 
   */
  const handleConstantChange = async (constant) => {
    setConstant(constant);
    await updateDoc(userRef, {
      "settings.constant": constant
    });
  };

  /* ----------------------------- FOOTNOTE STATES ---------------------------- */

  /**
   * Callback that sets the footnote state.
   * @param {boolean} footnote - The required value for footnote state.  
   */
  const handleFootnoteChange = async (footnote) => {
    setFootnote(footnote);
    await updateDoc(userRef, {
      "settings.footnotes": footnote
    });
  };

  /* ------------------------------ THEME STATES ------------------------------ */
  
  /**
   * Callback that sets the theme state and sets the theme.
   * @param {boolean} boolean - The required value for footnote state.
   */
  const handleThemeChange = async (boolean) => {
    setTheme(boolean);

    await updateDoc(userRef, {
      "settings.theme": boolean
    });

    let htmlElement = document.documentElement;
    boolean ? htmlElement.dataset.bsTheme = 'dark' : htmlElement.dataset.bsTheme = 'light';
  };

  /* ------------------------------ PRESET STATES ----------------------------- */

  const [preset, setPreset] = useState(false);

  /**
   * Callback that sets the preset state.
   * @param {Objects[]} municipalities - The municipalities array. Municipality is an array of objects in the form {name: string, formattedName: string, MUNID: number}.
   * @returns 
   */
  const handlePresetChange = (municipalities) => {
    setPreset(municipalities);
  };

  /* -------------------------------------------------------------------------- */
  /*                                 COMPONENTS                                 */
  /* -------------------------------------------------------------------------- */

  return (          
    <Container fluid>          
      <Row>
        <LeftNavbar 
          show={show} 
          handleClose={handleClose}
          dashboard={dashboard}
          handleDashboardChange={handleDashboardChange}
          municipality={municipality}
          handleMunicipalityChange={handleMunicipalityChange}
          municipalities={municipalities}
          handleMunicipalitiesChange={handleMunicipalitiesChange}
          years={years}
          handleStartYearChange={handleStartYearChange}
          handleEndYearChange={handleEndYearChange}
          constant={constant}
          handleConstantChange={handleConstantChange}
          footnote={footnote}
          handleFootnoteChange={handleFootnoteChange}
          theme={theme}
          handleThemeChange={handleThemeChange}
          dashboardLoading={dashboardLoading}
          user={user}
          admin={admin}
          preset={preset}
          handlePresetChange={handlePresetChange}
          handleGenerate={handleGenerate}
          filter={filter}
          handleFilterChange={handleFilterChange}
        />
        <Col xl={2} className='d-none d-xl-block'></Col>
        <Col xl={10} className='px-0 overflow-hidden' style={{height: '100svh'}}>
          <TopNavbar 
            handleShow={handleShow}
            constant={constant}
            handleConstantChange={handleConstantChange}
            footnote={footnote}
            handleFootnoteChange={handleFootnoteChange}
            theme={theme}
            handleThemeChange={handleThemeChange}
            user={user}
            admin={admin}
          /> 
          <Container fluid className='overflow-y-auto' style={{height: '95svh'}}>                  
            <Dashboards 
              municipality={municipality}
              municipalities={municipalities}
              years={years} 
              dashboard={dashboard}
              constant={constant}
              footnote={footnote}
              theme={theme}
              dashboardLoading={dashboardLoading}
              handleDashboardLoading={handleDashboardLoading}
              generate={generate}
              handleGenerate={handleGenerate}
              filter={filter}
            />
          </Container>
        </Col>
      </Row>
    </Container>
  );
}

export default DashboardTemplate;