// Assets
import HemsonLogoRed from '../../assets/Hemson Logo.png'

/**
 * Hemson logo.
 * @component
 * @param {Object} props - The component accepts props.
 * @param {number} [props.width] - Width of the logo in pixels [Optional].
 * @returns The Hemson logo.
 */
const HemsonLogo = ({ width }) => {
  return (
    <a href="https://hemson.com/">
      <img 
        className="img-fluid" 
        src={HemsonLogoRed}
        alt='Hemson logo' 
        width={width} 
      />
    </a>
  );
}

export default HemsonLogo;