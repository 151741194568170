import { useState, useEffect } from 'react';

/**
 * A span component with three animated dots (...) that can used within text elements (exp. Loading...).
 * @component
 * @returns span element with three animated dots.
 */
const LoadingDots = () => {
  const [dots, setDots] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots.length < 3 ? prevDots + '.' : ''));
    }, 500); // Change the interval time if you want the animation to be faster or slower

    return () => clearInterval(interval);
  }, []);

  return (
    <span style={{ display: 'inline-block', width: '1em', textAlign: 'left' }}>
      {dots}
    </span>
  );
};

export default LoadingDots;
