// Components
import ProgressBar from 'react-bootstrap/ProgressBar';
import GeneralDashboardCard from '../Cards/GeneralDashboardCard';
import Col from 'react-bootstrap/Col';
// Styles
import styles from './Spinners.module.css';

/**
 * Progress bar for dashboard loading.
 * @component
 * @param {Object} props - The component accepts props.
 * @param {number} props.progress The progress value 0-100.
 * @returns Progress bar component for dashboard loading. 
 */
function LoadingBar({progress}) {
  return (
    <Col xs={12} xl={10} className={`z-2 position-absolute bottom-0 end-0 ${styles.opacity50Overlay}`} style={{height: '100svh'}}>
      <Col xs={6} xl={4} className='position-absolute top-50 start-50 translate-middle'>
        <GeneralDashboardCard>
          <p className='text-center h5'>Loading...</p>
          <ProgressBar animated now={progress} className='col'/>
        </GeneralDashboardCard>
      </Col>
    </Col>
  );
}

export default LoadingBar;