// React
import { useState, useEffect } from 'react';
// Styles
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';
// Components
import { Typeahead, Highlighter } from 'react-bootstrap-typeahead';
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useTooltipsContext } from '../Context/TooltipsContext';
// Helpers
import { municipalityNames } from '../../configuration/MunicipalityNames.js';
import { dashboardType } from '../../configuration/dashboardNames.js';

/**
 * The municipality selection input.
 * @components
 * @param {Object} props - The component accepts props.
 * @param {string} props.dashboard - Dashboard selection value.
 * @param {Objects[]} props.municipality - Municipality selection value.
 * @param {function(Event): void} props.handleMunicipalityChange - Callback to handle municipality change.
 * @param {Objects[]} props.municipalities - Municipalities selection value.
 * @param {function(Event): void} props.handleMunicipalitiesChange - Callback to handle municipalities change. 
 * @param {boolean} props.dashboardLoading - Dashboard loading spinner state.
 * @param {Objects[]} props.preset - The preset municipalities array.
 * @param {boolean} [props.upperTier] - Boolean indicating whether the typeahead should only show upper/single-tier municipalities. Default = false.  
 */
const MunicipalitySelect = ({
  dashboard,
  municipality,
  handleMunicipalityChange,
  municipalities,
  handleMunicipalitiesChange,
  dashboardLoading,
  preset,
  upperTier = false
})  => {
  
  /* -------------------------------- Tooltips -------------------------------- */

  const { tooltips } = useTooltipsContext();

  const multiMunicipalityTooltip = (props) => (
    <Tooltip id="multiMunicipalityTooltip" {...props}>
      <div className='text-start'>
        <p className='mb-0'>✔ Begin typing to search for municipalities. Multiple municipalities can be selected by clicking on its name once its displayed.</p>
      </div>
    </Tooltip>
  );

  const singleMunicipalityTooltip = (props) => (
    <Tooltip id="singleMunicipalityTooltip" {...props}>
      <div className='text-start'>
        <p className='mb-0'>✔ Begin typing to search for municipalities. Select a municipality by clicking on its name once its displayed</p>
      </div>
    </Tooltip>
  );
    

  /* ----------------------------- Dashboard Logic ---------------------------- */
  
  const type = dashboardType(dashboard);
  const dashboardIsMulti = type === 'multi';

  const [key, setKey] = useState(0);


  // Incrementing the key will remount the Typeahead component to enable correct municipality selection: one or multiple municipalities
  useEffect(() => {
    setKey((prevKey) => prevKey + 1);
  }, [dashboard]); // Reset key whenever dashboard prop changes

  useEffect(() => { // Replaces current municipal selection with preset if selected by user
    if (preset) {
      handleMunicipalitiesChange(preset);
      setKey((prevKey) => prevKey + 1);
    }
  }, [preset]);

  /**
   * Displays descriptive text on the type of municipality depending whether its an upper, lower or single-tier.
   * @param {string} type - The type of a municipality UT, LT or ST
   * @param {string} typeText - The upper tier name.
   * @returns {string} - A string indicating the text to display.
   */
  const municipalityType = (type, typeText) => {
    if (type === 'UT') {
      return 'Upper Tier';
    } else if (type === 'LT') {
      return `Upper Tier: ${typeText}`;
    } else if (type === 'ST') {
      return 'Single Tier';
    } else {
      return 'Error finding type.'
    }
  }

  /* ----------------------- Municipality List Selection ---------------------- */

  const filteredMunicipalities = upperTier ? municipalityNames.filter(municipality => ['UT', 'ST'].includes(municipality.type)) : municipalityNames;
  
  /* -------------------------------- Component ------------------------------- */

  return (
    <Form.Group className="mb-4">
      <Form.Label className='fw-semibold icon-link'>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pin-map" viewBox="0 0 16 16">
          <path fillRule="evenodd" d="M3.1 11.2a.5.5 0 0 1 .4-.2H6a.5.5 0 0 1 0 1H3.75L1.5 15h13l-2.25-3H10a.5.5 0 0 1 0-1h2.5a.5.5 0 0 1 .4.2l3 4a.5.5 0 0 1-.4.8H.5a.5.5 0 0 1-.4-.8z"/>
          <path fillRule="evenodd" d="M8 1a3 3 0 1 0 0 6 3 3 0 0 0 0-6M4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999z"/>
        </svg>
        Select Municipalities
      </Form.Label>     
      <OverlayTrigger
        trigger={tooltips ? ['focus', 'hover'] : ''}
        placement="auto-end"
        delay={{ show: 250, hide: 400 }}
        overlay={dashboardIsMulti ? multiMunicipalityTooltip : singleMunicipalityTooltip}
      >
        <div>
          <Typeahead
            renderMenuItemChildren={(option, { text }) => (
              <>
                <Highlighter search={text}>{option.formatName}</Highlighter>,
                <div>
                  <span style={{fontSize: '0.75rem'}}>ID: {option.MUNID}, {municipalityType(option.type, option.upperTierName)}</span>
                </div>
              </>
            )}
            key={key}
            clearButton
            disabled={dashboardLoading}
            caseSensitive={false}
            id='municipality-select'
            ignoreDiacritics={true}
            labelKey={'formatName'} // Needed if the options array is objects
            options={filteredMunicipalities}
            placeholder={dashboardIsMulti ? 'Select municipalities...' : 'Select a municipality...'}
            multiple={dashboardIsMulti}
            onChange={dashboardIsMulti ? (selectedMunicipalities) => handleMunicipalitiesChange(selectedMunicipalities) : (selectedMunicipality) => handleMunicipalityChange(selectedMunicipality)}
            defaultSelected={dashboardIsMulti ? municipalities : municipality}
            //selected={dashboardIsMulti ? municipalities : municipality}
            positionFixed

          />
        </div>
      </OverlayTrigger>
    </Form.Group>          
  );
}

export default MunicipalitySelect;