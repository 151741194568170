/**
 * Component that generates google map.
 * @component
 * @param {Object} props - The component accepts props.
 * @param {boolean} props.theme - Theme selection value.
 * @param {boolean} props.error - The error indicator.
 * @param {string} [props.name] - A full municipal name [default='Province of Ontario'].
 * @returns Google map component
 */
const Map = ({ 
  theme,
  error, 
  name = 'Province of Ontario',
}) => {
  let mapBaseURL = 'https://www.google.com/maps/embed/v1/place?key=AIzaSyBaRXpiqGDKlR0pghia-LmFi4rioP0vs10&q=';

  if (error) {
    return (
      <div style={{height: 430}}>
        <div className='d-flex justify-content-center align-items-center h-100 w-100'>
          <div className='text-center'>
            <p>No Data</p>
          </div>
        </div> 
      </div>
    );
  }

  // Note height includes the button portion chart height = 400px + button div height = 30px
  return (
    <div style={{height: 430}}>
      <iframe
        id="map"
        src={mapBaseURL + `${name}` + '+ON'}
        className="rounded w-100 h-100"
        loading="lazy"
        allowFullScreen
        referrerPolicy="no-referrer-when-downgrade"
        title="map"
        style={theme ? {filter: 'invert(90%) hue-rotate(180deg)'} : {filter: 'none'}}
      />
    </div>
  );
};

export default Map;