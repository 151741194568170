
/* -------------------------------------------------------------------------- */
/*                       Operating Expenditure Dashboard                      */
/* -------------------------------------------------------------------------- */

// These names must be consistent with the keys in the firRowFilter object in the backend

const opExpServiceArea = [
  { option: 'Total', category: 'All Categories' },
  { option: 'General Government', category: 'General Government' },
  { option: 'Program Support', category: 'General Government' },
  { option: 'Fire', category: 'Protection Service' },
  { option: 'Police', category: 'Protection Service' },
  { option: 'Court Services', category: 'Protection Service' },
  { option: 'Other Protection Services', category: 'Protection Service' },
  { option: 'By Law Enforcement', category: 'Protection Service' },
  { option: 'Building', category: 'Protection Service' },
  { option: 'Roads and Related', category: 'Transportation Services' },
  { option: 'Transit', category: 'Transportation Services' },
  { option: 'Other Transportation', category: 'Transportation Services' },
  { option: 'Water', category: 'Environmental Services' },
  { option: 'Wastewater', category: 'Environmental Services' },
  { option: 'Stormwater', category: 'Environmental Services' },
  { option: 'Waste Management', category: 'Environmental Services' },
  { option: 'Other Environmental', category: 'Environmental Services' },
  { option: 'Public Health', category: 'Health Services' },
  { option: 'Ambulance', category: 'Health Services' },
  { option: 'Cemetery', category: 'Health Services' },
  { option: 'Other Health Services', category: 'Health Services' },
  { option: 'Social Housing', category: 'Social Housing and Family Services' },
  { option: 'Social Services', category: 'Social Housing and Family Services' },
  { option: 'Parks', category: 'Recreation and Cultural Services' },
  { option: 'Recreation', category: 'Recreation and Cultural Services' },
  { option: 'Library', category: 'Recreation and Cultural Services' },
  { option: 'Museum and Cultural Services', category: 'Recreation and Cultural Services' },
  { option: 'Planning', category: 'Planning and Development' },
  { option: 'Other', category: 'Other' },
]

const opExpType = [
  { option: 'Total' },
  { option: 'Salaries, Wages and Benefits' },
  { option: 'Interest on Long Term Debt' },
  { option: 'Materials' },
  { option: 'Contracted Services' },
  { option: 'Rents and Financial Expenses' },
  { option: 'External Transfers' },
  { option: 'Amortization' },
  { option: 'Indirect Costs' },
  { option: 'Transfers Between Departments' },
]

/* -------------------------------------------------------------------------- */
/*                             User Fees Dashboard                            */
/* -------------------------------------------------------------------------- */

const userFeeServiceArea = [
  { option: 'Total' },
  { option: 'Fire' },
  { option: 'Police' },
  { option: 'Building' },
  { option: 'Planning' },
  { option: 'Transit' },
  { option: 'Parks' },
  { option: 'Recreation' },
  { option: 'Library' }
];

/* -------------------------------------------------------------------------- */
/*                             Workforce Dashboard                            */
/* -------------------------------------------------------------------------- */

const workforceServiceArea = [
  { option: 'Total', category: 'All Categories' },
  { option: 'Administration', category: 'General Government' },
  { option: 'Fire Uniform', category: 'Protection Services' },
  { option: 'Fire Civilian', category: 'Protection Services' },
  { option: 'Police Uniform', category: 'Protection Services' },
  { option: 'Police Civilian', category: 'Protection Services' },
  { option: 'Court Services Uniform', category: 'Protection Services' },
  { option: 'Court Services Civilian', category: 'Protection Services' },
  { option: 'Transit', category: 'Transportation and Environmental Services' },
  { option: 'Public Works', category: 'Transportation and Environmental Services' },
  { option: 'Ambulance Uniform', category: 'Health Services' },
  { option: 'Ambulance Civilian', category: 'Health Services' },
  { option: 'Public Health', category: 'Health Services' },
  { option: 'Long Term Care', category: 'Social and Family Services' },
  { option: 'Social Services and Related', category: 'Social and Family Services' },
  { option: 'Parks and Recreation', category: 'Recreation and Cultural Services' },
  { option: 'Library', category: 'Recreation and Cultural Services' },
  { option: 'Planning', category: 'Planning and Development' },
  { option: 'Other', category: 'Other' },
]

const employees = [
  { option: 'Total'},
  { option: 'Full Time'},
  { option: 'Part Time'},
  { option: 'Seasonal'}
];

/**
 * Object containing all schema data for use in custom typeahead filter. It is organized by dashboard name.
 */
export const masterFilterObject = {
  'Operating Expenditure' : [
    {
      filterTextName: 'Service Area',
      uniqueId: 'opExpServiceArea',
      typeaheadOptions: opExpServiceArea,
      defaultStateValue: [opExpServiceArea[1].option]
    },
    {
      filterTextName: 'Expenditure Type',
      uniqueId: 'opExpType',
      typeaheadOptions: opExpType,
      defaultStateValue: [opExpType[1].option]
    },
  ],

  'User Fees': [
    {
      filterTextName: 'Service Area',
      uniqueId: 'userFeeServiceArea',
      typeaheadOptions: userFeeServiceArea,
      defaultStateValue: [userFeeServiceArea[1].option]
    },
  ],
  
  'Workforce' : [
    {
      filterTextName: 'Service Area',
      uniqueId: 'typeaheadOne',
      typeaheadOptions: workforceServiceArea,
      defaultStateValue: [workforceServiceArea[1].option]
    },
    {
      filterTextName: 'Employees',
      uniqueId: 'typeaheadTwo',
      typeaheadOptions: employees,
      defaultStateValue: [employees[1].option]
    },
  ],

  'Region Operating Expenditure' : [
    {
      filterTextName: 'Service Area',
      uniqueId: 'opExpServiceArea',
      typeaheadOptions: opExpServiceArea,
      defaultStateValue: [opExpServiceArea[1].option]
    },
    {
      filterTextName: 'Expenditure Type',
      uniqueId: 'opExpType',
      typeaheadOptions: opExpType,
      defaultStateValue: [opExpType[1].option]
    },
  ],
}


/**
 * Takes the masterFilterObject and returns an object containing all uniqueId: defaultStateValue pairs for use in the filter state as the default value.
 * @param {Object} obj - The masterFilterObject object.
 * @returns {Object} An object.
 */
const createMasterFilterDefaultObject = (obj) => {
  const result = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const filters = obj[key];
      filters.forEach(filter => {
        result[filter.uniqueId] = filter.defaultStateValue;
      });
    }
  }
  return result;
}

/**
 * The object containing all uniqueId: defaultStateValue pairs for use in the filter state as the default value.
 */
export const masterFilterDefaultObject = createMasterFilterDefaultObject(masterFilterObject);
