// Components
import HemsonAnalyticsLogo from './HemsonAnalyticsLogo';

/**
 * Hemson Analytics logo and brand.
 * @component
 * @param {Object} props - The component accepts props.
 * @param {string} [props.text] - Addtional text with name [Optional].
 * @returns Logo and Hemson Analytics brand.
 */
const HemsonAnalyticsBrand = ({text}) => {
  return (
    <div className="d-flex align-items-center">
      <HemsonAnalyticsLogo width={100}/>
      <h5 className={`mb-0 ${text ? 'ms-2' : ''}`} style={{color: 'rgb(144, 118, 44)'}}><span>{text}</span></h5>
    </div>
  );
}

export default HemsonAnalyticsBrand;