// React
import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
// Firebase
import { auth } from './services/firebase.config';
import { useAuthState } from 'react-firebase-hooks/auth';
// Components
import Container from 'react-bootstrap/Container';
import LoadScreen from './components/Spinners/LoadScreen';
import LoginPage from './pages/LoginPage';
import DashboardPage from './pages/DashboardPage';
import Admin from './pages/Admin';

const App = () => {

  const [user, loading, error] = useAuthState(auth);
  const [admin, setAdmin] = useState(false);
  
  /* -------------------------- Authentication Error -------------------------- */
  
  if (error) {
    return (
      <Container fluid>
        <p>An authentication error occured. Please refresh the page and try again.</p>
      </Container>
    )
  }

  /* ------------------------- Authentication Loading ------------------------- */

  if (loading) {
    return <LoadScreen/>
  }

  /* ------------------------------ Invalid User ------------------------------ */
  
  if (!user) {
    return <LoginPage/>
  }

  /* ----------------------------- Check Privilege ---------------------------- */

  const checkPrivilege = async () => {
    const userToken = await user.getIdTokenResult();
    
    const isAdmin = userToken.claims?.admin || false;
    
    setAdmin(isAdmin);
  }

  checkPrivilege();
  
  /* --------------------------- Authenticated User --------------------------- */
  
  return (
    <Router>
      <Routes>
        
        <Route
          exact
          path='/'
          element={ 
            <DashboardPage
              user={user}
              admin={admin}
            /> 
          }
        />
        
        <Route
          exact
          path='/admin'
          element={ admin ? <Admin/> : (
            <Container fluid>
              <p>Unauthorized</p>
            </Container>
          )}
        />
      
      </Routes>
    </Router>
  );
}

export default App;

//   function AllowIfAuth() {
    //     const [user, loading, error] = useAuthState(auth);
    //     if (loading) {
    //         return <div> Loading... </div>;
    //     } else if (user) {
    //         return <div> Some Content </div>;
    //     } else if (error) {
    //         return <div>There was an authentication error.</div>;
    //     } else {
    //         return <LoginPage/>;
    //     }
    // }