// React
import React from 'react';
// Components
import Card from 'react-bootstrap/Card';

/**
 * The general dashboard card template used in the dashboard.
 * @component
 * @param {Object} props - The component accepts props.
 * @param {component} props.children - A component to display inside the card.
 * @param {string} props.className - Additional class names to be added above defaults.
 * @param {React.RefObject<HTMLElement>} props.ref - Reference to the underlying Card element.
 * @returns A dashboard card component.
 */
const GeneralDashboardCard = React.forwardRef(({children, className}, ref) => {
  return (
    <Card ref={ref} border='primary' className={`shadow bg-primary-subtle ${className}`}>
      <Card.Body>
        {children}
      </Card.Body>
    </Card>
  );
});

export default GeneralDashboardCard;