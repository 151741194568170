// React
import { useRef } from 'react';
// Chart JS
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
// Styles
import styles from './Charts.module.css'
// Components
import Button from 'react-bootstrap/Button';
import LoadingChart from './LoadingChart';
import NoDataChart from './NoDataChart';
import DownloadIcon from '../Icons/DownloadIcon';
// Helpers
import { 
  chartConfig, 
  downloadCSV,
  shortenXAxisLabel,
  yAxisFormat,
  checkDataArrayForAllNullValues
} from './ChartHelpers';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

/* -------------------------------------------------------------------------- */
/*                              STACKED BAR CHART                             */
/* -------------------------------------------------------------------------- */

/**
 * Component for a standard chartjs stacked bar chart.
 * @component
 * @param {Object} props - The component accepts props.
 * @param {Objects[]} props.labelArray - Length of this array should be equal to the number of categories to be included on the chart. For example, an array to show two stacked bars for two sets of data like expenditure and revenue would be [
  {
    label: 'Expenditure',
    xKey: 'year',
    yKey: 'values.expenditure'
  },
  {
    label: 'Revenue',
    xKey: 'year',
    yKey: 'values.revenue'
  }
].
 * @param {Objects[]} props.dataArray - An array of chart data. Must be configured in the form [...{xAxisKey: string, values:{...value: number} }]
 * @param {boolean} props.dashboardLoading - Dashboard loading spinner state.
 * @param {boolean} props.theme - Theme selection value.
 * @param {string} [props.chartTitle] - The title of the chart [default='No Title Provided']. 
 * @param {boolean} [props.showLegend] - Boolean to display legend [default=false].
 * @param {boolean} [props.sort] - Indicates wether the bars should be sorted in ascending order.
 * @param {string} [props.symbol] - Specifies the format to be applied. Takes one of three values: 'none', 'dollar', or 'percent'. Default is 'dollar'.
 * @param {number} [props.decimal] - The number of decimal places for value in tooltip. Default is 0.
 * @param {object | boolean} [props.filterText] - Object of text indicating the filters chosen. Default is false.
 * @returns A dashboard stacked bar chart component in a card.
 */
const StackedBarChart = ({
  
  labelArray,
  dataArray,
  dashboardLoading,
  theme,
  chartTitle = 'No Title Provided',
  showLegend = false,
  sort = false,
  symbol = 'dollar',
  decimal = 0,
  filterText = false
}) => {

  const chartRef = useRef(null);

  /* --------------------------------- Loading -------------------------------- */

   if (dashboardLoading) {
    return <LoadingChart/>
  }
  
  /* ----------------------------- Check Null Data ---------------------------- */
  
  const dataOk = checkDataArrayForAllNullValues(dataArray);

  if (chartTitle === null || labelArray === null || dataArray === null || dataOk === false) {
    return <NoDataChart/>
  }

  try {

    /* ---------------------------------- Theme --------------------------------- */
  
    const selectedThemeColor = theme ? chartConfig.labels.darkThemeColor : chartConfig.labels.lightThemeColor;
  
    /* ------------------------------ Chart Options ----------------------------- */
    
    let options = {
      responsive: true,
      maintainAspectRatio: false,
      parsing: {
        xAxisKey: labelArray[0].xKey
      },
      plugins: {
        title: {
          display: true,
          text: chartTitle,
          color: selectedThemeColor,
          font: chartConfig.labels.font,
        },
        legend: {
          display: showLegend,
          position: 'bottom',
          labels: {
            color: selectedThemeColor,
            font: chartConfig.labels.font,
          },
        },      
        tooltip: {
          callbacks: {
            // title: tooltipTitleFormat,
            label: (context) => {
              
              const symbolChoice = symbol === 'dollar' ? '$' : '';

              const datasetLabel = context.dataset.label;
              const dataIndex = context.dataIndex;
              const rawValue = context.dataset.data[dataIndex].values[datasetLabel];
              const rawTotal = context.dataset.data[dataIndex].stackTotal;
              
              // The order of formatting ensures that there are no infinite repeating decimals.
              const value = (Number(rawValue)).toFixed(decimal);
              const total = (Number(rawTotal)).toFixed(decimal);
              const share = (Number(value/total)*100).toFixed(1);

              return [
                `${datasetLabel}`,
                `Value: ${symbolChoice}${Number(value).toLocaleString("en-US")}`,
                `Share: ${share}%`,
                `Total: ${symbolChoice}${Number(total).toLocaleString("en-US")}`
              ]
            }      
          }
        },
      },
      scales: {
        y: {
          stacked: true,
          ticks: {
            callback: (value, index, values) => yAxisFormat(value, index, values, symbol),
            color: selectedThemeColor,
            font: chartConfig.labels.font,
          }
        },
        x: {
          stacked: true,
          ticks: {
            callback: (value, index, values) => shortenXAxisLabel(value, index, values, dataArray[index][labelArray[0].xKey]),
            color: selectedThemeColor,
            font: chartConfig.labels.font,
          },
        },
      }
    };
    
    /* -------------------------------- Sort Data ------------------------------- */
    
    // Get the keys for stack values
    const stackNames = Object.keys(dataArray[0].values); //Array of names for each stack in a bar
    const stackTotals = dataArray.map(element => {
      
      const numberOfStacks = stackNames.length;
      let totalStackValue = 0;
      
      for (let index = 0; index < numberOfStacks; index++) {
        totalStackValue += element.values[stackNames[index]]; // object of the values
      }
  
      return totalStackValue;
    });
  
    stackTotals.forEach((element, index) => {
      dataArray[index].stackTotal = element;
    });
  
    
    if (sort) {
      dataArray.sort((a, b) => a.stackTotal - b.stackTotal);
    }
  
    /* -------------------------------- Set Data -------------------------------- */
  
    let dataConfig = labelArray.map((element, index) => {
      return {
        label: element.label,
        data: dataArray,
        backgroundColor: chartConfig.colorArray[index % chartConfig.colorArray.length],
        borderColor: chartConfig.chartGraphics.borderColor,
        borderWidth: chartConfig.chartGraphics.borderWidth,
        parsing: {
          yAxisKey: element.yKey
        }
      }
    });
  
    let data = {
      labels: dataArray.map(element => {
        return element[labelArray[0].xKey]
      }), // labels set manually so that they can be formatted using a callback in the options object at options.scales.x.ticks.callback
      datasets: dataConfig 
    };
      
    /* -------------------------------- Component ------------------------------- */
  
    return (
      <div className={styles.chartBoxHeight}>
        <div className={styles.chartHeight}>
          <Bar
            options={options}
            data={data}
            fallbackContent={<p>No Data</p>}
            ref={chartRef}
          />
        </div>
        <div className={`text-end ${styles.downloadButtonDivHeight}`}>
          <Button
            variant="outline-primary"
            size='sm'
            className={`mt-2 fw-bold icon-link icon-link-hover ${styles.downloadButton}`}
            onClick={(event) => downloadCSV(event, chartRef, filterText)}
          >
            <DownloadIcon width={16}/>
            Export Data
          </Button>
        </div>
      </div>
    );
  
  /* ---------------------------------- Error --------------------------------- */
  
  } catch (error) {

    console.log(error);

    return <NoDataChart message={`Chart Error: ${error.message}`}/>
    
  }
}

export default StackedBarChart;