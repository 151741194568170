// React
import { useRef } from 'react';
// Chart JS
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartjsPluginStacked100 from "chartjs-plugin-stacked100";
import { Bar } from 'react-chartjs-2';
// Styles
import styles from './Charts.module.css'
// Components
import Button from 'react-bootstrap/Button';
import LoadingChart from './LoadingChart';
import NoDataChart from './NoDataChart';
import DownloadIcon from '../Icons/DownloadIcon';
// Helpers
import { 
  chartConfig,
  shortenXAxisLabel
} from './ChartHelpers';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartjsPluginStacked100
);

/* -------------------------------------------------------------------------- */
/*                          PERCENT STACKED BAR CHART                         */
/* -------------------------------------------------------------------------- */

/**
 * Component for a chartjs stacked percent bar chart.
 * @component
 * @param {Object} props - The component accepts props.
 * @param {Objects[]} props.labelArray - Length of this array should be equal to the number of categories to be included on the chart. For example, an array to show two stacked bars for two sets of data like expenditure and revenue would be [
  {
    label: 'Expenditure',
    xKey: 'year',
    yKey: 'values.expenditure'
  },
  {
    label: 'Revenue',
    xKey: 'year',
    yKey: 'values.revenue'
  }
].
 * @param {Objects[]} props.dataArray - An array of chart data. Must be configured in the form [...{xAxisKey: string, values:{...value: number} }]
 * @param {boolean} props.dashboardLoading - Dashboard loading spinner state.
 * @param {boolean} props.theme - Theme selection value.
 * @param {string} [props.chartTitle] - The title of the chart [default='No Title Provided'].
 * @param {string} [props.symbol] - Specifies the format to be applied. Takes one of three values: 'none', 'dollar', or 'percent'. Default is 'dollar'.
 * @param {number} [props.decimal] - The number of decimal places for value in tooltip. Default is 0.
 * @param {object | boolean} [props.filterText] - Object of text indicating the filters chosen. Default
 * @param {string} [props.sortLabel] - The name of the label on which to sort. Also referred to as the category on which to sort. Default is no sorting.
 is false.
 @returns A dashboard percent bar chart component in a card.
 */
const PercentStackedBarChart = ({
  labelArray,
  dataArray,
  dashboardLoading,
  theme,
  chartTitle = 'No Title Provided',
  symbol = 'dollar',
  decimal = 0,
  filterText = false,
  sortLabel = 'None'
}) => {

  const chartRef = useRef(null);

  /* --------------------------------- Loading -------------------------------- */

  if (dashboardLoading) {
    return <LoadingChart/>
  }
  
  /* ----------------------------- Check Null Data ---------------------------- */
  
  if (chartTitle === null || labelArray === null || dataArray === null) {
    return <NoDataChart/>
  }

  try {
    
    /* ---------------------------------- Theme --------------------------------- */
  
    const selectedThemeColor = theme ? chartConfig.labels.darkThemeColor : chartConfig.labels.lightThemeColor;
    
    /* ------------------------------ Chart Options ----------------------------- */
  
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        title: {
          display: true,
          text: chartTitle,
          color: selectedThemeColor,
          font: chartConfig.labels.font,
        },
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            color: selectedThemeColor,
            font: chartConfig.labels.font,
          },
          onClick: null
        },      
        tooltip: {
          callbacks: {
            label: (tooltipItem) => {

              const startSymbolChoice = symbol === 'dollar' ? '$' : '';

              const data = tooltipItem.chart.data;
              const datasetIndex = tooltipItem.datasetIndex;
              const index = tooltipItem.dataIndex;
              const datasetLabel = data.datasets[datasetIndex].label || "";
              // You can use two type values.
              // `data.originalData` is raw values,
              // `data.calculatedData` is percentage values, e.g. 20.5 (The total value is 100.0)
              const originalValue = (Number(data.originalData[datasetIndex][index])).toFixed(decimal);
              const rateValue = data.calculatedData[datasetIndex][index];
  
              const sumValuesAtIndex = (arr, index) => {
                let sum = 0;
                
                for (let i = 0; i < arr.length; i++) {
                  if (arr[i][index] !== undefined) {
                    sum += arr[i][index];
                  }
                }
                
                return sum;
              }
             
              const totalValue = (Number(sumValuesAtIndex(data.originalData, index))).toFixed(decimal);
    
              return [
                `${datasetLabel}`,
                `Value: ${startSymbolChoice}${Number(originalValue).toLocaleString("en-US")}`,
                `Share: ${rateValue}%`,
                `Total: ${startSymbolChoice}${Number(totalValue).toLocaleString("en-US")}`
              ];
            },
          }
        },
        stacked100: {
          enable: true,
          precision: 0,
          replaceTooltipLabel: false
        }
      },
      scales: {
        y: {
          beginAtZero: true,
          stacked: true,
          max: 100,
          ticks: {
            callback: (value, index, values) => `${value}%`,
            color: selectedThemeColor,
            font: chartConfig.labels.font,
          }
        },
        x: {
          stacked: true,
          ticks: {
            callback: (value, index, values) => shortenXAxisLabel(value, index, values, labels[index]),
            color: selectedThemeColor,
            font: chartConfig.labels.font,
          },
        },
      }
    };
  
    /* -------------------------------- Sort Data ------------------------------- */
  
    const municipalityNames = Object.keys(dataArray[0].values); // Municipal names in an array
  
    /**
     * Creates an key:value pair that will be used to sort the elements of the data in ascending order. They value of this pair is the percent share of the total.
     * @param {string} orderCategory - The label on which sorting will occur.
     * @returns {object[]} - Data array with added key:value pair.
     */
    const orderIndicatorFunction = (orderCategory) => {
      
      // Create an array structure that can be ordered and maintain data integrity
      let arrayConstructor = municipalityNames.map(municipalityName => {
        
        const data = dataArray.map(data => {
          return {
            category: data.label,
            value: data.values[municipalityName]
          }
        })
  
        const orderIndex = data.reduce((accumulator, currentValue) => {
          return accumulator + currentValue.value;
        }, 0)
        
        // Check that orderCategory exists in the data
        const orderCategoryExists = data.some(element => element.category === orderCategory);

        let orderCategoryObject;

        // Find the correct orderCategory value
        if (orderCategoryExists) {
          orderCategoryObject = data.find(element => element.category === orderCategory);
        } else {
          // The fallback just takes the first category in data array
          orderCategoryObject = data.find(element => element.category === data[0].category);
        }
        
        const orderCategoryValue = orderCategoryObject.value;
        
        return {  
          municipalityName: municipalityName,
          data: data,
          orderIndex: orderCategoryValue/orderIndex
        }
      });
  
      return arrayConstructor;
    }
  
    const unorderedArray = orderIndicatorFunction(sortLabel);
    
    // Sort of sortLabel value prop is provided, otherwise do not sort
    const orderedArray = sortLabel ==='None' ? unorderedArray : unorderedArray.sort((a, b) => a.orderIndex - b.orderIndex);
   
    // labels array is the x-axis labels. This is ordered so that the data structure is also ordered
    const labels = orderedArray.map(element => {
      return element.municipalityName;
    });
    
    /* -------------------------------- Set Data -------------------------------- */
    
    const datasets = dataArray.map((element, index) => {
      return {
        label: element.label,
        data: labels.map(municipalityName => {
            return element.values[municipalityName];
          }
        ),
        backgroundColor: chartConfig.colorArray[index % chartConfig.colorArray.length],
        borderColor: chartConfig.chartGraphics.borderColor,
        borderWidth: chartConfig.chartGraphics.borderWidth,     
      }
    });
    
    let data = {
      labels: labels,
      datasets: datasets
    };
    
  
    /* ------------------------ Prepare Data for Download ----------------------- */
  
    const convertDataToCSVPercentStackedBarChart = (data, filterText = false) => {
      // First row
      const labels = 'Label,' + data.labels.join(',');
  
      // Setup data
      const csvRows = data.datasets.map(entry => {
        return `${entry.label},${entry.data.join(',')}`;
      })
  
      // Combine rows into a CSV string
      const dataCSV = `${labels}\n${csvRows.join('\n')}`;

      // Add filterText if applicable
      let filterString = '';
      if (filterText) {
        const filterNames = Object.keys(filterText);
        filterNames.forEach(filterName => {
          filterString += `${filterName}: ${filterText[filterName].join('/')}\n`
        })
      }
  
      // Add Footnotes
      const dataCSVWithFootNotes = dataCSV + 
      `\n\nData: ${chartTitle}\n${filterString}Exported on ${new Date()} from app.hemsonanalytics.com\nPowered by Hemson`;
  
      return dataCSVWithFootNotes;
    };
  
    const downloadCSVPercentStackedBarChart = (event, chartRef, filterText) => {
      
      // Get the chart
      const data = chartRef.current.config._config.data;
  
      // Convert data to CSV format
      const csvContent = "data:text/csv;charset=utf-8," + encodeURIComponent(convertDataToCSVPercentStackedBarChart(data, filterText));
  
      // Create a download link
      const link = document.createElement('a');
      link.href = csvContent;
      link.download = `${chartTitle}.csv`;
      link.click();
    }
  
    /* -------------------------------- Component ------------------------------- */
  
    return (
      <div className={styles.chartBoxHeight}>
        <div className={styles.chartHeight}>
          <Bar
            options={options}
            data={data}
            fallbackContent={<p>No Data</p>}
            ref={chartRef}
          />
        </div>
        <div className={`text-end ${styles.downloadButtonDivHeight}`}>
          <Button
            variant="outline-primary"
            size='sm'
            className={`mt-2 fw-bold icon-link icon-link-hover ${styles.downloadButton}`}
            onClick={(event) => downloadCSVPercentStackedBarChart(event, chartRef, filterText)}
          >
            <DownloadIcon width={16}/>
            Export Data
          </Button>
        </div>
      </div>
    );

  /* ---------------------------------- Error --------------------------------- */  

  } catch (error) {

    console.log(error);

    return <NoDataChart message={`Chart Error: ${error.message}`}/>
    
  }
}

export default PercentStackedBarChart;

/* 
Sample configuraion for this to work
<Bar
  options={{
    plugins: {
      stacked100: { enable: true },
    },
  }}
  data={{
    labels: ["Municipality Name 1", "Municipality Name 2"],
    datasets: [
      { label: "Residential", data: [5, 25], backgroundColor: "rgba(244, 143, 177, 0.6)" },
      { label: "Commercial", data: [15, 10], backgroundColor: "rgba(255, 235, 59, 0.6)" },
      { label: "Industrial", data: [10, 8], backgroundColor: "rgba(100, 181, 246, 0.6)" },
    ],
  }}
  fallbackContent={<p>No Data</p>}
  ref={chartRef}
/>

*/