/* ---------------------------------- YEARS --------------------------------- */

export const firFirstYear = 2009;
export const firLastYear = 2024; // Set to the current year

/* -------------------------------------------------------------------------- */
/*                               DASHBOARD NAMES                              */
/* -------------------------------------------------------------------------- */

export const dashboardCategories = [
  'Profile',
  'Comparator'
]

/**
 * The `dashboards` array contains objects that represent various dashboards available in Hemson Analytics. Each object includes the necessary information to identify and configure the dashboard.
 * @param {string} name - The name of the dashboard.
 * @param {string} type - The type of the dashboard, which can be either "single" or "multi" to indicate if it's for a single municipality or multiple municipalities.
 * @param {string} category - The category of the dashboard, typically sourced from the `dashboardCategories` array.
 * @param {string} description - A description of the contents of the dashboard.
 * @param {boolean} [filter] - Indicates whether the dashboard includes additional filters beyond municipality and year. Defaults to `false` if not specified.
 * @param {boolean} [experimental] - Indicates whether the dashboard is experimental. Defaults to `false` if not specified.
 * @param {boolean} [hideYearFilter] - Indicates whether the year filter should be hidden on the dashboard. Defaults to `false` if not specified.
 * @param {boolean} [upperTier] - Boolean indicating whether the typeahead should only show upper/single-tier municipalities. Default = false.
 * @param {boolean} [ignoreInflation] - Boolean indicating whether the dashboard hides the inflation indicator. Default = false.
 */
export const dashboards = [
  {
    name: 'Overview',
    type: 'single',
    category: dashboardCategories[0],
    description: 'Provides an overview of commonly requested financial data from the Financial Information Return.'
  },
  {
    name: 'Expenditure',
    type: 'single',
    category: dashboardCategories[0],
    description: 'Provides an overview of operating expenditures and outstanding debt.'
  },
  {
    name: 'Revenue',
    type: 'single',
    category: dashboardCategories[0],
    description: 'Provides an overview of different sources of revenue.'
  },
  {
    name: 'Growth',
    type: 'single',
    category: dashboardCategories[0],
    description: 'Estimates of population and households based on the Statcan census.',
    ignoreInflation: true
  },
  {
    name: 'Assessment',
    type: 'multi',
    category: dashboardCategories[1],
    description: 'Comparisons of the assessment base across municipalities to benchmark the fiscal capacity of municipalities to collect taxes.'
  },
  {
    name: 'Debt Levels',
    type: 'multi',
    category: dashboardCategories[1],
    description: 'Comparisons of debt levels across municipalities to benchmark the use of debt as a financing tool.'
  },
  {
    name: 'Reserves',
    type: 'multi',
    category: dashboardCategories[1],
    description: 'Benchmark of reserves and reserve levels across municipalities.'
  },
  {
    name: 'Taxation',
    type: 'multi',
    category: dashboardCategories[1],
    description: 'Benchmarks on the level of tax revenue collected across municipalities.'
  },
  {
    name: 'Operating Expenditure',
    type: 'multi',
    category: dashboardCategories[1],
    description: 'Benchmarks on operating expenditures across municipalities.',
    filter: true
  },
  {
    name: 'User Fees',
    type: 'multi',
    category: dashboardCategories[1],
    description: 'Comparison of the level of user fee revenue across municipalities.',
    filter: true
  },
  {
    name: 'Tax Bill',
    type: 'multi',
    category: dashboardCategories[1],
    description: 'Comparison of tax rates and typical tax bills across municipalities.',
    filter: false,
    ignoreInflation: true
  },
  {
    name: 'Workforce',
    type: 'multi',
    category: dashboardCategories[1],
    description: 'The workforce across municipalities compared through the number of funded postions.',
    filter: true,
    ignoreInflation: true
  },
  {
    name: 'Construction Activity',
    type: 'multi',
    category: dashboardCategories[1],
    description: 'The level of construction activity measured by the value of building permits across municipalities.',
    filter: true,
    ignoreInflation: true
  },
  {
    name: 'Affordable Units',
    type: 'multi',
    category: dashboardCategories[1],
    description: 'Average market prices of new homes and affordability threshholds for the purposes of DC and CBC exemptions as calculated by the Province of Ontario.',
    filter: true,
    hideYearFilter: true,
    ignoreInflation: true
  },
  {
    name: 'Utility Rates',
    type: 'multi',
    category: dashboardCategories[1],
    description: 'A comparison of the typical water and wastewater bill across municipalities.',
    filter: false,
    ignoreInflation: true
  },
  {
    name: 'Region Assessment',
    type: 'multi',
    category: dashboardCategories[1],
    description: 'Comparisons of the assessment base across combined upper and lower-tier municipalities and single-tier municipalities. Used to benchmark the fiscal capacity of municipalities to collect taxes at a wider regional level and better compare across municipalities where services are delineated between upper and lower-tier governments. This dashboard shows cumulative assessment comparisons for upper-tiers (sum of assessment of all lower-tiers) compared along side single-tier municipalities.',
    upperTier: true
  },
  {
    name: 'Region Debt Levels',
    type: 'multi',
    category: dashboardCategories[1],
    description: 'Comparisons of the debt levels across combined upper and lower-tier municipalities and single-tier municipalities. Used to benchmark the use of debt as a financing tool at a wider regional level and better compare across municipalities where services are delineated between upper and lower-tier governments. This dashboard shows cumulative comparisons of debt for all services (at the upper and lower-tier level) compared along side single-tier municipalities.',
    upperTier: true
  },
  {
    name: 'Region Reserves',
    type: 'multi',
    category: dashboardCategories[1],
    description: 'Comparisons of reserve levels across combined upper and lower-tier municipalities and single-tier municipalities. Used to benchmark the use reserves for long-term savings at a wider regional level and better compare across municipalities where services are delineated between upper and lower-tier governments. This dashboard shows cumulative comparisons of reserves for all services (at the upper and lower-tier level) compared along side single-tier municipalities.',
    upperTier: true
  },
  {
    name: 'Region Taxation',
    type: 'multi',
    category: dashboardCategories[1],
    description: 'Comparisons of the level of tax revenue collected across combined upper and lower-tier municipalities and single-tier municipalities. Used to benchmark taxation revenue at a wider regional level and better compare across municipalities where services are delineated between upper and lower-tier governments. This dashboard shows cumulative comparisons of tax revenue (sum of upper and lower-tier tax revenue) compared along side single-tier municipalities.',
    upperTier: true
  },
  {
    name: 'Region Operating Expenditure',
    type: 'multi',
    category: dashboardCategories[1],
    description: 'Comparisons of operating expenditure across combined upper and lower-tier municipalities and single-tier municipalities. Used to benchmark operating expenditure at a wider regional level and better compare across municipalities where services are delineated between upper and lower-tier governments. This dashboard shows the cumulative operating expenditures (sum of upper and lower-tier operating expenditures for all service areas) compared along side single-tier municipalities.',
    filter: true,
    upperTier: true
  },
]

const dashboardsObjectHolder = {};

dashboards.forEach(dashboard => {
  dashboardsObjectHolder[dashboard.name] = {
    name: dashboard.name,
    type: dashboard.type,
    category: dashboard.category,
    description: dashboard?.description ? dashboard?.description : 'No dashboard description provided.',
    hideYearFilter: dashboard?.hideYearFilter ? true : false,
    experimental: dashboard?.experimental ? true : false,
    upperTier: dashboard?.upperTier ? true : false,
    ignoreInflation: dashboard?.ignoreInflation ? true : false
  };
});

/* A utility object containing key/values of the form
[dashboard name] : {
  name: name,
  type: type,
  category: category,
  description: string,
  hideYearFilter: boolean,
  experimental: boolean,
  upperTier: boolean,
}
*/
export const dashboardObject = dashboardsObjectHolder;

// Array of only dashboard names
export const dashboardNames = dashboards.map(object => object.name);

/**
 * Take a dashboard name and returns its type: 'single' or 'multi'.
 * @param {string} dashboardName - The name of a dashboard. 
 * @returns {string} Returns the type of the dashboard.
 */
export const dashboardType = (dashboardName) => {
  const foundDashboard = dashboards.find(object => object.name === dashboardName);
  return foundDashboard.type;
}

/**
 * Take a dashboard name and returns its filter: true or false.
 * @param {string} dashboardName - The name of a dashboard. 
 * @returns {boolean} Returns the filter type of the dashboard.
 */
export const dashboardFilter = (dashboardName) => {
  const foundDashboard = dashboards.find(object => object.name === dashboardName);
  return foundDashboard.filter ? foundDashboard.filter : false;
}