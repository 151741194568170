// Styles
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';
// Components
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useTooltipsContext } from '../Context/TooltipsContext';
// Helpers
import { dashboards, dashboardCategories } from '../../configuration/dashboardNames.js';

/**
 * The dashboard selection input.
 * @components
 * @param {Object} props - The component accepts props.
 * @param {string} props.dashboard - Dashboard selection value.
 * @param {function(Event): void} props.handleDashboardChange - Callback to handle dashboard change.
 * @param {boolean} props.dashboardLoading - Dashboard loading spinner state.
 * @returns A dashboard selection dropdown component.  
 */
const DashboardSelect = ({
    dashboard, 
    handleDashboardChange,
    dashboardLoading
  }) => {
    
    const { tooltips } = useTooltipsContext();

    const renderTooltip = (props) => (
      <Tooltip id="renderTooltip" {...props}>
        <div className='text-start'>
          <p className='mb-0'>✔ Select dashboards from this menu. Each dashboard contains different financial information.</p>
        </div>
      </Tooltip>
    );

    return (
      <Form.Group className="mb-4">
        <Form.Label className='fw-semibold icon-link'>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-window-fullscreen" viewBox="0 0 16 16">
            <path d="M3 3.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0m1.5 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0m1 .5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1"/>
            <path d="M.5 1a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 .5.5h15a.5.5 0 0 0 .5-.5v-13a.5.5 0 0 0-.5-.5zM1 5V2h14v3zm0 1h14v8H1z"/>
          </svg>
          Select Dashboard
        </Form.Label>
        <OverlayTrigger
          trigger={tooltips ? ['focus', 'hover'] : ''}
          placement="auto-end"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip}
        >
          <Form.Select
            disabled={dashboardLoading}
            aria-label="Select dashboard"
            onChange={(event) => handleDashboardChange(event.target.value)}
            value={dashboard}
          >
            {
              dashboardCategories.map(category => {
                return <optgroup key={category} label={category}>
                  {
                    dashboards.map(dashboardObject => {
                      if (dashboardObject.category === category) {
                        return <option key={dashboardObject.name} value={dashboardObject.name}>{dashboardObject.name}</option>
                      }                   
                    })
                  }
                </optgroup>
              })
            }            
          </Form.Select>

        </OverlayTrigger>
      </Form.Group>      
    );
  }
  
  export default DashboardSelect;