/**
 * Bootstrap icon of a closed door.
 * @component
 * @param {Object} props - The component accepts props.
 * @param {number} [props.width] - Width of the icon in pixels [Optional].
 * @param {number} [props.height] - Height of the icon in pixels [Optional].
 * @returns An icon of a closed door.
 */
const DoorCloseIcon = ({ width, height }) => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-door-closed" viewBox="0 0 16 16">
        <path d="M3 2a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v13h1.5a.5.5 0 0 1 0 1h-13a.5.5 0 0 1 0-1H3zm1 13h8V2H4z"/>
        <path d="M9 9a1 1 0 1 0 2 0 1 1 0 0 0-2 0"/>
      </svg>
    </>
  );
}

export default DoorCloseIcon;