// Components
import Collapse from 'react-bootstrap/Collapse';
import GeneralDashboardCard from "../Cards/GeneralDashboardCard";
import Placeholder from 'react-bootstrap/Placeholder';

/**
 * A collapsable footnote box for the dashboard.
 * @component
 * @param {Object} props - The component accepts props. 
 * @param {boolean} props.footnote - The footnote display state.
 * @param {Object} props.footnoteObject - The footnote data object must contain the keys: name, source, descrition and insight.
 * @param {string} [props.footnoteObject.name] - The name of the chart [default='Not applicable].
 * @param {string} [props.footnoteObject.source] - The source of the data [default='Not applicable].
 * @param {string} [props.footnoteObject.description] - A description of the chart [default='Not applicable].
 * @param {string} [props.footnoteObject.insight] - A short paragraph [default='Not applicable].
 * @param {boolean} props.dashboardLoading - Dashboard loading spinner state.
 * @returns A footnote box for a dashboard chart. 
 */
const Footnote = ({
  footnote = true,
  footnoteObject = {
    name: 'Not applicable',
    source: 'Not applicable',
    description: 'Not applicable',
    insight: 'Not applicable'
  },
  dashboardLoading
}) => {

  const footnoteContent = () => {
    
    if (dashboardLoading) {
      return (
        <>
          <Placeholder as="p" animation="wave" className='mb-1'>
            <Placeholder xs={1} /><span>{' '}</span><Placeholder xs={3} />
          </Placeholder>
          <Placeholder as="p" animation="wave" className='mb-1'>
            <Placeholder xs={1} /><span>{' '}</span><Placeholder xs={1} />
          </Placeholder>
          <Placeholder as="p" animation="wave" className='mb-1'>
            <Placeholder xs={2} /><span>{' '}</span><Placeholder xs={4} />
          </Placeholder>
          <Placeholder as="p" animation="wave" className='mb-0'>
            <Placeholder xs={2} /><span>{' '}</span><Placeholder xs={6} />
          </Placeholder>
        </>
      )
    } else {
      return (
        <>
          <p className='mb-1'><span className="fw-bold">Name: </span>{footnoteObject?.name || 'Not Applicable'}</p>
          <p className='mb-1'><span className="fw-bold">Source: </span>{footnoteObject?.source || 'Not Applicable'}</p>
          <p className='mb-1'><span className="fw-bold">Description: </span>{footnoteObject?.description || 'Not Applicable'}</p>
          <p className='mb-0'><span className="fw-bold">Hemson Insight: </span>{footnoteObject?.insight || 'Not Applicable'}</p>
        </>
      );
    }
  };

  return (
    <Collapse in={footnote}>
      <GeneralDashboardCard className="mt-3">
        {footnoteContent()}
      </GeneralDashboardCard>
    </Collapse>
  );
}

export default Footnote;