// Components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

/**
 * Single municipality dashboard layout. In this layout row 1 has 3 demograhic boxes, row 2 has 2 charts and 1 map.
 * @component
 * @param {Object} props - The component accepts props.
 * @param {React.JSX.Element} props.demoBox1 - A card component.
 * @param {React.JSX.Element} props.demoBox2 - A card component.
 * @param {React.JSX.Element} props.demoBox3 - A card component.
 * @param {React.JSX.Element} props.map - A map component.
 * @param {React.JSX.Element} props.chart1 - A chart component.
 * @param {React.JSX.Element} props.chart2 - A chart component.
 * @param {React.JSX.Element} props.mapFootnote - A map footnote component. 
 * @param {React.JSX.Element} props.chartFootnote1 - A chart footnote component for chart1.
 * @param {React.JSX.Element} props.chartFootnote2 - A chart footnote component for chart2. 
 * @returns A layout for a profile dashboard.      
 */
const ThreeChartHorizontalDashboardLayout = ({
  demoBox1,
  demoBox2,
  demoBox3,
  map,
  chart1,
  chart2,
  mapFootnote,
  chartFootnote1,
  chartFootnote2,
}) => {
  return (
    <>
      {/* 3 demographic boxes */}
      <Row className="pt-3 g-3">
        <Col md={4}>
          {demoBox1}
        </Col>
        <Col md={4}>
          {demoBox2}
        </Col>
        <Col md={4}>
          {demoBox3}
        </Col>      
      </Row>
      
      {/* 2 charts and 1 map */}
      <Row className="pt-3 g-3">
        <Col lg={4}>
          {chart1}
          {chartFootnote1}
        </Col>
        <Col lg={4}>
          {chart2}
          {chartFootnote2}
        </Col>
        <Col lg={4}>
          {map}
          {mapFootnote}
        </Col>
      </Row>

    </>
  );
}

export default ThreeChartHorizontalDashboardLayout;