// Assets
import HemsonAnalyticsLogoRed from "../../assets/Hemson-Analytics-Logo.webp";
// Styles
import styles from './Icons.module.css';

/**
 * Hemson Analytics logo.
 * @component
 * @param {Object} props - The component accepts props.
 * @param {number} [props.width] - Width of the logo in pixels [Optional].
 * @param {height} [props.height] - Height of the logo in pixels [Optional].
 * @param {boolean} [props.rotate] - Rotate the icon in place [Optional].
 * @returns The Hemson Analytics logo.
 */
const HemsonAnalyticsLogo = ({ 
  width,
  height,
  rotate = false
}) => {

  return (
    <a href="https://hemsonanalytics.com/">
      <div className="position-relative" style={{width: 'fit-content'}}>
        <img
          className={`img-fluid ${rotate ? styles.rotate3d : ''}`}
          src={HemsonAnalyticsLogoRed}
          alt='Hemson Analytics logo'
          width={width}
          height={height}
        />
      </div>
    </a>
  );
}

export default HemsonAnalyticsLogo;