// React
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// Firebase
import { auth } from '../../services/firebase.config'
import { useUpdatePassword } from 'react-firebase-hooks/auth';
// Components
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from "react-bootstrap/Form";
import InputGroup from 'react-bootstrap/InputGroup';
import Spinner from "react-bootstrap/Spinner";
import SignOutMenuButton from "../Buttons/SignOutMenuButton";
import ColorModeSwitchButton from "../Buttons/ColorModeSwitchButton";
import InflationSwitchButton from "../Buttons/InflationSwitchButton";
import TooltipsSwitchButton from "../Buttons/TooltipsSwitchButton";
import FootnoteSwitchButton from "../Buttons/FootnoteSwitchButton";
import DoorCloseIcon from "../Icons/DoorCloseIcon";
import LockIcon from "../Icons/LockIcon";
// Assets
import dashboardMenuImage from "../../assets/dashboard_menu.png";
import generateButtonImage from "../../assets/generate-button.webp";

/* -------------------------------------------------------------------------- */
/*                                    Icons                                   */
/* -------------------------------------------------------------------------- */

const PersonCardIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-vcard" viewBox="0 0 16 16">
      <path d="M5 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4m4-2.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5M9 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4A.5.5 0 0 1 9 8m1 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5"/>
      <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zM1 4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H8.96q.04-.245.04-.5C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1 1 0 0 1 1 12z"/>
    </svg>
  );
};

const GearIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-gear-fill" viewBox="0 0 16 16">
      <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"/>
    </svg>
  );
}

const QuestionMarkIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-lg" viewBox="0 0 16 16">
      <path fillRule="evenodd" d="M4.475 5.458c-.284 0-.514-.237-.47-.517C4.28 3.24 5.576 2 7.825 2c2.25 0 3.767 1.36 3.767 3.215 0 1.344-.665 2.288-1.79 2.973-1.1.659-1.414 1.118-1.414 2.01v.03a.5.5 0 0 1-.5.5h-.77a.5.5 0 0 1-.5-.495l-.003-.2c-.043-1.221.477-2.001 1.645-2.712 1.03-.632 1.397-1.135 1.397-2.028 0-.979-.758-1.698-1.926-1.698-1.009 0-1.71.529-1.938 1.402-.066.254-.278.461-.54.461h-.777ZM7.496 14c.622 0 1.095-.474 1.095-1.09 0-.618-.473-1.092-1.095-1.092-.606 0-1.087.474-1.087 1.091S6.89 14 7.496 14"/>
    </svg>
  );
}

/* -------------------------------------------------------------------------- */
/*                                Account Modal                               */
/* -------------------------------------------------------------------------- */

/**
 * A form to change the users password.
 * @component
 * @returns A form component.
 */
const UpdatePassword = () => {
  
  const [newPassword, setNewPassword] = useState('');
  const [retypeNewPassword, setRetypeNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const [updatePassword, updating, error] = useUpdatePassword(auth);

  /**
   * Callback to handle password change.
   * @param {Event} e - Event object.
   * @returns 
   */
  const handleUpdatePassword = async (e) => {
    e.preventDefault();

    // Check passwords match
    if (newPassword !== retypeNewPassword) {
      setMessage(<p className="text-danger">Passwords do not match.</p>);
      setNewPassword('');
      setRetypeNewPassword('');
      return;
    }
    
    const success = await updatePassword(newPassword);
    
    if (success) {
      setMessage(<p className="text-success">Password update successful.</p>);
      setNewPassword('');
      setRetypeNewPassword('');
    }
  }

  // Set message on error
  if (error) {
    setMessage(<p className="text-danger">An error occured, please try again. Error: {error.message}</p>);
    setNewPassword('');
    setRetypeNewPassword('');
  }
  
  return (
    <Form onSubmit={handleUpdatePassword}>
      <InputGroup className="mb-3">
        <InputGroup.Text id="password-icon">
          <LockIcon width={16}/>
        </InputGroup.Text>
        <Form.Control
          required
          disabled={updating}
          type="password"
          placeholder="New password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
      </InputGroup>
      <InputGroup className="mb-3">
        <InputGroup.Text id="password-icon">
          <LockIcon width={16}/>
        </InputGroup.Text>
        <Form.Control
          required
          disabled={updating}
          type="password"
          placeholder="Retype new password"
          value={retypeNewPassword}
          onChange={(e) => setRetypeNewPassword(e.target.value)}
        />
      </InputGroup>

      <Button variant="primary" className='mb-3 fw-bold icon-link icon-link-hover' type="submit" size="sm" disabled={updating}>
        {updating ? 
          <Spinner size="sm"/>
          :
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-return-right" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5"/>
          </svg>
        }
        <span>Submit</span>
      </Button>

      {message}
    </Form>
  );
};

/**
 * A modal containing the account menu.
 * @component
 * @param {Object} props - The component accepts props.
 * @param {boolean} props.show - React Bootstrap show prop.
 * @param {function(Event): void} props.handleClose - React Bootstrap callback to handle modal close.
 * @param {boolean} props.user - User object.
 * @returns Dashboard account modal component.
 */
const AccountModal = ({ show, handleClose, user }) => {
  
  return (
    <Modal 
      show={show} 
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton className="bg-primary-subtle bg-gradient">
        <Modal.Title className="icon-link">
          <PersonCardIcon/>
          Account
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Container>
          
        <Row className="mb-3">
          <Col>
            <h5>User Profile</h5>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col xs={12}>
            <p className="mb-0 fw-semibold">Email: {user.email}</p>
          </Col>
          <Col xs={12}>
            <p className="fw-light text-body-secondary"><small>The email registered for this account.</small></p>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <hr className="border border-primary-subtle opacity-75"/>
            <h5>Change Password</h5>
          </Col>
        </Row>
        
        <Row>
          <Col xs={12}>
            <UpdatePassword/>
          </Col>
          <Col xs={12}>
            <p className="fw-light text-body-secondary"><small>You can enter a new password to change it.</small></p>
          </Col>
        </Row>
        
        </Container>
      </Modal.Body>
      <Modal.Footer className="bg-primary-subtle bg-gradient">
        <Button variant="outline-secondary" className="fw-bold icon-link icon-link-hover" onClick={handleClose}>
          <DoorCloseIcon width={16}/>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

/* -------------------------------------------------------------------------- */
/*                               Settings Modal                               */
/* -------------------------------------------------------------------------- */

/**
 * A modal containing the dashboard settings menu.
 * @component
 * @param {Object} props - The component accepts props.
 * @param {boolean} props.show - React Bootstrap show prop.
 * @param {function(Event): void} props.handleClose - React Bootstrap callback to handle modal close.
 * @param {boolean} props.constant - Inflation selection value.
 * @param {function(Event): void} props.handleConstantChange - Callback to handle inflation change.
 * @param {boolean} props.footnote - Footnote selection value.
 * @param {function(Event): void} props.handleFootnoteChange - Callback to handle footnote change.
 * @param {boolean} props.theme - Theme selection value.
 * @param {function(Event): void} props.handleThemeChange - Callback to handle theme change.
 * @returns Dashboard settings modal component.
 */
const SettingsModal = ({ 
  show, 
  handleClose,
  constant,
  handleConstantChange,
  footnote,
  handleFootnoteChange,
  theme,
  handleThemeChange 
}) => {
  
  const currentYear = new Date().getFullYear();

  return (
    <Modal 
      show={show} 
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton className="bg-primary-subtle bg-gradient">
        <Modal.Title className="icon-link">
          <GearIcon/>
          Settings
        </Modal.Title>
      </Modal.Header>
      
      <Modal.Body>
        <Container>
          
          <Row className="mb-3">
            <Col xs={12}>
              <h5>Dashboard Settings</h5>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={6}>
              <p className="mb-0 fw-semibold">Inflation</p>
            </Col>
            <Col xs={6}>
              <InflationSwitchButton
                constant={constant}
                handleConstantChange={handleConstantChange}
              />
            </Col>
            <Col xs={12}>
              <p className="fw-light text-body-secondary"><small>Setting to constant will adjust all dollar values to constant {currentYear} dollars. Some dashboards may ignore this setting.</small></p>
            </Col>
          </Row>
          
          <Row className="mb-3">
            <Col xs={6}>
              <p className="mb-0 fw-semibold">Footnotes</p>
            </Col>
            <Col xs={6}>
              <FootnoteSwitchButton
                footnote={footnote}
                handleFootnoteChange={handleFootnoteChange}
              />
            </Col>
            <Col xs={12}>
              <p className="fw-light text-body-secondary"><small>Display helpful footnotes under each chart.</small></p>
            </Col>
          </Row>
            
          <Row className="mb-3">
            <Col xs={6}>
              <p className="mb-0 fw-semibold">Tooltips</p>
            </Col>
            <Col xs={6}>
              <TooltipsSwitchButton/>
            </Col>
            <Col xs={12}>
              <p className="fw-light text-body-secondary"><small>Display helpful tooltips on important parts of the dashboard.</small></p>
            </Col>
          </Row>
          
          <Row className="mb-3">
            <Col xs={12}>
              <hr className="border border-primary-subtle opacity-75"/>
              <h5>Appearance Settings</h5>
            </Col>
          </Row>
          
          <Row>
            <Col xs={6}>
              <p className="mb-0 fw-semibold">Theme</p>
            </Col>
            <Col xs={6}>
              <ColorModeSwitchButton
                theme={theme}
                handleThemeChange={handleThemeChange}
              />
            </Col>
            <Col xs={12}>
              <p className="fw-light text-body-secondary"><small>Change between light and dark themes.</small></p>
            </Col>
          </Row>
        
        </Container>
      </Modal.Body>
      
      <Modal.Footer className="bg-primary-subtle bg-gradient">
        <Button variant="outline-secondary" className="fw-bold icon-link icon-link-hover" onClick={handleClose}>
          <DoorCloseIcon width={16}/>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

/* -------------------------------------------------------------------------- */
/*                                 Help Modal                                 */
/* -------------------------------------------------------------------------- */

/**
 * A modal containing the dashboard help menu.
 * @component
 * @param {Object} props - The component accepts props.
 * @param {boolean} props.show - React Bootstrap show prop.
 * @param {function(Event): void} props.handleClose - React Bootstrap callback to handle modal close.
 * @returns Dashboard help modal component.
 */
const HelpModal = ({ show, handleClose }) => {
  
  return (
    <Modal 
      show={show} 
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton className="bg-primary-subtle bg-gradient">
        <Modal.Title className="icon-link">
          <QuestionMarkIcon/>
          Help
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="h5">Selecting Data</p>
        <div className="clearfix">
          <p>Viewing data reports for municipalities can all be completed from the Dashboard Menu.</p>
          <figure className="figure float-lg-start me-3">
            <img src={dashboardMenuImage} className="img-thumbnail" style={{width: 200}} alt="Dashboard menu"/>
            <figcaption className="figure-caption">The Dashboard Menu.</figcaption>
          </figure>
          <p><span className="fw-semibold">Step 1:</span> Select a dashboard from the dropdown. There are two types of dashboards: Profile and Comparator. Profile dashboards display reports for individual municipalities and allow only one selection. Comparator dashboards show comparable benchmarks across municipalities and allow multiple selections.</p>
          <p><span className="fw-semibold">Step 2:</span> Select a municipality. Municipalities can be searched by typing in the dropdown. To select multiple options, search and click municipalities to add them to the list. Click ✖️ to remove them.</p>
          <p><span className="fw-semibold">Step 3:</span> Select a year. Some dashboards have an option to select a start and end year.</p>
          <figure className="figure float-lg-end ms-3">
            <img src={generateButtonImage} className="img-thumbnail" style={{width: 200}} alt="Dashboard menu"/>
            <figcaption className="figure-caption">The Generate button.</figcaption>
          </figure> 
          <p><span className="fw-semibold">Step 4:</span> Click the "Generate" button to update the data. Whenever the selection changes, the user is prompted to refresh the data. Look for a glowing button labelled "Refresh Data."</p>                   
        </div>

        <div className="alert alert-success" role="alert">
          ❕ Tip: Some dashboards have additional filtering options.
        </div>
        <hr className="border border-primary-subtle opacity-75"/>
        <p>Want to talk to an expert at Hemson? <a href="https://www.hemson.com/contact-us/" target="_blank" rel="noopener noreferrer">Reach out.</a></p>
      </Modal.Body>
      <Modal.Footer className="bg-primary-subtle bg-gradient">
        <Button variant="outline-secondary" className="fw-bold icon-link icon-link-hover" onClick={handleClose}>
          <DoorCloseIcon width={16}/>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

/* -------------------------------------------------------------------------- */
/*                              Dashboard Options                             */
/* -------------------------------------------------------------------------- */

/**
 * A dropdown with the dashboard options list.
 * @component
 * @param {Object} props - The component accepts props.
 * @param {boolean} props.constant - Inflation selection value.
 * @param {function(Event): void} props.handleConstantChange - Callback to handle inflation change.
 * @param {boolean} props.footnote - Footnote selection value.
 * @param {function(Event): void} props.handleFootnoteChange - Callback to handle footnote change.
 * @param {boolean} props.theme - Theme selection value.
 * @param {function(Event): void} props.handleThemeChange - Callback to handle theme change.
 * @param {boolean} props.user - User object.
 * @param {boolean} props.admin - Admin state.
 * @returns The list of dashboard options in a dropdown.  
 */
const DashboardOptions = ({
  constant,
  handleConstantChange,
  footnote,
  handleFootnoteChange,
  theme,
  handleThemeChange,
  user,
  admin
}) => {
  const navigate = useNavigate();

  /* -------------------------- ACCOUNT MODAL STATES -------------------------- */

  const [showAccount, setShowAccount] = useState(false);

  const handleCloseAccount = () => setShowAccount(false);
  const handleShowAccount = () => setShowAccount(true);

  /* -------------------------- SETTINGS MODAL STATES ------------------------- */
  
  const [showSettings, setShowSettings] = useState(false);

  const handleCloseSettings = () => setShowSettings(false);
  const handleShowSettings = () => setShowSettings(true);

  /* ---------------------------- HELP MODAL STATES --------------------------- */

  const [showHelp, setShowHelp] = useState(false);

  const handleCloseHelp = () => setShowHelp(false);
  const handleShowHelp = () => setShowHelp(true);

  /* -------------------------------- Component ------------------------------- */

  return (
    <>       
      <Dropdown drop="up">
        
        <Dropdown.Toggle variant="primary" id="dashboard-options" className="w-100">
          <span>
            <GearIcon/>
            <span className="ms-1 align-text-top fw-bold">Dashboard Options</span>
          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu className="w-100">
          <Dropdown.ItemText className="fw-semibold">
            {user.email}
          </Dropdown.ItemText>
          
          <Dropdown.Divider />
          <Dropdown.Item
            as="button"
            onClick={handleShowAccount}
            className="fw-semibold icon-link icon-link-hover"
          >
            <PersonCardIcon/>
            Account
          </Dropdown.Item>
          <Dropdown.Item
            as="button"
            onClick={handleShowSettings}
            className="fw-semibold icon-link icon-link-hover"
          >
            <GearIcon/>
            Settings
          </Dropdown.Item>
          <Dropdown.Item
            as="button"
            onClick={handleShowHelp}
            className="fw-semibold icon-link icon-link-hover"
          >
            <QuestionMarkIcon/>
            Help
          </Dropdown.Item>
          <Dropdown.Item as="a" className="fw-semibold icon-link icon-link-hover" href="https://www.hemson.com/contact-us/" target="_blank" rel="noopener noreferrer">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
              <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
            </svg>
            Contact
          </Dropdown.Item>
          
          {admin ? 
            <Dropdown.Item as="button" className="fw-semibold icon-link icon-link-hover" onClick={() => navigate('/admin')}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-controller" viewBox="0 0 16 16">
                <path d="M11.5 6.027a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0m-1.5 1.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1m2.5-.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0m-1.5 1.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1m-6.5-3h1v1h1v1h-1v1h-1v-1h-1v-1h1z"/>
                <path d="M3.051 3.26a.5.5 0 0 1 .354-.613l1.932-.518a.5.5 0 0 1 .62.39c.655-.079 1.35-.117 2.043-.117.72 0 1.443.041 2.12.126a.5.5 0 0 1 .622-.399l1.932.518a.5.5 0 0 1 .306.729q.211.136.373.297c.408.408.78 1.05 1.095 1.772.32.733.599 1.591.805 2.466s.34 1.78.364 2.606c.024.816-.059 1.602-.328 2.21a1.42 1.42 0 0 1-1.445.83c-.636-.067-1.115-.394-1.513-.773-.245-.232-.496-.526-.739-.808-.126-.148-.25-.292-.368-.423-.728-.804-1.597-1.527-3.224-1.527s-2.496.723-3.224 1.527c-.119.131-.242.275-.368.423-.243.282-.494.575-.739.808-.398.38-.877.706-1.513.773a1.42 1.42 0 0 1-1.445-.83c-.27-.608-.352-1.395-.329-2.21.024-.826.16-1.73.365-2.606.206-.875.486-1.733.805-2.466.315-.722.687-1.364 1.094-1.772a2.3 2.3 0 0 1 .433-.335l-.028-.079zm2.036.412c-.877.185-1.469.443-1.733.708-.276.276-.587.783-.885 1.465a14 14 0 0 0-.748 2.295 12.4 12.4 0 0 0-.339 2.406c-.022.755.062 1.368.243 1.776a.42.42 0 0 0 .426.24c.327-.034.61-.199.929-.502.212-.202.4-.423.615-.674.133-.156.276-.323.44-.504C4.861 9.969 5.978 9.027 8 9.027s3.139.942 3.965 1.855c.164.181.307.348.44.504.214.251.403.472.615.674.318.303.601.468.929.503a.42.42 0 0 0 .426-.241c.18-.408.265-1.02.243-1.776a12.4 12.4 0 0 0-.339-2.406 14 14 0 0 0-.748-2.295c-.298-.682-.61-1.19-.885-1.465-.264-.265-.856-.523-1.733-.708-.85-.179-1.877-.27-2.913-.27s-2.063.091-2.913.27"/>
              </svg>
              Admin
            </Dropdown.Item> :
            ''
          }

          <Dropdown.Divider />

          <SignOutMenuButton/>

        </Dropdown.Menu>
      
      </Dropdown>
      
      <AccountModal
        show={showAccount}
        handleClose={handleCloseAccount}
        user={user}
      />
      <SettingsModal
        show={showSettings}
        handleClose={handleCloseSettings}
        constant={constant}
        handleConstantChange={handleConstantChange}
        footnote={footnote}
        handleFootnoteChange={handleFootnoteChange}
        theme={theme}
        handleThemeChange={handleThemeChange}
      />
      <HelpModal
        show={showHelp}
        handleClose={handleCloseHelp}
      />
    </>
  );
}

export default DashboardOptions;