const municipalityNamesJson = [
  {
    "MUNID": "11033",
    "FORMAT_NAME": "Addington Highlands (Township of)",
    "NAME": "Township of Addington Highlands",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Lennox And Addington"
  },
  {
    "MUNID": "39010",
    "FORMAT_NAME": "Adelaide-Metcalfe (Township of)",
    "NAME": "Township of Adelaide-Metcalfe",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Middlesex"
  },
  {
    "MUNID": "43002",
    "FORMAT_NAME": "Adjala-Tosorontio (Township of)",
    "NAME": "Township of Adjala-Tosorontio",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Simcoe"
  },
  {
    "MUNID": "47041",
    "FORMAT_NAME": "Admaston/Bromley (Township of)",
    "NAME": "Township of Admaston/Bromley",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Renfrew"
  },
  {
    "MUNID": "18005",
    "FORMAT_NAME": "Ajax (Town of)",
    "NAME": "Town of Ajax",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "Regional Municipality of Durham"
  },
  {
    "MUNID": "59011",
    "FORMAT_NAME": "Alberton (Township of)",
    "NAME": "Township of Alberton",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "2030",
    "FORMAT_NAME": "Alfred and Plantagenet (Township of)",
    "NAME": "Township of Alfred and Plantagenet",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "United Counties of Prescott and Russell"
  },
  {
    "MUNID": "46020",
    "FORMAT_NAME": "Algonquin Highlands (Township of)",
    "NAME": "Township of Algonquin Highlands",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Haliburton"
  },
  {
    "MUNID": "14015",
    "FORMAT_NAME": "Alnwick/Haldimand (Township of)",
    "NAME": "Township of Alnwick/Haldimand",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Northumberland"
  },
  {
    "MUNID": "22008",
    "FORMAT_NAME": "Amaranth (Township of)",
    "NAME": "Township of Amaranth",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Dufferin"
  },
  {
    "MUNID": "37025",
    "FORMAT_NAME": "Amherstburg (Town of)",
    "NAME": "Town of Amherstburg",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Essex"
  },
  {
    "MUNID": "49019",
    "FORMAT_NAME": "Armour (Township of)",
    "NAME": "Township of Armour",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "54036",
    "FORMAT_NAME": "Armstrong (Township of)",
    "NAME": "Township of Armstrong",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "47002",
    "FORMAT_NAME": "Arnprior (Town of)",
    "NAME": "Town of Arnprior",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Renfrew"
  },
  {
    "MUNID": "41037",
    "FORMAT_NAME": "Arran-Elderslie (Municipality of)",
    "NAME": "Municipality of Arran-Elderslie",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Bruce"
  },
  {
    "MUNID": "40030",
    "FORMAT_NAME": "Ashfield-Colborne-Wawanosh (Township of)",
    "NAME": "Township of Ashfield-Colborne-Wawanosh",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Huron"
  },
  {
    "MUNID": "15002",
    "FORMAT_NAME": "Asphodel-Norwood (Township of)",
    "NAME": "Township of Asphodel-Norwood",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Peterborough"
  },
  {
    "MUNID": "51011",
    "FORMAT_NAME": "Assiginack (Township of)",
    "NAME": "Township of Assiginack",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "7040",
    "FORMAT_NAME": "Athens (Township of)",
    "NAME": "Township of Athens",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "United Counties of Leeds and Grenville"
  },
  {
    "MUNID": "59001",
    "FORMAT_NAME": "Atikokan (Town of)",
    "NAME": "Town of Atikokan",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "7006",
    "FORMAT_NAME": "Augusta (Township of)",
    "NAME": "Township of Augusta",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "United Counties of Leeds and Grenville"
  },
  {
    "MUNID": "19046",
    "FORMAT_NAME": "Aurora (Town of)",
    "NAME": "Town of Aurora",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "Regional Municipality of York"
  },
  {
    "MUNID": "34011",
    "FORMAT_NAME": "Aylmer (Town of)",
    "NAME": "Town of Aylmer",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Elgin"
  },
  {
    "MUNID": "52028",
    "FORMAT_NAME": "Baldwin (Township of)",
    "NAME": "Township of Baldwin",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "12061",
    "FORMAT_NAME": "Bancroft (Town of)",
    "NAME": "Town of Bancroft",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Hastings"
  },
  {
    "MUNID": "43042",
    "FORMAT_NAME": "Barrie (City of)",
    "NAME": "City of Barrie",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "34003",
    "FORMAT_NAME": "Bayham (Municipality of)",
    "NAME": "Municipality of Bayham",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Elgin"
  },
  {
    "MUNID": "9024",
    "FORMAT_NAME": "Beckwith (Township of)",
    "NAME": "Township of Beckwith",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Lanark"
  },
  {
    "MUNID": "12005",
    "FORMAT_NAME": "Belleville (City of)",
    "NAME": "City of Belleville",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "51021",
    "FORMAT_NAME": "Billings (Township of)",
    "NAME": "Township of Billings",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "56014",
    "FORMAT_NAME": "Black River-Matheson (Township of)",
    "NAME": "Township of Black River-Matheson",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "32045",
    "FORMAT_NAME": "Blandford-Blenheim (Township of)",
    "NAME": "Township of Blandford-Blenheim",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Oxford"
  },
  {
    "MUNID": "57038",
    "FORMAT_NAME": "Blind River (Town of)",
    "NAME": "Town of Blind River",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "40010",
    "FORMAT_NAME": "Bluewater (Municipality of)",
    "NAME": "Municipality of Bluewater",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Huron"
  },
  {
    "MUNID": "48026",
    "FORMAT_NAME": "Bonfield (Township of)",
    "NAME": "Township of Bonfield",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "47015",
    "FORMAT_NAME": "Bonnechere Valley (Township of)",
    "NAME": "Township of Bonnechere Valley",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Renfrew"
  },
  {
    "MUNID": "44018",
    "FORMAT_NAME": "Bracebridge (Town of)",
    "NAME": "Town of Bracebridge",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "District Municipality of Muskoka"
  },
  {
    "MUNID": "43013",
    "FORMAT_NAME": "Bradford West Gwillimbury (Town of)",
    "NAME": "Town of Bradford West Gwillimbury",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Simcoe"
  },
  {
    "MUNID": "21010",
    "FORMAT_NAME": "Brampton (City of)",
    "NAME": "City of Brampton",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "Regional Municipality of Peel"
  },
  {
    "MUNID": "29002",
    "FORMAT_NAME": "Brant (County of)",
    "NAME": "County of Brant",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "29006",
    "FORMAT_NAME": "Brantford (City of)",
    "NAME": "City of Brantford",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "54032",
    "FORMAT_NAME": "Brethour (Township of)",
    "NAME": "Township of Brethour",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "14005",
    "FORMAT_NAME": "Brighton (Municipality of)",
    "NAME": "Municipality of Brighton",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Northumberland"
  },
  {
    "MUNID": "18039",
    "FORMAT_NAME": "Brock (Township of)",
    "NAME": "Township of Brock",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "Regional Municipality of Durham"
  },
  {
    "MUNID": "41030",
    "FORMAT_NAME": "Brockton (Municipality of)",
    "NAME": "Municipality of Brockton",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Bruce"
  },
  {
    "MUNID": "7015",
    "FORMAT_NAME": "Brockville (City of)",
    "NAME": "City of Brockville",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "38011",
    "FORMAT_NAME": "Brooke-Alvinston (Municipality of)",
    "NAME": "Municipality of Brooke-Alvinston",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Lambton"
  },
  {
    "MUNID": "41000",
    "FORMAT_NAME": "Bruce (County of)",
    "NAME": "County of Bruce",
    "TYPE": "UT",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "57021",
    "FORMAT_NAME": "Bruce Mines (Town of)",
    "NAME": "Town of Bruce Mines",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "47018",
    "FORMAT_NAME": "Brudenell, Lyndoch And Raglan (Township of)",
    "NAME": "Township of Brudenell, Lyndoch and Raglan",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Renfrew"
  },
  {
    "MUNID": "49022",
    "FORMAT_NAME": "Burk's Falls (Village of)",
    "NAME": "Village of Burk'S Falls",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "24002",
    "FORMAT_NAME": "Burlington (City of)",
    "NAME": "City of Burlington",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "Regional Municipality of Halton"
  },
  {
    "MUNID": "51028",
    "FORMAT_NAME": "Burpee And Mills (Township of)",
    "NAME": "Township of Burpee and Mills",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "21024",
    "FORMAT_NAME": "Caledon (Town of)",
    "NAME": "Town of Caledon",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "Regional Municipality of Peel"
  },
  {
    "MUNID": "49066",
    "FORMAT_NAME": "Callander (Municipality of)",
    "NAME": "Municipality of Callander",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "48022",
    "FORMAT_NAME": "Calvin (Township of)",
    "NAME": "Township of Calvin",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "30006",
    "FORMAT_NAME": "Cambridge (City of)",
    "NAME": "City of Cambridge",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "Regional Municipality of Waterloo"
  },
  {
    "MUNID": "9028",
    "FORMAT_NAME": "Carleton Place (Town of)",
    "NAME": "Town of Carleton Place",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Lanark"
  },
  {
    "MUNID": "49036",
    "FORMAT_NAME": "Carling (Township of)",
    "NAME": "Township of Carling",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "12067",
    "FORMAT_NAME": "Carlow/Mayo (Township of)",
    "NAME": "Township of Carlow/Mayo",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Hastings"
  },
  {
    "MUNID": "54029",
    "FORMAT_NAME": "Casey (Township of)",
    "NAME": "Township of Casey",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "2044",
    "FORMAT_NAME": "Casselman (Municipality of)",
    "NAME": "Municipality of Casselman",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "United Counties of Prescott and Russell"
  },
  {
    "MUNID": "15012",
    "FORMAT_NAME": "Cavan Monaghan (Township of)",
    "NAME": "Township of Cavan Monaghan",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Peterborough"
  },
  {
    "MUNID": "34015",
    "FORMAT_NAME": "Central Elgin (Municipality of)",
    "NAME": "Municipality of Central Elgin",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Elgin"
  },
  {
    "MUNID": "10025",
    "FORMAT_NAME": "Central Frontenac (Township of)",
    "NAME": "Township of Central Frontenac",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Frontenac"
  },
  {
    "MUNID": "40025",
    "FORMAT_NAME": "Central Huron (Municipality of)",
    "NAME": "Municipality of Central Huron",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Huron"
  },
  {
    "MUNID": "51009",
    "FORMAT_NAME": "Central Manitoulin (Municipality of)",
    "NAME": "Municipality of Central Manitoulin",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "12023",
    "FORMAT_NAME": "Centre Hastings (Municipality of)",
    "NAME": "Municipality of Centre Hastings",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Hastings"
  },
  {
    "MUNID": "23010",
    "FORMAT_NAME": "Centre Wellington (Township of)",
    "NAME": "Township of Centre Wellington",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Wellington"
  },
  {
    "MUNID": "54054",
    "FORMAT_NAME": "Chamberlain (Township of)",
    "NAME": "Township of Chamberlain",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "2005",
    "FORMAT_NAME": "Champlain (Township of)",
    "NAME": "Township of Champlain",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "United Counties of Prescott and Russell"
  },
  {
    "MUNID": "52092",
    "FORMAT_NAME": "Chapleau (Township of)",
    "NAME": "Township of Chapleau",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "59024",
    "FORMAT_NAME": "Chapple (Township of)",
    "NAME": "Township of Chapple",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "54047",
    "FORMAT_NAME": "Charlton And Dack (Municipality of)",
    "NAME": "Municipality of Charlton and Dack",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "36003",
    "FORMAT_NAME": "Chatham-Kent (Municipality of)",
    "NAME": "Municipality of Chatham-Kent",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "42031",
    "FORMAT_NAME": "Chatsworth (Township of)",
    "NAME": "Township of Chatsworth",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Grey"
  },
  {
    "MUNID": "48031",
    "FORMAT_NAME": "Chisholm (Township of)",
    "NAME": "Township of Chisholm",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "2036",
    "FORMAT_NAME": "Clarence-Rockland (City of)",
    "NAME": "City of Clarence-Rockland",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "United Counties of Prescott and Russell"
  },
  {
    "MUNID": "18017",
    "FORMAT_NAME": "Clarington (Municipality of)",
    "NAME": "Municipality of Clarington",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "Regional Municipality of Durham"
  },
  {
    "MUNID": "43030",
    "FORMAT_NAME": "Clearview (Township of)",
    "NAME": "Township of Clearview",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Simcoe"
  },
  {
    "MUNID": "54008",
    "FORMAT_NAME": "Cobalt (Town of)",
    "NAME": "Town of Cobalt",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "14021",
    "FORMAT_NAME": "Cobourg (Town of)",
    "NAME": "Town of Cobourg",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Northumberland"
  },
  {
    "MUNID": "56037",
    "FORMAT_NAME": "Cochrane (Town of)",
    "NAME": "Town of Cochrane",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "51034",
    "FORMAT_NAME": "Cockburn Island (Township of)",
    "NAME": "Township of Cockburn Island",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "54001",
    "FORMAT_NAME": "Coleman (Township of)",
    "NAME": "Township of Coleman",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "43031",
    "FORMAT_NAME": "Collingwood (Town of)",
    "NAME": "Town of Collingwood",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Simcoe"
  },
  {
    "MUNID": "58019",
    "FORMAT_NAME": "Conmee (Township of)",
    "NAME": "Township of Conmee",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "1012",
    "FORMAT_NAME": "Cornwall (City of)",
    "NAME": "City of Cornwall",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "14010",
    "FORMAT_NAME": "Cramahe (Township of)",
    "NAME": "Township of Cramahe",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Northumberland"
  },
  {
    "MUNID": "38005",
    "FORMAT_NAME": "Dawn-Euphemia (Township of)",
    "NAME": "Township of Dawn-Euphemia",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Lambton"
  },
  {
    "MUNID": "59040",
    "FORMAT_NAME": "Dawson (Township of)",
    "NAME": "Township of Dawson",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "47096",
    "FORMAT_NAME": "Deep River (Town of)",
    "NAME": "Town of Deep River",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Renfrew"
  },
  {
    "MUNID": "12002",
    "FORMAT_NAME": "Deseronto (Town of)",
    "NAME": "Town of Deseronto",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Hastings"
  },
  {
    "MUNID": "58034",
    "FORMAT_NAME": "Dorion (Township of)",
    "NAME": "Township of Dorion",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "15021",
    "FORMAT_NAME": "Douro-Dummer (Township of)",
    "NAME": "Township of Douro-Dummer",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Peterborough"
  },
  {
    "MUNID": "9007",
    "FORMAT_NAME": "Drummond/North Elmsley (Township of)",
    "NAME": "Township of Drummond/North Elmsley",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Lanark"
  },
  {
    "MUNID": "60025",
    "FORMAT_NAME": "Dryden (City of)",
    "NAME": "City of Dryden",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "57079",
    "FORMAT_NAME": "Dubreuilville (Township of)",
    "NAME": "Township of Dubreuilville",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "22000",
    "FORMAT_NAME": "Dufferin (County of)",
    "NAME": "County of Dufferin",
    "TYPE": "UT",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "18000",
    "FORMAT_NAME": "Durham (Regional Municipality of)",
    "NAME": "Regional Municipality of Durham",
    "TYPE": "UT",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "34028",
    "FORMAT_NAME": "Dutton/Dunwich (Municipality of)",
    "NAME": "Municipality of Dutton/Dunwich",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Elgin"
  },
  {
    "MUNID": "46024",
    "FORMAT_NAME": "Dysart, Dudley, Harcourt, Guilford, Harburn, Bruto (United Townships of)",
    "NAME": "United Townships of Dysart, Dudley, Harcourt, Guilford, Harburn, Bruto",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Haliburton"
  },
  {
    "MUNID": "60042",
    "FORMAT_NAME": "Ear Falls (Township of)",
    "NAME": "Township of Ear Falls",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "48034",
    "FORMAT_NAME": "East Ferris (Municipality of)",
    "NAME": "Municipality of East Ferris",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "22001",
    "FORMAT_NAME": "East Garafraxa (Township of)",
    "NAME": "Township of East Garafraxa",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Dufferin"
  },
  {
    "MUNID": "19054",
    "FORMAT_NAME": "East Gwillimbury (Town of)",
    "NAME": "Town of East Gwillimbury",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "Regional Municipality of York"
  },
  {
    "MUNID": "2001",
    "FORMAT_NAME": "East Hawkesbury (Township of)",
    "NAME": "Township of East Hawkesbury",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "United Counties of Prescott and Russell"
  },
  {
    "MUNID": "32038",
    "FORMAT_NAME": "East Zorra-Tavistock (Township of)",
    "NAME": "Township of East Zorra-Tavistock",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Oxford"
  },
  {
    "MUNID": "7003",
    "FORMAT_NAME": "Edwardsburgh/Cardinal (Township of)",
    "NAME": "Township of Edwardsburgh/Cardinal",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "United Counties of Leeds and Grenville"
  },
  {
    "MUNID": "34000",
    "FORMAT_NAME": "Elgin (County of)",
    "NAME": "County of Elgin",
    "TYPE": "UT",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "7011",
    "FORMAT_NAME": "Elizabethtown-Kitley (Township of)",
    "NAME": "Township of Elizabethtown-Kitley",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "United Counties of Leeds and Grenville"
  },
  {
    "MUNID": "57041",
    "FORMAT_NAME": "Elliot Lake (City of)",
    "NAME": "City of Elliot Lake",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "59019",
    "FORMAT_NAME": "Emo (Township of)",
    "NAME": "Township of Emo",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "54052",
    "FORMAT_NAME": "Englehart (Town of)",
    "NAME": "Town of Englehart",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "38016",
    "FORMAT_NAME": "Enniskillen (Township of)",
    "NAME": "Township of Enniskillen",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Lambton"
  },
  {
    "MUNID": "23015",
    "FORMAT_NAME": "Erin (Town of)",
    "NAME": "Town of Erin",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Wellington"
  },
  {
    "MUNID": "52026",
    "FORMAT_NAME": "Espanola (Town of)",
    "NAME": "Town of Espanola",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "43021",
    "FORMAT_NAME": "Essa (Township of)",
    "NAME": "Township of Essa",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Simcoe"
  },
  {
    "MUNID": "37000",
    "FORMAT_NAME": "Essex (County of)",
    "NAME": "County of Essex",
    "TYPE": "UT",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "37020",
    "FORMAT_NAME": "Essex (Town of)",
    "NAME": "Town of Essex",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Essex"
  },
  {
    "MUNID": "54049",
    "FORMAT_NAME": "Evanturel (Township of)",
    "NAME": "Township of Evanturel",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "12058",
    "FORMAT_NAME": "Faraday (Township of)",
    "NAME": "Township of Faraday",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Hastings"
  },
  {
    "MUNID": "56052",
    "FORMAT_NAME": "Fauquier-Strickland (Township of)",
    "NAME": "Township of Fauquier-Strickland",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "26003",
    "FORMAT_NAME": "Fort Erie (Town of)",
    "NAME": "Town of Fort Erie",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "Regional Municipality of Niagara"
  },
  {
    "MUNID": "59012",
    "FORMAT_NAME": "Fort Frances (Town of)",
    "NAME": "Town of Fort Frances",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "52001",
    "FORMAT_NAME": "French River (Municipality of)",
    "NAME": "Municipality of French River",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "7017",
    "FORMAT_NAME": "Front Of Yonge (Township of)",
    "NAME": "Township of Front of Yonge",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "United Counties of Leeds and Grenville"
  },
  {
    "MUNID": "10000",
    "FORMAT_NAME": "Frontenac (County of)",
    "NAME": "County of Frontenac",
    "TYPE": "UT",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "10002",
    "FORMAT_NAME": "Frontenac Islands (Township of)",
    "NAME": "Township of Frontenac Islands",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Frontenac"
  },
  {
    "MUNID": "7024",
    "FORMAT_NAME": "Gananoque (Town of)",
    "NAME": "Town of Gananoque",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "54066",
    "FORMAT_NAME": "Gauthier (Township of)",
    "NAME": "Township of Gauthier",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "44065",
    "FORMAT_NAME": "Georgian Bay (Township of)",
    "NAME": "Township of Georgian Bay",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "District Municipality of Muskoka"
  },
  {
    "MUNID": "42055",
    "FORMAT_NAME": "Georgian Bluffs (Township of)",
    "NAME": "Township of Georgian Bluffs",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Grey"
  },
  {
    "MUNID": "19070",
    "FORMAT_NAME": "Georgina (Town of)",
    "NAME": "Town of Georgina",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "Regional Municipality of York"
  },
  {
    "MUNID": "58012",
    "FORMAT_NAME": "Gillies (Township of)",
    "NAME": "Township of Gillies",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "40028",
    "FORMAT_NAME": "Goderich (Town of)",
    "NAME": "Town of Goderich",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Huron"
  },
  {
    "MUNID": "51025",
    "FORMAT_NAME": "Gordon/Barrie Island (Municipality of)",
    "NAME": "Municipality of Gordon / Barrie Island",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "51026",
    "FORMAT_NAME": "Gore Bay (Town of)",
    "NAME": "Town of Gore Bay",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "22005",
    "FORMAT_NAME": "Grand Valley (Town of)",
    "NAME": "Town of Grand Valley",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Dufferin"
  },
  {
    "MUNID": "44002",
    "FORMAT_NAME": "Gravenhurst (Town of)",
    "NAME": "Town of Gravenhurst",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "District Municipality of Muskoka"
  },
  {
    "MUNID": "47007",
    "FORMAT_NAME": "Greater Madawaska (Township of)",
    "NAME": "Township of Greater Madawaska",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Renfrew"
  },
  {
    "MUNID": "11010",
    "FORMAT_NAME": "Greater Napanee (Town of)",
    "NAME": "Town of Greater Napanee",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Lennox And Addington"
  },
  {
    "MUNID": "53005",
    "FORMAT_NAME": "Greater Sudbury (City of)",
    "NAME": "City of Greater Sudbury",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "58058",
    "FORMAT_NAME": "Greenstone (Municipality of)",
    "NAME": "Municipality of Greenstone",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "42000",
    "FORMAT_NAME": "Grey (County of)",
    "NAME": "County of Grey",
    "TYPE": "UT",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "42013",
    "FORMAT_NAME": "Grey Highlands (Municipality of)",
    "NAME": "Municipality of Grey Highlands",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Grey"
  },
  {
    "MUNID": "26065",
    "FORMAT_NAME": "Grimsby (Town of)",
    "NAME": "Town of Grimsby",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "Regional Municipality of Niagara"
  },
  {
    "MUNID": "23008",
    "FORMAT_NAME": "Guelph (City of)",
    "NAME": "City of Guelph",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "23005",
    "FORMAT_NAME": "Guelph/Eramosa (Township of)",
    "NAME": "Township of Guelph/Eramosa",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Wellington"
  },
  {
    "MUNID": "28005",
    "FORMAT_NAME": "Haldimand County",
    "NAME": "Haldimand County",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "46000",
    "FORMAT_NAME": "Haliburton (County of)",
    "NAME": "County of Haliburton",
    "TYPE": "UT",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "24000",
    "FORMAT_NAME": "Halton (Regional Municipality of)",
    "NAME": "Regional Municipality of Halton",
    "TYPE": "UT",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "24015",
    "FORMAT_NAME": "Halton Hills (Town of)",
    "NAME": "Town of Halton Hills",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "Regional Municipality of Halton"
  },
  {
    "MUNID": "25005",
    "FORMAT_NAME": "Hamilton (City of)",
    "NAME": "City of Hamilton",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "14019",
    "FORMAT_NAME": "Hamilton (Township of)",
    "NAME": "Township of Hamilton",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Northumberland"
  },
  {
    "MUNID": "42029",
    "FORMAT_NAME": "Hanover (Town of)",
    "NAME": "Town of Hanover",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Grey"
  },
  {
    "MUNID": "54026",
    "FORMAT_NAME": "Harley (Township of)",
    "NAME": "Township of Harley",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "54014",
    "FORMAT_NAME": "Harris (Township of)",
    "NAME": "Township of Harris",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "12000",
    "FORMAT_NAME": "Hastings (County of)",
    "NAME": "County of Hastings",
    "TYPE": "UT",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "12073",
    "FORMAT_NAME": "Hastings Highlands (Municipality of)",
    "NAME": "Municipality of Hastings Highlands",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Hastings"
  },
  {
    "MUNID": "15030",
    "FORMAT_NAME": "Havelock-Belmont-Methuen (Township of)",
    "NAME": "Township of Havelock-Belmont-Methuen",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Peterborough"
  },
  {
    "MUNID": "2008",
    "FORMAT_NAME": "Hawkesbury (Town of)",
    "NAME": "Town of Hawkesbury",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "United Counties of Prescott and Russell"
  },
  {
    "MUNID": "47098",
    "FORMAT_NAME": "Head, Clara And Maria (Township of)",
    "NAME": "Township of Head, Clara and Maria",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Renfrew"
  },
  {
    "MUNID": "56076",
    "FORMAT_NAME": "Hearst (Town of)",
    "NAME": "Town of Hearst",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "46002",
    "FORMAT_NAME": "Highlands East (Municipality of)",
    "NAME": "Municipality of Highlands East",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Haliburton"
  },
  {
    "MUNID": "54034",
    "FORMAT_NAME": "Hilliard (Township of)",
    "NAME": "Township of Hilliard",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "57004",
    "FORMAT_NAME": "Hilton (Township of)",
    "NAME": "Township of Hilton",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "57006",
    "FORMAT_NAME": "Hilton Beach (Village of)",
    "NAME": "Village of Hilton Beach",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "57096",
    "FORMAT_NAME": "Hornepayne (Township of)",
    "NAME": "Township of Hornepayne",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "47046",
    "FORMAT_NAME": "Horton (Township of)",
    "NAME": "Township of Horton",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Renfrew"
  },
  {
    "MUNID": "40046",
    "FORMAT_NAME": "Howick (Township of)",
    "NAME": "Township of Howick",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Huron"
  },
  {
    "MUNID": "54021",
    "FORMAT_NAME": "Hudson (Township of)",
    "NAME": "Township of Hudson",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "44042",
    "FORMAT_NAME": "Huntsville (Town of)",
    "NAME": "Town of Huntsville",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "District Municipality of Muskoka"
  },
  {
    "MUNID": "40000",
    "FORMAT_NAME": "Huron (County of)",
    "NAME": "County of Huron",
    "TYPE": "UT",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "40015",
    "FORMAT_NAME": "Huron East (Municipality of)",
    "NAME": "Municipality of Huron East",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Huron"
  },
  {
    "MUNID": "57023",
    "FORMAT_NAME": "Huron Shores (Municipality of)",
    "NAME": "Municipality of Huron Shores",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "41010",
    "FORMAT_NAME": "Huron-Kinloss (Township of)",
    "NAME": "Township of Huron-Kinloss",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Bruce"
  },
  {
    "MUNID": "60001",
    "FORMAT_NAME": "Ignace (Township of)",
    "NAME": "Township of Ignace",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "32018",
    "FORMAT_NAME": "Ingersoll (Town of)",
    "NAME": "Town of Ingersoll",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Oxford"
  },
  {
    "MUNID": "43017",
    "FORMAT_NAME": "Innisfil (Town of)",
    "NAME": "Town of Innisfil",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Simcoe"
  },
  {
    "MUNID": "56031",
    "FORMAT_NAME": "Iroquois Falls (Town of)",
    "NAME": "Town of Iroquois Falls",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "54042",
    "FORMAT_NAME": "James (Township of)",
    "NAME": "Township of James",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "57001",
    "FORMAT_NAME": "Jocelyn (Township of)",
    "NAME": "Township of Jocelyn",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "57016",
    "FORMAT_NAME": "Johnson (Township of)",
    "NAME": "Township of Johnson",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "49051",
    "FORMAT_NAME": "Joly (Township of)",
    "NAME": "Township of Joly",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "56066",
    "FORMAT_NAME": "Kapuskasing (Town of)",
    "NAME": "Town of Kapuskasing",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "16002",
    "FORMAT_NAME": "Kawartha Lakes (City of)",
    "NAME": "City of Kawartha Lakes",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "49018",
    "FORMAT_NAME": "Kearney (Town of)",
    "NAME": "Town of Kearney",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "60011",
    "FORMAT_NAME": "Kenora (City of)",
    "NAME": "City of Kenora",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "54024",
    "FORMAT_NAME": "Kerns (Township of)",
    "NAME": "Township of Kerns",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "47030",
    "FORMAT_NAME": "Killaloe, Hagarty And Richards (Township of)",
    "NAME": "Township of Killaloe, Hagarty and Richards",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Renfrew"
  },
  {
    "MUNID": "52003",
    "FORMAT_NAME": "Killarney (Municipality of)",
    "NAME": "Municipality of Killarney",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "41020",
    "FORMAT_NAME": "Kincardine (Municipality of)",
    "NAME": "Municipality of Kincardine",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Bruce"
  },
  {
    "MUNID": "19049",
    "FORMAT_NAME": "King (Township of)",
    "NAME": "Township of King",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "Regional Municipality of York"
  },
  {
    "MUNID": "10005",
    "FORMAT_NAME": "Kingston (City of)",
    "NAME": "City of Kingston",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "37008",
    "FORMAT_NAME": "Kingsville (Town of)",
    "NAME": "Town of Kingsville",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Essex"
  },
  {
    "MUNID": "54068",
    "FORMAT_NAME": "Kirkland Lake (Town of)",
    "NAME": "Town of Kirkland Lake",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "30012",
    "FORMAT_NAME": "Kitchener (City of)",
    "NAME": "City of Kitchener",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "Regional Municipality of Waterloo"
  },
  {
    "MUNID": "59016",
    "FORMAT_NAME": "La Vallee (Township of)",
    "NAME": "Township of La Vallee",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "57011",
    "FORMAT_NAME": "Laird (Township of)",
    "NAME": "Township of Laird",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "44027",
    "FORMAT_NAME": "Lake of Bays (Township of)",
    "NAME": "Township of Lake of Bays",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "District Municipality of Muskoka"
  },
  {
    "MUNID": "59045",
    "FORMAT_NAME": "Lake Of The Woods (Township of)",
    "NAME": "Township of Lake of The Woods",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "37049",
    "FORMAT_NAME": "Lakeshore (Municipality of)",
    "NAME": "Municipality of Lakeshore",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Essex"
  },
  {
    "MUNID": "38000",
    "FORMAT_NAME": "Lambton (County of)",
    "NAME": "County of Lambton",
    "TYPE": "UT",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "38037",
    "FORMAT_NAME": "Lambton Shores (Municipality of)",
    "NAME": "Municipality of Lambton Shores",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Lambton"
  },
  {
    "MUNID": "9000",
    "FORMAT_NAME": "Lanark (County of)",
    "NAME": "County of Lanark",
    "TYPE": "UT",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "9033",
    "FORMAT_NAME": "Lanark Highlands (Township of)",
    "NAME": "Township of Lanark Highlands",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Lanark"
  },
  {
    "MUNID": "54062",
    "FORMAT_NAME": "Larder Lake (Township of)",
    "NAME": "Township of Larder Lake",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "37034",
    "FORMAT_NAME": "Lasalle (Town of)",
    "NAME": "Town of Lasalle",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Essex"
  },
  {
    "MUNID": "54006",
    "FORMAT_NAME": "Latchford (Town of)",
    "NAME": "Town of Latchford",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "47091",
    "FORMAT_NAME": "Laurentian Hills (Town of)",
    "NAME": "Town of Laurentian Hills",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Renfrew"
  },
  {
    "MUNID": "47063",
    "FORMAT_NAME": "Laurentian Valley (Township of)",
    "NAME": "Township of Laurentian Valley",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Renfrew"
  },
  {
    "MUNID": "37003",
    "FORMAT_NAME": "Leamington (Municipality of)",
    "NAME": "Municipality of Leamington",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Essex"
  },
  {
    "MUNID": "7000",
    "FORMAT_NAME": "Leeds And Grenville (United Counties of)",
    "NAME": "United Counties of Leeds and Grenville",
    "TYPE": "UT",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "7019",
    "FORMAT_NAME": "Leeds And The Thousand Islands (Township of)",
    "NAME": "Township of Leeds and the Thousand Islands",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "United Counties of Leeds and Grenville"
  },
  {
    "MUNID": "11000",
    "FORMAT_NAME": "Lennox And Addington (County of)",
    "NAME": "County of Lennox and Addington",
    "TYPE": "UT",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "12051",
    "FORMAT_NAME": "Limerick (Township of)",
    "NAME": "Township of Limerick",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Hastings"
  },
  {
    "MUNID": "26057",
    "FORMAT_NAME": "Lincoln (Town of)",
    "NAME": "Town of Lincoln",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "Regional Municipality of Niagara"
  },
  {
    "MUNID": "39036",
    "FORMAT_NAME": "London (City of)",
    "NAME": "City of London",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "11002",
    "FORMAT_NAME": "Loyalist (Township of)",
    "NAME": "Township of Loyalist",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Lennox And Addington"
  },
  {
    "MUNID": "39057",
    "FORMAT_NAME": "Lucan Biddulph (Township of)",
    "NAME": "Township of Lucan Biddulph",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Middlesex"
  },
  {
    "MUNID": "57051",
    "FORMAT_NAME": "Macdonald, Meredith And Aberdeen Additional (Township of)",
    "NAME": "Township of Macdonald, Meredith and Aberdeen Additional",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "49054",
    "FORMAT_NAME": "Machar (Township of)",
    "NAME": "Township of Machar",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "60021",
    "FORMAT_NAME": "Machin (Township of)",
    "NAME": "Township of Machin",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "47023",
    "FORMAT_NAME": "Madawaska Valley (Township of)",
    "NAME": "Township of Madawaska Valley",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Renfrew"
  },
  {
    "MUNID": "12036",
    "FORMAT_NAME": "Madoc (Township of)",
    "NAME": "Township of Madoc",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Hastings"
  },
  {
    "MUNID": "49041",
    "FORMAT_NAME": "Magnetawan (Municipality of)",
    "NAME": "Municipality of Magnetawan",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "34007",
    "FORMAT_NAME": "Malahide (Township of)",
    "NAME": "Township of Malahide",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Elgin"
  },
  {
    "MUNID": "58066",
    "FORMAT_NAME": "Manitouwadge (Township of)",
    "NAME": "Township of Manitouwadge",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "23023",
    "FORMAT_NAME": "Mapleton (Township of)",
    "NAME": "Township of Mapleton",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Wellington"
  },
  {
    "MUNID": "58059",
    "FORMAT_NAME": "Marathon (Town of)",
    "NAME": "Town of Marathon",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "19036",
    "FORMAT_NAME": "Markham (City of)",
    "NAME": "City of Markham",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "Regional Municipality of York"
  },
  {
    "MUNID": "52007",
    "FORMAT_NAME": "Markstay-Warren (Municipality of)",
    "NAME": "Municipality of Markstay-Warren",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "12039",
    "FORMAT_NAME": "Marmora And Lake (Municipality of)",
    "NAME": "Municipality of Marmora and Lake",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Hastings"
  },
  {
    "MUNID": "54056",
    "FORMAT_NAME": "Matachewan (Township of)",
    "NAME": "Township of Matachewan",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "48021",
    "FORMAT_NAME": "Mattawa (Town of)",
    "NAME": "Town of Mattawa",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "48019",
    "FORMAT_NAME": "Mattawan (Township of)",
    "NAME": "Township of Mattawan",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "56077",
    "FORMAT_NAME": "Mattice-Val C?t? (Township of)",
    "NAME": "Township of Mattice-Val C?t?",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "49031",
    "FORMAT_NAME": "Mcdougall (Municipality of)",
    "NAME": "Municipality of Mcdougall",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "54058",
    "FORMAT_NAME": "Mcgarry (Township of)",
    "NAME": "Township of Mcgarry",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "49028",
    "FORMAT_NAME": "Mckellar (Township of)",
    "NAME": "Township of Mckellar",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "49012",
    "FORMAT_NAME": "Mcmurrich/Monteith (Township of)",
    "NAME": "Township of Mcmurrich/Monteith",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "47003",
    "FORMAT_NAME": "Mcnab/Braeside (Township of)",
    "NAME": "Township of Mcnab/Braeside",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Renfrew"
  },
  {
    "MUNID": "42047",
    "FORMAT_NAME": "Meaford (Municipality of)",
    "NAME": "Municipality of Meaford",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Grey"
  },
  {
    "MUNID": "22019",
    "FORMAT_NAME": "Melancthon (Township of)",
    "NAME": "Township of Melancthon",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Dufferin"
  },
  {
    "MUNID": "7050",
    "FORMAT_NAME": "Merrickville-Wolford (Village of)",
    "NAME": "Village of Merrickville-Wolford",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "United Counties of Leeds and Grenville"
  },
  {
    "MUNID": "39000",
    "FORMAT_NAME": "Middlesex (County of)",
    "NAME": "County of Middlesex",
    "TYPE": "UT",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "39020",
    "FORMAT_NAME": "Middlesex Centre (Municipality of)",
    "NAME": "Municipality of Middlesex Centre",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Middlesex"
  },
  {
    "MUNID": "43074",
    "FORMAT_NAME": "Midland (Town of)",
    "NAME": "Town of Midland",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Simcoe"
  },
  {
    "MUNID": "24009",
    "FORMAT_NAME": "Milton (Town of)",
    "NAME": "Town of Milton",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "Regional Municipality of Halton"
  },
  {
    "MUNID": "46011",
    "FORMAT_NAME": "Minden Hills (Township of)",
    "NAME": "Township of Minden Hills",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Haliburton"
  },
  {
    "MUNID": "23040",
    "FORMAT_NAME": "Minto (Town of)",
    "NAME": "Town of Minto",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Wellington"
  },
  {
    "MUNID": "21005",
    "FORMAT_NAME": "Mississauga (City of)",
    "NAME": "City of Mississauga",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "Regional Municipality of Peel"
  },
  {
    "MUNID": "9030",
    "FORMAT_NAME": "Mississippi Mills (Town of)",
    "NAME": "Town of Mississippi Mills",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Lanark"
  },
  {
    "MUNID": "22012",
    "FORMAT_NAME": "Mono (Town of)",
    "NAME": "Town of Mono",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Dufferin"
  },
  {
    "MUNID": "9001",
    "FORMAT_NAME": "Montague (Township of)",
    "NAME": "Township of Montague",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Lanark"
  },
  {
    "MUNID": "56056",
    "FORMAT_NAME": "Moonbeam (Township of)",
    "NAME": "Township of Moonbeam",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "56065",
    "FORMAT_NAME": "Moosonee (Town of)",
    "NAME": "Town of Moosonee",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "59031",
    "FORMAT_NAME": "Morley (Township of)",
    "NAME": "Township of Morley",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "40048",
    "FORMAT_NAME": "Morris-Turnberry (Municipality of)",
    "NAME": "Municipality of Morris-Turnberry",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Huron"
  },
  {
    "MUNID": "22016",
    "FORMAT_NAME": "Mulmur (Township of)",
    "NAME": "Township of Mulmur",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Dufferin"
  },
  {
    "MUNID": "44000",
    "FORMAT_NAME": "Muskoka (District Municipality of)",
    "NAME": "District Municipality of Muskoka",
    "TYPE": "UT",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "44053",
    "FORMAT_NAME": "Muskoka Lakes (Township of)",
    "NAME": "Township of Muskoka Lakes",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "District Municipality of Muskoka"
  },
  {
    "MUNID": "52031",
    "FORMAT_NAME": "Nairn And Hyman (Township of)",
    "NAME": "Township of Nairn and Hyman",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "58001",
    "FORMAT_NAME": "Neebing (Municipality of)",
    "NAME": "Municipality of Neebing",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "43025",
    "FORMAT_NAME": "New Tecumseth (Town of)",
    "NAME": "Town of New Tecumseth",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Simcoe"
  },
  {
    "MUNID": "39002",
    "FORMAT_NAME": "Newbury (Village of)",
    "NAME": "Village of Newbury",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Middlesex"
  },
  {
    "MUNID": "19048",
    "FORMAT_NAME": "Newmarket (Town of)",
    "NAME": "Town of Newmarket",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "Regional Municipality of York"
  },
  {
    "MUNID": "26000",
    "FORMAT_NAME": "Niagara (Regional Municipality of)",
    "NAME": "Regional Municipality of Niagara",
    "TYPE": "UT",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "26043",
    "FORMAT_NAME": "Niagara Falls (City of)",
    "NAME": "City of Niagara Falls",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "Regional Municipality of Niagara"
  },
  {
    "MUNID": "26047",
    "FORMAT_NAME": "Niagara-on-the-Lake (Town of)",
    "NAME": "Town of Niagara-On-The-Lake",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "Regional Municipality of Niagara"
  },
  {
    "MUNID": "58044",
    "FORMAT_NAME": "Nipigon (Township of)",
    "NAME": "Township of Nipigon",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "49071",
    "FORMAT_NAME": "Nipissing (Township of)",
    "NAME": "Township of Nipissing",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "28030",
    "FORMAT_NAME": "Norfolk County",
    "NAME": "Norfolk County",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "47068",
    "FORMAT_NAME": "North Algona Wilberforce (Township of)",
    "NAME": "Township of North Algona Wilberforce",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Renfrew"
  },
  {
    "MUNID": "48044",
    "FORMAT_NAME": "North Bay (City of)",
    "NAME": "City of North Bay",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "30001",
    "FORMAT_NAME": "North Dumfries (Township of)",
    "NAME": "Township of North Dumfries",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "Regional Municipality of Waterloo"
  },
  {
    "MUNID": "1026",
    "FORMAT_NAME": "North Dundas (Township of)",
    "NAME": "Township of North Dundas",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "United Counties of Stormont, Dundas and Glengarry"
  },
  {
    "MUNID": "10041",
    "FORMAT_NAME": "North Frontenac (Township of)",
    "NAME": "Township of North Frontenac",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Frontenac"
  },
  {
    "MUNID": "1047",
    "FORMAT_NAME": "North Glengarry (Township of)",
    "NAME": "Township of North Glengarry",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "United Counties of Stormont, Dundas and Glengarry"
  },
  {
    "MUNID": "7055",
    "FORMAT_NAME": "North Grenville (Municipality of)",
    "NAME": "Municipality of North Grenville",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "United Counties of Leeds and Grenville"
  },
  {
    "MUNID": "40050",
    "FORMAT_NAME": "North Huron (Township of)",
    "NAME": "Township of North Huron",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Huron"
  },
  {
    "MUNID": "15035",
    "FORMAT_NAME": "North Kawartha (Township of)",
    "NAME": "Township of North Kawartha",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Peterborough"
  },
  {
    "MUNID": "39041",
    "FORMAT_NAME": "North Middlesex (Municipality of)",
    "NAME": "Municipality of North Middlesex",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Middlesex"
  },
  {
    "MUNID": "31035",
    "FORMAT_NAME": "North Perth (Municipality of)",
    "NAME": "Municipality of North Perth",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Perth"
  },
  {
    "MUNID": "1037",
    "FORMAT_NAME": "North Stormont (Township of)",
    "NAME": "Township of North Stormont",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "United Counties of Stormont, Dundas and Glengarry"
  },
  {
    "MUNID": "51015",
    "FORMAT_NAME": "Northeastern Manitoulin And The Islands (Town of)",
    "NAME": "Town of Northeastern Manitoulin and the Islands",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "41061",
    "FORMAT_NAME": "Northern Bruce Peninsula (Municipality of)",
    "NAME": "Municipality of Northern Bruce Peninsula",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Bruce"
  },
  {
    "MUNID": "14000",
    "FORMAT_NAME": "Northumberland (County of)",
    "NAME": "County of Northumberland",
    "TYPE": "UT",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "32002",
    "FORMAT_NAME": "Norwich (Township of)",
    "NAME": "Township of Norwich",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Oxford"
  },
  {
    "MUNID": "24001",
    "FORMAT_NAME": "Oakville (Town of)",
    "NAME": "Town of Oakville",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "Regional Municipality of Halton"
  },
  {
    "MUNID": "58016",
    "FORMAT_NAME": "O'Connor (Township of)",
    "NAME": "Township of O'Connor",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "38018",
    "FORMAT_NAME": "Oil Springs (Village of)",
    "NAME": "Village of Oil Springs",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Lambton"
  },
  {
    "MUNID": "58007",
    "FORMAT_NAME": "Oliver Paipoonge (Municipality of)",
    "NAME": "Municipality of Oliver Paipoonge",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "56073",
    "FORMAT_NAME": "Opasatika (Township of)",
    "NAME": "Township of Opasatika",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "22014",
    "FORMAT_NAME": "Orangeville (Town of)",
    "NAME": "Town of Orangeville",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Dufferin"
  },
  {
    "MUNID": "43052",
    "FORMAT_NAME": "Orillia (City of)",
    "NAME": "City of Orillia",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "43047",
    "FORMAT_NAME": "Oro-Medonte (Township of)",
    "NAME": "Township of Oro-Medonte",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Simcoe"
  },
  {
    "MUNID": "18013",
    "FORMAT_NAME": "Oshawa (City of)",
    "NAME": "City of Oshawa",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "Regional Municipality of Durham"
  },
  {
    "MUNID": "15005",
    "FORMAT_NAME": "Otonabee-South Monaghan (Township of)",
    "NAME": "Township of Otonabee-South Monaghan",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Peterborough"
  },
  {
    "MUNID": "6005",
    "FORMAT_NAME": "Ottawa (City of)",
    "NAME": "City of Ottawa",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "42059",
    "FORMAT_NAME": "Owen Sound (City of)",
    "NAME": "City of Owen Sound",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Grey"
  },
  {
    "MUNID": "32000",
    "FORMAT_NAME": "Oxford (County of)",
    "NAME": "County of Oxford",
    "TYPE": "UT",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "48017",
    "FORMAT_NAME": "Papineau-Cameron (Township of)",
    "NAME": "Township of Papineau-Cameron",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "49032",
    "FORMAT_NAME": "Parry Sound (Town of)",
    "NAME": "Town of Parry Sound",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "21000",
    "FORMAT_NAME": "Peel (Regional Municipality of)",
    "NAME": "Regional Municipality of Peel",
    "TYPE": "UT",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "37001",
    "FORMAT_NAME": "Pelee (Township of)",
    "NAME": "Township of Pelee",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "26028",
    "FORMAT_NAME": "Pelham (Town of)",
    "NAME": "Town of Pelham",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "Regional Municipality of Niagara"
  },
  {
    "MUNID": "47064",
    "FORMAT_NAME": "Pembroke (City of)",
    "NAME": "City of Pembroke",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "43072",
    "FORMAT_NAME": "Penetanguishene (Town of)",
    "NAME": "Town of Penetanguishene",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Simcoe"
  },
  {
    "MUNID": "49014",
    "FORMAT_NAME": "Perry (Township of)",
    "NAME": "Township of Perry",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "31000",
    "FORMAT_NAME": "Perth (County of)",
    "NAME": "County of Perth",
    "TYPE": "UT",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "9021",
    "FORMAT_NAME": "Perth (Town of)",
    "NAME": "Town of Perth",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Lanark"
  },
  {
    "MUNID": "31002",
    "FORMAT_NAME": "Perth East (Township of)",
    "NAME": "Township of Perth East",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Perth"
  },
  {
    "MUNID": "31008",
    "FORMAT_NAME": "Perth South (Township of)",
    "NAME": "Township of Perth South",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Perth"
  },
  {
    "MUNID": "47077",
    "FORMAT_NAME": "Petawawa (Town of)",
    "NAME": "Town of Petawawa",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Renfrew"
  },
  {
    "MUNID": "15014",
    "FORMAT_NAME": "Peterborough (City of)",
    "NAME": "City of Peterborough",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "15000",
    "FORMAT_NAME": "Peterborough (County of)",
    "NAME": "County of Peterborough",
    "TYPE": "UT",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "38019",
    "FORMAT_NAME": "Petrolia (Town of)",
    "NAME": "Town of Petrolia",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Lambton"
  },
  {
    "MUNID": "18001",
    "FORMAT_NAME": "Pickering (City of)",
    "NAME": "City of Pickering",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "Regional Municipality of Durham"
  },
  {
    "MUNID": "60049",
    "FORMAT_NAME": "Pickle Lake (Township of)",
    "NAME": "Township of Pickle Lake",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "57019",
    "FORMAT_NAME": "Plummer Additional (Township of)",
    "NAME": "Township of Plummer Additional",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "38033",
    "FORMAT_NAME": "Plympton-Wyoming (Town of)",
    "NAME": "Town of Plympton-Wyoming",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Lambton"
  },
  {
    "MUNID": "38031",
    "FORMAT_NAME": "Point Edward (Village of)",
    "NAME": "Village of Point Edward",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Lambton"
  },
  {
    "MUNID": "26011",
    "FORMAT_NAME": "Port Colborne (City of)",
    "NAME": "City of Port Colborne",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "Regional Municipality of Niagara"
  },
  {
    "MUNID": "14022",
    "FORMAT_NAME": "Port Hope (Municipality of)",
    "NAME": "Municipality of Port Hope",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Northumberland"
  },
  {
    "MUNID": "49058",
    "FORMAT_NAME": "Powassan (Municipality of)",
    "NAME": "Municipality of Powassan",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "7008",
    "FORMAT_NAME": "Prescott (Town of)",
    "NAME": "Town of Prescott",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "2000",
    "FORMAT_NAME": "Prescott And Russell (United Counties of)",
    "NAME": "United Counties of Prescott and Russell",
    "TYPE": "UT",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "57066",
    "FORMAT_NAME": "Prince (Township of)",
    "NAME": "Township of Prince",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "13002",
    "FORMAT_NAME": "Prince Edward (County of)",
    "NAME": "County of Prince Edward",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "23001",
    "FORMAT_NAME": "Puslinch (Township of)",
    "NAME": "Township of Puslinch",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Wellington"
  },
  {
    "MUNID": "12010",
    "FORMAT_NAME": "Quinte West (City of)",
    "NAME": "City of Quinte West",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "59042",
    "FORMAT_NAME": "Rainy River (Town of)",
    "NAME": "Town of Rainy River",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "43050",
    "FORMAT_NAME": "Ramara (Township of)",
    "NAME": "Township of Ramara",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Simcoe"
  },
  {
    "MUNID": "60040",
    "FORMAT_NAME": "Red Lake (Municipality of)",
    "NAME": "Municipality of Red Lake",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "58041",
    "FORMAT_NAME": "Red Rock (Township of)",
    "NAME": "Township of Red Rock",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "47000",
    "FORMAT_NAME": "Renfrew (County of)",
    "NAME": "County of Renfrew",
    "TYPE": "UT",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "47048",
    "FORMAT_NAME": "Renfrew (Town of)",
    "NAME": "Town of Renfrew",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Renfrew"
  },
  {
    "MUNID": "19038",
    "FORMAT_NAME": "Richmond Hill (City of)",
    "NAME": "City of Richmond Hill",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "Regional Municipality of York"
  },
  {
    "MUNID": "7030",
    "FORMAT_NAME": "Rideau Lakes (Township of)",
    "NAME": "Township of Rideau Lakes",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "United Counties of Leeds and Grenville"
  },
  {
    "MUNID": "2048",
    "FORMAT_NAME": "Russell (Township of)",
    "NAME": "Township of Russell",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "United Counties of Prescott and Russell"
  },
  {
    "MUNID": "49024",
    "FORMAT_NAME": "Ryerson (Township of)",
    "NAME": "Township of Ryerson",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "52019",
    "FORMAT_NAME": "Sables-Spanish Rivers (Township of)",
    "NAME": "Township of Sables-Spanish Rivers",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "38030",
    "FORMAT_NAME": "Sarnia (City of)",
    "NAME": "City of Sarnia",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Lambton"
  },
  {
    "MUNID": "41043",
    "FORMAT_NAME": "Saugeen Shores (Town of)",
    "NAME": "Town of Saugeen Shores",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Bruce"
  },
  {
    "MUNID": "57061",
    "FORMAT_NAME": "Sault Ste. Marie (City of)",
    "NAME": "City of Sault Ste. Marie",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "58051",
    "FORMAT_NAME": "Schreiber (Township of)",
    "NAME": "Township of Schreiber",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "18020",
    "FORMAT_NAME": "Scugog (Township of)",
    "NAME": "Township of Scugog",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "Regional Municipality of Durham"
  },
  {
    "MUNID": "49003",
    "FORMAT_NAME": "Seguin (Township of)",
    "NAME": "Township of Seguin",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "15017",
    "FORMAT_NAME": "Selwyn (Township of)",
    "NAME": "Township of Selwyn",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Peterborough"
  },
  {
    "MUNID": "43080",
    "FORMAT_NAME": "Severn (Township of)",
    "NAME": "Township of Severn",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Simcoe"
  },
  {
    "MUNID": "22021",
    "FORMAT_NAME": "Shelburne (Town of)",
    "NAME": "Town of Shelburne",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Dufferin"
  },
  {
    "MUNID": "58028",
    "FORMAT_NAME": "Shuniah (Municipality of)",
    "NAME": "Municipality of Shuniah",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "43000",
    "FORMAT_NAME": "Simcoe (County of)",
    "NAME": "County of Simcoe",
    "TYPE": "UT",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "60034",
    "FORMAT_NAME": "Sioux Lookout (Municipality of)",
    "NAME": "Municipality of Sioux Lookout",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "60008",
    "FORMAT_NAME": "Sioux Narrows-Nestor Falls (Township of)",
    "NAME": "Township of Sioux Narrows-Nestor Falls",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "9004",
    "FORMAT_NAME": "Smiths Falls (Town of)",
    "NAME": "Town of Smiths Falls",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "56048",
    "FORMAT_NAME": "Smooth Rock Falls (Town of)",
    "NAME": "Town of Smooth Rock Falls",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "48001",
    "FORMAT_NAME": "South Algonquin (Township of)",
    "NAME": "Township of South Algonquin",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "41004",
    "FORMAT_NAME": "South Bruce (Municipality of)",
    "NAME": "Municipality of South Bruce",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Bruce"
  },
  {
    "MUNID": "41053",
    "FORMAT_NAME": "South Bruce Peninsula (Town of)",
    "NAME": "Town of South Bruce Peninsula",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Bruce"
  },
  {
    "MUNID": "1016",
    "FORMAT_NAME": "South Dundas (Municipality of)",
    "NAME": "Municipality of South Dundas",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "United Counties of Stormont, Dundas and Glengarry"
  },
  {
    "MUNID": "10013",
    "FORMAT_NAME": "South Frontenac (Township of)",
    "NAME": "Township of South Frontenac",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Frontenac"
  },
  {
    "MUNID": "1002",
    "FORMAT_NAME": "South Glengarry (Township of)",
    "NAME": "Township of South Glengarry",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "United Counties of Stormont, Dundas and Glengarry"
  },
  {
    "MUNID": "40003",
    "FORMAT_NAME": "South Huron (Municipality of)",
    "NAME": "Municipality of South Huron",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Huron"
  },
  {
    "MUNID": "49056",
    "FORMAT_NAME": "South River (Village of)",
    "NAME": "Village of South River",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "1009",
    "FORMAT_NAME": "South Stormont (Township of)",
    "NAME": "Township of South Stormont",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "United Counties of Stormont, Dundas and Glengarry"
  },
  {
    "MUNID": "42005",
    "FORMAT_NAME": "Southgate (Township of)",
    "NAME": "Township of Southgate",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Grey"
  },
  {
    "MUNID": "39003",
    "FORMAT_NAME": "Southwest Middlesex (Municipality of)",
    "NAME": "Municipality of Southwest Middlesex",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Middlesex"
  },
  {
    "MUNID": "32011",
    "FORMAT_NAME": "South-West Oxford (Township of)",
    "NAME": "Township of South-West Oxford",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Oxford"
  },
  {
    "MUNID": "34024",
    "FORMAT_NAME": "Southwold (Township of)",
    "NAME": "Township of Southwold",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Elgin"
  },
  {
    "MUNID": "57039",
    "FORMAT_NAME": "Spanish (Town of)",
    "NAME": "Town of Spanish",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "43040",
    "FORMAT_NAME": "Springwater (Township of)",
    "NAME": "Township of Springwater",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Simcoe"
  },
  {
    "MUNID": "26053",
    "FORMAT_NAME": "St. Catharines (City of)",
    "NAME": "City of St. Catharines",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "Regional Municipality of Niagara"
  },
  {
    "MUNID": "38002",
    "FORMAT_NAME": "St. Clair (Township of)",
    "NAME": "Township of St. Clair",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Lambton"
  },
  {
    "MUNID": "57008",
    "FORMAT_NAME": "St. Joseph (Township of)",
    "NAME": "Township of St. Joseph",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "31016",
    "FORMAT_NAME": "St. Marys (Town of)",
    "NAME": "Town of St. Marys",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "34021",
    "FORMAT_NAME": "St. Thomas (City of)",
    "NAME": "City of St. Thomas",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "52004",
    "FORMAT_NAME": "St.-Charles (Municipality of)",
    "NAME": "Municipality of St.-Charles",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "12017",
    "FORMAT_NAME": "Stirling-Rawdon (Township of)",
    "NAME": "Township of Stirling-Rawdon",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Hastings"
  },
  {
    "MUNID": "11023",
    "FORMAT_NAME": "Stone Mills (Township of)",
    "NAME": "Township of Stone Mills",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Lennox And Addington"
  },
  {
    "MUNID": "1000",
    "FORMAT_NAME": "Stormont, Dundas And Glengarry (United Counties of)",
    "NAME": "United Counties of Stormont, Dundas and Glengarry",
    "TYPE": "UT",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "31011",
    "FORMAT_NAME": "Stratford (City of)",
    "NAME": "City of Stratford",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "39013",
    "FORMAT_NAME": "Strathroy-Caradoc (Municipality of)",
    "NAME": "Municipality of Strathroy-Caradoc",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Middlesex"
  },
  {
    "MUNID": "49046",
    "FORMAT_NAME": "Strong (Township of)",
    "NAME": "Township of Strong",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "49048",
    "FORMAT_NAME": "Sundridge (Village of)",
    "NAME": "Village of Sundridge",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "57014",
    "FORMAT_NAME": "Tarbutt (Township of)",
    "NAME": "Township of Tarbutt",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "43070",
    "FORMAT_NAME": "Tay (Township of)",
    "NAME": "Township of Tay",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Simcoe"
  },
  {
    "MUNID": "9010",
    "FORMAT_NAME": "Tay Valley (Township of)",
    "NAME": "Township of Tay Valley",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Lanark"
  },
  {
    "MUNID": "37043",
    "FORMAT_NAME": "Tecumseh (Town of)",
    "NAME": "Town of Tecumseh",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Essex"
  },
  {
    "MUNID": "51001",
    "FORMAT_NAME": "Tehkummah (Township of)",
    "NAME": "Township of Tehkummah",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "48069",
    "FORMAT_NAME": "Temagami (Municipality of)",
    "NAME": "Municipality of Temagami",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "54010",
    "FORMAT_NAME": "Temiskaming Shores (City of)",
    "NAME": "City of Temiskaming Shores",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "58054",
    "FORMAT_NAME": "Terrace Bay (Township of)",
    "NAME": "Township of Terrace Bay",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "39025",
    "FORMAT_NAME": "Thames Centre (Municipality of)",
    "NAME": "Municipality of Thames Centre",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Middlesex"
  },
  {
    "MUNID": "49005",
    "FORMAT_NAME": "The Archipelago (Township of)",
    "NAME": "Township of The Archipelago",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "42041",
    "FORMAT_NAME": "The Blue Mountains (Town of)",
    "NAME": "Town of The Blue Mountains",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Grey"
  },
  {
    "MUNID": "2011",
    "FORMAT_NAME": "The Nation (Municipality of)",
    "NAME": "Municipality of The Nation",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "United Counties of Prescott and Russell"
  },
  {
    "MUNID": "57040",
    "FORMAT_NAME": "The North Shore (Township of)",
    "NAME": "Township of The North Shore",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "57028",
    "FORMAT_NAME": "Thessalon (Town of)",
    "NAME": "Town of Thessalon",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "54038",
    "FORMAT_NAME": "Thornloe (Village of)",
    "NAME": "Village of Thornloe",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "26037",
    "FORMAT_NAME": "Thorold (City of)",
    "NAME": "City of Thorold",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "Regional Municipality of Niagara"
  },
  {
    "MUNID": "58004",
    "FORMAT_NAME": "Thunder Bay (City of)",
    "NAME": "City of Thunder Bay",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "32004",
    "FORMAT_NAME": "Tillsonburg (Town of)",
    "NAME": "Town of Tillsonburg",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Oxford"
  },
  {
    "MUNID": "56027",
    "FORMAT_NAME": "Timmins (City of)",
    "NAME": "City of Timmins",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "43068",
    "FORMAT_NAME": "Tiny (Township of)",
    "NAME": "Township of Tiny",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Simcoe"
  },
  {
    "MUNID": "20002",
    "FORMAT_NAME": "Toronto (City of)",
    "NAME": "City of Toronto",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "14028",
    "FORMAT_NAME": "Trent Hills (Municipality of)",
    "NAME": "Municipality of Trent Hills",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Northumberland"
  },
  {
    "MUNID": "15041",
    "FORMAT_NAME": "Trent Lakes (Municipality of)",
    "NAME": "Municipality of Trent Lakes",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Peterborough"
  },
  {
    "MUNID": "12048",
    "FORMAT_NAME": "Tudor And Cashel (Township of)",
    "NAME": "Township of Tudor and Cashel",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Hastings"
  },
  {
    "MUNID": "12027",
    "FORMAT_NAME": "Tweed (Municipality of)",
    "NAME": "Municipality of Tweed",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Hastings"
  },
  {
    "MUNID": "12001",
    "FORMAT_NAME": "Tyendinaga (Township of)",
    "NAME": "Township of Tyendinaga",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Hastings"
  },
  {
    "MUNID": "18029",
    "FORMAT_NAME": "Uxbridge (Township of)",
    "NAME": "Township of Uxbridge",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "Regional Municipality of Durham"
  },
  {
    "MUNID": "56070",
    "FORMAT_NAME": "Val Rita-Harty (Township of)",
    "NAME": "Township of Val Rita-Harty",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "19028",
    "FORMAT_NAME": "Vaughan (City of)",
    "NAME": "City of Vaughan",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "Regional Municipality of York"
  },
  {
    "MUNID": "26014",
    "FORMAT_NAME": "Wainfleet (Township of)",
    "NAME": "Township of Wainfleet",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "Regional Municipality of Niagara"
  },
  {
    "MUNID": "38040",
    "FORMAT_NAME": "Warwick (Township of)",
    "NAME": "Township of Warwick",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Lambton"
  },
  {
    "MUNID": "43064",
    "FORMAT_NAME": "Wasaga Beach (Town of)",
    "NAME": "Town of Wasaga Beach",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Simcoe"
  },
  {
    "MUNID": "30016",
    "FORMAT_NAME": "Waterloo (City of)",
    "NAME": "City of Waterloo",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "Regional Municipality of Waterloo"
  },
  {
    "MUNID": "30000",
    "FORMAT_NAME": "Waterloo (Regional Municipality of)",
    "NAME": "Regional Municipality of Waterloo",
    "TYPE": "UT",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "57076",
    "FORMAT_NAME": "Wawa (Municipality of)",
    "NAME": "Municipality of Wawa",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "26032",
    "FORMAT_NAME": "Welland (City of)",
    "NAME": "City of Welland",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "Regional Municipality of Niagara"
  },
  {
    "MUNID": "30024",
    "FORMAT_NAME": "Wellesley (Township of)",
    "NAME": "Township of Wellesley",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "Regional Municipality of Waterloo"
  },
  {
    "MUNID": "23000",
    "FORMAT_NAME": "Wellington (County of)",
    "NAME": "County of Wellington",
    "TYPE": "UT",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "23020",
    "FORMAT_NAME": "Wellington North (Township of)",
    "NAME": "Township of Wellington North",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Wellington"
  },
  {
    "MUNID": "34035",
    "FORMAT_NAME": "West Elgin (Municipality of)",
    "NAME": "Municipality of West Elgin",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Elgin"
  },
  {
    "MUNID": "42004",
    "FORMAT_NAME": "West Grey (Municipality of)",
    "NAME": "Municipality of West Grey",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Grey"
  },
  {
    "MUNID": "26021",
    "FORMAT_NAME": "West Lincoln (Township of)",
    "NAME": "Township of West Lincoln",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "Regional Municipality of Niagara"
  },
  {
    "MUNID": "48050",
    "FORMAT_NAME": "West Nipissing (Municipality of)",
    "NAME": "Municipality of West Nipissing",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "31017",
    "FORMAT_NAME": "West Perth (Municipality of)",
    "NAME": "Municipality of West Perth",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Perth"
  },
  {
    "MUNID": "7033",
    "FORMAT_NAME": "Westport (Village of)",
    "NAME": "Village of Westport",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "United Counties of Leeds and Grenville"
  },
  {
    "MUNID": "18009",
    "FORMAT_NAME": "Whitby (Town of)",
    "NAME": "Town of Whitby",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "Regional Municipality of Durham"
  },
  {
    "MUNID": "19044",
    "FORMAT_NAME": "Whitchurch-Stouffville (Town of)",
    "NAME": "Town of Whitchurch-Stouffville",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "Regional Municipality of York"
  },
  {
    "MUNID": "57091",
    "FORMAT_NAME": "White River (Township of)",
    "NAME": "Township of White River",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "49039",
    "FORMAT_NAME": "Whitestone (Municipality of)",
    "NAME": "Municipality of Whitestone",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "47050",
    "FORMAT_NAME": "Whitewater Region (Township of)",
    "NAME": "Township of Whitewater Region",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Renfrew"
  },
  {
    "MUNID": "30018",
    "FORMAT_NAME": "Wilmot (Township of)",
    "NAME": "Township of Wilmot",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "Regional Municipality of Waterloo"
  },
  {
    "MUNID": "37039",
    "FORMAT_NAME": "Windsor (City of)",
    "NAME": "City of Windsor",
    "TYPE": "ST",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "12054",
    "FORMAT_NAME": "Wollaston (Township of)",
    "NAME": "Township of Wollaston",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Hastings"
  },
  {
    "MUNID": "32042",
    "FORMAT_NAME": "Woodstock (City of)",
    "NAME": "City of Woodstock",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Oxford"
  },
  {
    "MUNID": "30029",
    "FORMAT_NAME": "Woolwich (Township of)",
    "NAME": "Township of Woolwich",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "Regional Municipality of Waterloo"
  },
  {
    "MUNID": "19000",
    "FORMAT_NAME": "York (Regional Municipality of)",
    "NAME": "Regional Municipality of York",
    "TYPE": "UT",
    "UPPER_TIER_NAME": null
  },
  {
    "MUNID": "32027",
    "FORMAT_NAME": "Zorra (Township of)",
    "NAME": "Township of Zorra",
    "TYPE": "LT",
    "UPPER_TIER_NAME": "County of Oxford"
  }
]

const municipalityCategories = [
  {category: 'Lower Tier', code: 'LT'},
  {category: 'Single Tier', code: 'ST'},
  {category: 'Upper Tier', code: 'UT'}
]

export const municipalityNames = municipalityNamesJson.map(element => {
  return {
    MUNID: Number(element['MUNID']),
    formatName: element['FORMAT_NAME'],
    name: element['NAME'],
    type: element['TYPE'],
    upperTierName: element['UPPER_TIER_NAME'] === null ? '' : element['UPPER_TIER_NAME']
  }
});