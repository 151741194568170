// React
import React, { useState } from 'react';
// Firebase
import { auth } from '../../services/firebase.config';
import { signInWithEmailAndPassword } from 'firebase/auth';
// Components
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import EmailIcon from '../Icons/EmailIcon';
import LockIcon from '../Icons/LockIcon';
import DoorCloseIcon from '../Icons/DoorCloseIcon';
import DashboardAlert from '../Alert/DashboardAlert';

/**
 * A modal containing a title and message.
 * @component
 * @param {Object} props - The component accepts props.
 * @param {boolean} props.show - React Bootstrap show prop.
 * @param {function(Event): void} props.handleClose - React Bootstrap callback to handle modal close.
 * @param {string} props.title - A title for the modal.
 * @param {string} props.message - A message for the modal.
 * @returns Modal component with title and message.
 */
const GeneralModal = ({ show, handleClose, title, message}) => {
  
  return (
    <Modal 
      show={show} 
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton className="bg-primary-subtle bg-gradient">
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {message}
      </Modal.Body>
      <Modal.Footer className="bg-primary-subtle bg-gradient">
        <Button variant="outline-secondary" className="fw-bold icon-link icon-link-hover" onClick={handleClose}>
          <DoorCloseIcon width={16}/>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

/**
 * The form to sign in a user to firebase authentication.
 * @component
 * @param {Object} props - The component accepts props.
 * @param {React.JSX.Element} props.children - Child element.
 * @returns Sign in form component. 
 */
const SignInForm = ({children}) => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [signInError, setSignInError] = useState('');
  const [loading, setLoading] = useState(false);

  const [show, setShow] = useState(false);
  const [modalText, setModalText] = useState({
    title: '',
    message: ''
  });

  const handleSignIn = async (e) => {
    e.preventDefault();
    setSignInError('');
    setLoading(true);
    
    try {

      // beforeSignedIn blocking function checks for trial account before sign in

      const response = await signInWithEmailAndPassword(auth, email, password);

    } catch (error) {
      
      const code = error.code;
      const message = error.message;

      console.log(error);
      console.log(message);

      if (message.includes('Trial expired')) {
        setSignInError('Your trial period has expired.');
        setShow(true);
        setModalText({
          title: 'Trial Expired',
          message: (
            <div>
              <p>Want to continue your access to Hemson Analytics? Contact <a href="https://www.hemson.com/contact-us/" target="_blank" rel="noopener noreferrer">Hemson</a> today.</p>
              <p>We offer flexible pricing options for municipalities, businesses and individuals looking to access the platform.</p>
            </div>
          )
        });
      
      } else if (message.includes('auth/user-not-found')) {
        setSignInError('Email not found.');

      } else if (message.includes('auth/wrong-password')) {
        setSignInError('Incorrect password, try again.');

      } else {
        setSignInError(`Sign In Error: ${code}\n`);
    
      }
    
    } finally {
      
      setLoading(false);
    
    }
  };

  return (
    <>
      <Form onSubmit={handleSignIn}>
        <InputGroup className="mb-4">
          <InputGroup.Text id="email-icon">
            <EmailIcon width={16}/>
          </InputGroup.Text>
          <Form.Control
            required
            disabled={loading}
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </InputGroup>
        <InputGroup className="mb-2">
          <InputGroup.Text id="password-icon">
            <LockIcon width={16}/>
          </InputGroup.Text>
          <Form.Control
            required
            disabled={loading}
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </InputGroup>
        <Form.Group className='mb-4 text-start'>
          {children}
        </Form.Group>
        <div className="mb-3 d-grid">
          <Button variant="primary" className='fw-bold icon-link icon-link-hover' type="submit" disabled={loading}>
            {loading ? 
              <Spinner size="sm" className='ms-auto'/>
              :
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-in-right ms-auto" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0z"/>
                <path fillRule="evenodd" d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"/>
              </svg>
            }
            <span className='me-auto'>Sign In</span>
          </Button>
        </div>
        <div className="mb-3">
          <DashboardAlert
            variant={signInError === 'Your trial period has expired.' ? 'warning' : 'danger'}
            text={signInError}
            display={signInError !== '' ? true : false}
          />
        </div>
      </Form>
      <GeneralModal
        show={show}
        handleClose={() => setShow(false)}
        title={modalText.title}
        message={modalText.message}
      />
    </>
  );
}

export default SignInForm;