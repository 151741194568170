// Components
import Container from 'react-bootstrap/Container';
import HemsonAnalyticsLogo from '../Icons/HemsonAnalyticsLogo';
import LoadingDots from '../Icons/LoadingDots';

/**
 * Load screen component with spinning Hemson Analytics logo.
 * @components
 * @returns Load screen component with spinning Hemson Analytics logo. 
 */
const LoadScreen = () => {
  return (
    <Container fluid className='position-relative bg-primary-subtle' style={{height: '100svh'}}>
      <div className="position-absolute top-50 start-50 translate-middle">
        <HemsonAnalyticsLogo
          width={150}
        />
        <p className='text-center h5 mt-2'>Loading<LoadingDots/></p>
      </div>
    </Container>
  )
}

export default LoadScreen;