// Components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import GeneralDashboardCard from './GeneralDashboardCard';
import Placeholder from 'react-bootstrap/Placeholder';

/**
 * A general dashboard demographic display card.
 * @component
 * @param {Object} props - The component accepts props. 
 * @param {string} props.footnoteText - A footnote for the box.
 * @param {React.JSX.Element} props.icon - A bootstrap icon component.
 * @param {boolean} props.dashboardLoading - Dashboard loading spinner state.
 * @param {string} [props.boxLabel] - The name of the metric [default='No Name'].
 * @param {(number|string)} [props.boxData] - The value of the metric [default='No Value'].
 * @returns A metric card component for the dashboard.
 */ 
const SingleMetricCard = ({
  footnoteText, 
  icon,
  dashboardLoading,
  boxLabel = 'No Name',
  boxData = 'No Value'
}) => {
  return (
    <GeneralDashboardCard className={'h-100'}>
      <Row>
        <Col xs={3}>
          {icon}
        </Col>
        <Col xs={9} className='text-end'>                  
          {dashboardLoading ? 
            <>
              <Placeholder as="h4" animation="wave">
                <Placeholder xs={5} />
              </Placeholder>
              <Placeholder as="p" animation="wave" className='fs-5 mb-0'>
                <Placeholder xs={5} />
              </Placeholder>
              <Placeholder as="p" animation="wave" className='mb-0'>
                <Placeholder xs={3} />
              </Placeholder>
            </>  
            : 
            <>
              <p className="h4">{boxLabel}</p>
              <p className="fs-5 mb-0">{boxData}</p>
              <p className="mb-0"><small className="text-muted">{footnoteText}</small></p>
            </>
          }
        </Col>
      </Row>
    </GeneralDashboardCard>
  );
}

export default SingleMetricCard;