// React
import React from 'react';
// Components
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useTooltipsContext } from '../Context/TooltipsContext';
// Helpers
import { 
  dashboardObject, 
  firFirstYear, 
  firLastYear 
} from '../../configuration/dashboardNames.js';

/**
 * The year selection input.
 * @components
 * @param {Object} props - The component accepts props.
 * @param {string} props.dashboard - Dashboard selection value.
 * @param {number[]} props.years - Array of start and end years.
 * @param {function(Event): void} props.handleStartYearChange - Callback to handle start year change.
 * @param {function(Event): void} props.handleEndYearChange - Callback to handle end year change. 
 * @param {boolean} props.dashboardLoading - Dashboard loading spinner state.
 * @param {boolean} [props.hide] - Indicates whether to hide or show the year filter [default=false].
 * @returns Year selection component.  
 */
const YearSelect = ({
    dashboard,  
    years,
    handleStartYearChange,
    handleEndYearChange,
    dashboardLoading,
    hide = false
  }) => {

    const { tooltips } = useTooltipsContext();

    const renderYearTooltip = (props) => (
      <Tooltip id="renderYearTooltip" {...props}>
        <div className='text-start'>
          <p className='mb-0'>✔ 
            {twoYear ? 
              'Select a start and end year to generate reports. The end year is used for charts that capture a single year' : 
              'Select a year to generate a report.'
            }
          </p>
        </div>
      </Tooltip>
    );

    const yearsArray = Array.from({ length: firLastYear - firFirstYear + 1 }, (_, index) => firFirstYear + index);
    
    const twoYear = dashboardObject[dashboard].type === 'multi' ? false : true;

    return (
      <Row className={`mx-0 ${hide ? 'd-none' : ''}`}>
       
       { twoYear ?  
        <Col className='ps-0 pe-5'>
          <Form.Group className="mb-4">
            <Form.Label className='fw-semibold icon-link'>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-calendar4" viewBox="0 0 16 16">
                <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M2 2a1 1 0 0 0-1 1v1h14V3a1 1 0 0 0-1-1zm13 3H1v9a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1z"/>
              </svg>
              Start Year
            </Form.Label>
            <Form.Select
              disabled={dashboardLoading}
              aria-label="Start year"
              onChange={(event) => handleStartYearChange(event.target.value)}
              value={years[0]}
            >
              {yearsArray.map(year => {                   
                    return <option key={year} value={year}>{year}</option>;
                  }
                )}
            </Form.Select>
          </Form.Group>                             
        </Col>: ''}
        

        <Col className='px-0'>
          <Form.Group className="mb-4">
            <Form.Label className='fw-semibold icon-link'>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-calendar4" viewBox="0 0 16 16">
                <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M2 2a1 1 0 0 0-1 1v1h14V3a1 1 0 0 0-1-1zm13 3H1v9a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1z"/>
              </svg>
              {twoYear ? 'End Year' : 'Year'}
            </Form.Label>
            <OverlayTrigger
              trigger={tooltips ? ['focus', 'hover'] : ''}
              placement="auto-end"
              delay={{ show: 250, hide: 400 }}
              overlay={renderYearTooltip}
            >
              <Form.Select
                disabled={dashboardLoading}
                aria-label="End year"
                onChange={(event) => handleEndYearChange(event.target.value)}
                value={years[1]}
              >
                {yearsArray.map(year => {                   
                      return <option key={year} value={year}>{year}</option>;
                    }
                  )}
              </Form.Select>
            </OverlayTrigger>
          </Form.Group>
        </Col>
      
      </Row>     
    );
  }
  
export default YearSelect;