// React
import React, { useState } from 'react';
// Components
import Form from 'react-bootstrap/Form';
// Helpers
import { setStorage } from '../Dashboards/DashboardHelpers';

/**
 * A switch button template component.
 * @component
 * @param {Object} props - The component accepts props.
 * @param {string} props.offLabel - The label for the 'off' state.
 * @param {string} props.onLabel - The label for the 'on' state.
 * @param {boolean} props.defaultChecked - The default position of the switch.
 * @param {string} props.keyName - The name of the key the state is saved under in local storage. 
 * @param {function} [props.onChange] - Callback function called when the switch state changes [default=undefined].
 * @param {boolean} [props.disabled] - Disable the button [default=false].
 * @returns A switch button component.
 */
const SwitchButtonTemplate = ({
  offLabel,
  onLabel,
  defaultChecked,
  keyName,
  onChange,
  disabled = false
}) =>  {
  
  const [label, setLabel] = useState(defaultChecked ? onLabel : offLabel); // set initial value based on local storage
   
  const handleChange = (e) => {
    let checked = e.currentTarget.checked;
    if (checked) {
      // check on
      setLabel(onLabel);
      setStorage(keyName, true);
    } else {
      // check off
      setLabel(offLabel);
      setStorage(keyName, false);
    }

    // A callback function when used as a component
    if (onChange) {
      // check a callback is provided to the onChange prop
      // This is a prop that can hold a callback function
      // The parameter must be the same in the component where this component
      // is being used (in this case "e").
      onChange(e);
    }
  };

  return (
    <>
      <Form.Check
        reverse
        type="switch"
        label={label}
        onChange={handleChange}
        defaultChecked={defaultChecked} // Set the defaultChecked based on higher level state
        disabled={disabled}
      />
    </>
  );
}

export default SwitchButtonTemplate;