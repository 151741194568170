// Styles
import 'bootstrap/dist/css/bootstrap.css';
// Components
import DashboardTemplate from '../components/Navbars/DashboardTemplate';

/**
 * The dashboard page at route '/'.
 * @component
 * @param {Object} props - The component accepts props.
 * @param {Object} props.user - User object.
 * @param {boolean} props.admin - Admin state.
 * @returns The dashboard page component.
 */
const DashboardPage = ({user, admin}) => {
  return (
    <DashboardTemplate
      user={user}
      admin={admin}
    />
  )
}

export default DashboardPage;