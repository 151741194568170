// React
import { useState, useEffect, useRef } from 'react';
// Components
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useTooltipsContext } from '../Context/TooltipsContext';
// Helpers
import { deepArrayComparison, deepObjectComparison } from '../Dashboards/DashboardHelpers';
// Styles
import styles from './Buttons.module.css'

/**
 * Generate button component.
 * @component
 * @param {Object} props - The component accepts props.
 * @param {string} props.dashboard - Dashboard selection value.
 * @param {boolean} props.constant - Inflation selection value.
 * @param {Objects[]} props.municipality - Municipality selection value.
 * @param {Objects[]} props.municipalities - Municipalities selection value.
 * @param {number[]} props.years - Array of start and end years.
 * @param {object} props.filter - A custom filter object.
 * @param {boolean} props.dashboardLoading - Dashboard loading spinner state.
 * @param {function(boolean): void} props.handleGenerate - Callback to handle the data fetch state.
 * @returns Dashboard generate button.
 */
const GenerateButton = ({
  dashboard,
  constant,
  municipality,
  municipalities,
  years,
  filter,
  dashboardLoading,
  handleGenerate
}) => {

  const prevPropsRef = useRef();   
  const [refresh, setRefresh] = useState(false);

  const { tooltips } = useTooltipsContext();

  const GenerateButtonTooltip = (props) => (
    <Tooltip id="generateButtonTooltip" {...props}>
      <div className='text-start'>
        <p className='mb-0'>✔ The Generate button loads the data. Occasionally you will need to refresh the data to get the latest selection.</p>
      </div>
    </Tooltip>
  );

  // Check if the selection has changed
  useEffect(() => {
    // Get reference to previous prop values
    const prevProps = prevPropsRef.current;
    
    if (!prevProps) {
      // Initial render actions no refresh needed
      setRefresh(false);
    } else {
      // Check which prop triggered the useEffect

      if (prevProps.dashboard === dashboard) {
        setRefresh(false);
      }

      if (
        prevProps.constant === constant &&
        deepArrayComparison(prevProps.municipality, municipality) &&
        deepArrayComparison(prevProps.municipalities, municipalities) && 
        deepArrayComparison(prevProps.years, years) &&
        deepObjectComparison(prevProps.filter, filter) 
      ) {
        setRefresh(false);
      } else {
        setRefresh(true);
      }
    }

    // Save the states
    prevPropsRef.current = { dashboard, constant, municipality, municipalities, years, filter };
  }, [dashboard, constant, municipality, municipalities, years, filter]);

  const MessageComponent = () => {
    if (dashboardLoading) {
      return <span className="me-auto">Loading...</span>
    }

    if (refresh) {
      return <span className="me-auto">Refresh Data</span>
    }

    return <span className="me-auto">Generate</span>
  }

  return (
    <OverlayTrigger
      trigger={tooltips ? ['focus', 'hover'] : ''}
      placement="auto-end"
      delay={{ show: 250, hide: 400 }}
      overlay={GenerateButtonTooltip}
    >
      <div>
        <Button
          onClick={() => {
            handleGenerate(true);
            setRefresh(false);
          }}
          variant="outline-primary"
          className={`fw-bold icon-link icon-link-hover ${refresh ? styles.pulse : ''}`}
          disabled={dashboardLoading}
        >
          {dashboardLoading ?
            <Spinner size="sm" className='ms-auto'/>
            :
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-graph-up ms-auto" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M0 0h1v15h15v1H0zm14.817 3.113a.5.5 0 0 1 .07.704l-4.5 5.5a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61 4.15-5.073a.5.5 0 0 1 .704-.07"/>
            </svg>
          }
          <MessageComponent/>
        </Button>
      </div>
    </OverlayTrigger>
  );
}

export default GenerateButton;