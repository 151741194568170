// Components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

/**
 * Five chart dashboard layout. This layout contains five stacked rows for five charts at full dashboard width.
 * @component
 * @param {Object} props - The component accepts props.
 * @param {component} props.chart1 - A chart component.
 * @param {component} props.chart2 - A chart component.
 * @param {component} props.chart3 - A chart component.
 * @param {component} props.chart4 - A chart component.
 * @param {component} props.chart5 - A chart component.
 * @param {React.JSX.Element} props.chartFootnote1 - A chart footnote component for chart1.
 * @param {React.JSX.Element} props.chartFootnote2 - A chart footnote component for chart2.
 * @param {React.JSX.Element} props.chartFootnote3 - A chart footnote component for chart3.
 * @param {React.JSX.Element} props.chartFootnote4 - A chart footnote component for chart4.
 * @param {React.JSX.Element} props.chartFootnote5 - A chart footnote component for chart5.
 * @returns A layout for a comparator dashboard.     
 */
const FiveChartStackedLayout = ({
  chart1,
  chart2,
  chart3,
  chart4,
  chart5,
  chartFootnote1,
  chartFootnote2,
  chartFootnote3,
  chartFootnote4,
  chartFootnote5,
}) => {
  return (
    <>     
      
      {/* 1 chart */}
      <Row className="pt-3 g-3">
        <Col>
          {chart1}
          {chartFootnote1}
        </Col>    
      </Row>

      {/* 1 chart */}
      <Row className="pt-3 g-3">
        <Col>
          {chart2}
          {chartFootnote2}
        </Col>      
      </Row>

      {/* 1 chart */}
      <Row className="pt-3 g-3">
        <Col>
          {chart3}
          {chartFootnote3}
        </Col>      
      </Row>

      {/* 1 chart */}
      <Row className="pt-3 g-3">
        <Col>
          {chart4}
          {chartFootnote4}
        </Col>      
      </Row>

      {/* 1 chart */}
      <Row className="pt-3 g-3">
        <Col>
          {chart5}
          {chartFootnote5}
        </Col>      
      </Row>

    </>
  );
}

export default FiveChartStackedLayout;