// Components
import Spinner from 'react-bootstrap/Spinner';
// Styles
import styles from './Charts.module.css'

/**
 * A chart spinner placeholder.
 * @component
 * @returns A spinner div for a chart box.
 */
const LoadingChart = () => {
 
  return (
    <div className={styles.chartBoxHeight}>
      <div className='d-flex justify-content-center align-items-center h-100 w-100'>
        <Spinner animation="border" variant="primary" />
      </div> 
    </div>
  );
}

export default LoadingChart;