import React, { createContext, useContext, useState } from 'react';

// Step 1: Create Context.js file like here.
// Step 2: Wrap the <App/> component in index.js with the <ContextProvider/> component.
// Step 3: Add state logic to component that determines and sets the context state. Import useMunicipalityContext and extract as const { setSelectedMunicipality } = useMunicipalityContext();
// Step 4: Import the context in components that need it. Import useMunicipalityContext and extract as const { selectedMunicipality } = useMunicipalityContext();
// Note make sure that all components that set and use the context are inside the <ContextProvider/> at all times across all routes or errors will occur.

// Create a context to hold the selected municipality value
const MunicipalityContext = createContext();

export const useMunicipalityContext = () => useContext(MunicipalityContext);

// MunicipalityContextProvider will hold the state and provide it to components
export const MunicipalityContextProvider = ({ children }) => {
  const [selectedMunicipality, setSelectedMunicipality] = useState([]);

  const handleMunicipalityChange = (value) => {
    setSelectedMunicipality(value);
  };

  return (
    <MunicipalityContext.Provider value={{ selectedMunicipality, handleMunicipalityChange }}>
      {children}
    </MunicipalityContext.Provider>
  );
}
