// React
import { useRef } from 'react';
// Chart JS
import { 
  Chart as ChartJS, 
  ArcElement, 
  Tooltip, 
  Legend 
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
// Styles
import styles from './Charts.module.css'
// Components
import Button from 'react-bootstrap/Button';
import LoadingChart from './LoadingChart';
import NoDataChart from './NoDataChart';
import DownloadIcon from '../Icons/DownloadIcon';
// Helpers
import { 
  chartConfig,
  downloadCSV,
} from './ChartHelpers';

ChartJS.register(ArcElement, Tooltip, Legend);

/* -------------------------------------------------------------------------- */
/*                               DOUGHNUT CHART                               */
/* -------------------------------------------------------------------------- */

/**
 * Component for a standard chartjs line chart.
 * @component
 * @param {Object} props - The component accepts props.
 * @param {Objects[]} props.labelArray - For doughnut chart should only be one object in array. For example: [
  {
    label: 'AMOUNT',
    xKey: 'label',
    yKey: 'values.AMOUNT'
  }
].
 * @param {Objects[]} props.dataArray - An array of chart data. Must be configured in the form [...{xAxisKey: string, values:{...value: number} }]
 * @param {boolean} props.dashboardLoading - Dashboard loading spinner state.
 * @param {boolean} props.theme - Theme selection value.
 * @param {string} [props.chartTitle] - The title of the chart [default='No Title Provided'].
 * @param {string} [props.symbol] - Specifies the format to be applied. Takes one of three values: 'none', 'dollar', or 'percent'. Default is 'dollar'.
 * @param {number} [props.decimal] - The number of decimal places for value in tooltip. Default is 0.
 * @param {object | boolean} [props.filterText] - Object of text indicating the filters chosen. Default is false.
 * @returns A dashboard line chart component in a card.
 */
const DoughnutChart = ({
  labelArray,
  dataArray,
  dashboardLoading,
  theme,
  chartTitle = 'No Title Provided',
  symbol = 'dollar',
  decimal = 0,
  filterText = false
}) => {

  const chartRef = useRef(null);

  /* --------------------------------- Loading -------------------------------- */

  if (dashboardLoading) {
    return <LoadingChart/>
  }
  
  /* ----------------------------- Check Null Data ---------------------------- */
  
  if (chartTitle === null || labelArray === null || dataArray === null) {
    return <NoDataChart/>
  }

  try {
    
    /* ---------------------------------- Theme --------------------------------- */
  
    const selectedThemeColor = theme ? chartConfig.labels.darkThemeColor : chartConfig.labels.lightThemeColor;
  
    /* ------------------------------ Chart Options ----------------------------- */
  
      let options = {
      radius: '85%',
      responsive: true,
      maintainAspectRatio: false,
      parsing: {
        key: labelArray[0].yKey
      },
      plugins: {
        title: {
          display: true,
          text: chartTitle,
          color: selectedThemeColor,
          font: chartConfig.labels.font,
        },
        legend: {
          position: 'bottom',
          labels: {
            color: selectedThemeColor,
            font: chartConfig.labels.font,
          },
        },      
        datalabels: {
          formatter: (value, context) => {
            let sum = context.chart.data.datasets[0].data.reduce((a, b) => a + b, 0);
            let percentage = (value * 100 / sum).toFixed(1);
            if (percentage > 1) {
              return percentage + '%';
            } else {
              return '';
            }
          },
          anchor: (context) => {
            let index = context.dataIndex;
            let chart = context.chart;
            let dataset = chart.data.datasets[0];
            let total = dataset.data.reduce(function(previousValue, currentValue) {
              return previousValue + currentValue;
            });
            let value = dataset.data[index];
          
            if (value / total >= 0.05) {
              return 'center';
            } else {
              return 'end';
            }
          },
          align: (context) => {
            let index = context.dataIndex;
            let chart = context.chart;
            let dataset = chart.data.datasets[0];
            let total = dataset.data.reduce(function(previousValue, currentValue) {
              return previousValue + currentValue;
            });
            let value = dataset.data[index];
          
            if (value / total >= 0.05) {
              return 'center';
            } else {
              return 'end';
            }
          }, 
        },
        tooltip: {
          callbacks: {
            label: (context) => {
              
              const startSymbolChoice = symbol === 'dollar' ? '$' : '';

              // Extract data from context object
              // console.log(context)
              let valuesArray = context.dataset.data.map(element => {
                let key = labelArray[0].yKey;
                let keys = key.split('.'); // Split the string into an array of keys
                // Access the nested property dynamically
                let result = element;
                for (let k of keys) {
                  result = result[k];
                }
                return result;
              });
  
              let pieSliceCategory = context.raw.label;
              
              let value = (Number(context.parsed)).toFixed(decimal);
              let percentage = valuesArray.reduce((a, b) => a + b, 0) > 0 ? ((context.parsed / valuesArray.reduce((a, b) => a + b, 0)) * 100).toFixed(1) + '%' : '';
              let total = valuesArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
              let roundedTotal = (Number(total)).toFixed(decimal);
              
              return [
                `${pieSliceCategory}`,
                `Value: ${startSymbolChoice}${Number(value).toLocaleString("en-US")}`,
                `Share: ${percentage}`,
                `Total: ${startSymbolChoice}${Number(roundedTotal).toLocaleString("en-US")}`
              ]
            }
          }
        },
      },
  
    };
  
    /* -------------------------------- Set Data -------------------------------- */
  
    let data = {
      datasets: [
        {
          data: dataArray,
          backgroundColor: chartConfig.colorArray,
          borderColor: chartConfig.chartGraphics.borderColor,
          borderWidth: chartConfig.chartGraphics.borderWidth,
        }
      ]
    };
  
    /* -------------------------------- Component ------------------------------- */
  
    return (
      <div className={styles.chartBoxHeight}>
        <div className={styles.chartHeight}>
          <Doughnut
            options={options}
            data={data}
            fallbackContent={<p>No Data</p>}
            ref={chartRef}
          />
        </div>
        <div className={`text-end ${styles.downloadButtonDivHeight}`}>
          <Button
            variant="outline-primary"
            size='sm'
            className={`mt-2 fw-bold icon-link icon-link-hover ${styles.downloadButton}`}
            onClick={(event) => downloadCSV(event, chartRef, filterText)}
          >
            <DownloadIcon width={16}/>
            Export Data
          </Button>
        </div>
      </div>
    );
  
  /* ---------------------------------- Error --------------------------------- */
  
  } catch (error) {

    console.log(error);

    return <NoDataChart message={`Chart Error: ${error.message}`}/>
    
  }

}

export default DoughnutChart;