// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import {
  getAuth,
  connectAuthEmulator,
  //createUserWithEmailAndPassword,
  //sendPasswordResetEmail,
  //signOut,
} from "firebase/auth";
import {
  getFirestore,
  connectFirestoreEmulator,
  // query,
  // getDocs,
  // collection,
  // where,
  // addDoc,
} from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

// create-react-app auto generated ('development' or 'production')
const isLocal = process.env.NODE_ENV === "development";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDSIwvLcVsaSd7WIj1HkfEh8BZ_k6p0biQ",
  authDomain: "citydata-46dc9.firebaseapp.com",
  projectId: "citydata-46dc9",
  storageBucket: "citydata-46dc9.appspot.com",
  messagingSenderId: "348439327532",
  appId: "1:348439327532:web:67beae4785016616987289",
  measurementId: "G-LTLV853TRJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = isLocal ? '' : getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
//'us-central1'
const functions = isLocal ? getFunctions(app) : getFunctions(app, 'us-central1');

/* CHANGE DEPENDING ON LOCATION */
const isHome = true;

// emulators

if (isHome) {
  
  // Home computer. Must also add "host": "192.168.2.11" to all emulator port objects in firebase.json
  // For testing on mobile locally (through wifi) go to "192.168.2.11:3000"
  if (isLocal) {
    connectAuthEmulator(auth, "http://192.168.2.11:9099", { disableWarnings: true }); // Required to use the emulator for Firebase Authentication
    connectFirestoreEmulator(db, '192.168.2.11', 8080);
    connectFunctionsEmulator(functions, "192.168.2.11", 5001); // Required to use the emulator for Firebase Functions
  } 

} else {
  
  // Work computer. Must also add "host": "10.0.0.22" to all emulator port objects in firebase.json
  if (isLocal) {
    connectAuthEmulator(auth, "http://10.0.0.22:9099", { disableWarnings: true }); // Required to use the emulator for Firebase Authentication
    connectFirestoreEmulator(db, '10.0.0.22', 8080);
    connectFunctionsEmulator(functions, "10.0.0.22", 5001); // Required to use the emulator for Firebase Functions
  }
}

export {
  auth,
  db,
  functions,
  isLocal
};