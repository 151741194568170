import { masterFilterObject } from "../../configuration/filterCategories";

/**
 * Set key: value pair in local storage.
 * @param {string} key - A string key name.
 * @param {any} value - A value.
 * @returns {void}
 */
export const setStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

/**
 * Get key: value pair from local storage if it exists.
 * @param {string} key - A string key name.
 * @returns {(Any|null)} A value from local storage. Returns null if no value exists.
 */
export const getStorage = (key) => {
  let value = localStorage.getItem(key);
  if (value !== null) {
    let parsedValue = JSON.parse(value);
    return parsedValue;
  } else {
    return null;
  }
};

/**
 * Check if two objects are equal. Values must be simple values and not objects or arrays.
 * @param {object} obj1 - First object to compare.
 * @param {object} obj2 - Second object to compare. 
 * @returns {boolean}
 */
export const deepObjectComparison = (obj1, obj2) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = obj1[key];
    const val2 = obj2[key];

    // Recursively check nested objects
    if (typeof val1 === 'object' && typeof val2 === 'object') {
      if (!deepObjectComparison(val1, val2)) {
        return false;
      }
    } else {
      // If the values are not objects, use strict equality
      if (val1 !== val2) {
        return false;
      }
    }
  }

  return true;
}

/**
 * Check if two arrays are equal. Arrays must contain objects or simple items.
 * @param {array} arr1 - First array of objects to compare.
 * @param {array} arr2 - Second array of objects to compare. 
 * @returns {boolean}
 */
export const deepArrayComparison = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    const obj1 = arr1[i];
    const obj2 = arr2[i];

    // Check if both elements are objects
    if (typeof obj1 === 'object' && typeof obj2 === 'object') {
      if (!deepObjectComparison(obj1, obj2)) {
        return false;
      }
    } else {
      // If the elements are not objects, use strict equality
      if (obj1 !== obj2) {
        return false;
      }
    }
  }

  return true;
}

/**
 * Format large numbers by adding commas and converting them to strings.
 * @param {number} number - A number to add decimals to as a string. 
 * @param {boolean} [preserveDecimals] - A boolean that controls whether to keep or remove decimals [default=false].
 * @returns {string} A string representation of a number with commas.
 */
export const addCommas = (number, preserveDecimals = false) => {
  // Check if the input is a valid number
  if (isNaN(number)) {
    return 'No Data';
  }

  // Convert the number to a string
  const numStr = number.toString();

  // Split the number into integer and decimal parts
  const [integerPart, decimalPart] = numStr.split('.');

  // Use a regular expression to add commas to the integer part
  const integerWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Combine the integer and decimal parts, preserving decimals if needed
  const result = preserveDecimals ? `${integerWithCommas}.${decimalPart || '0'}` : integerWithCommas;

  return result;
}

/**
 * Returns an object containing the key/value pairs to be used to display the applied filters in the TitleCard component.
 * @param {string} dashboardName - The dashboard name.
 * @param {Object} data - The data state.
 * @returns {Object} - An object.
 */
export const createFilterTextObject = (dashboardName, data) => {
  const filterTextObject = {};
  
  masterFilterObject[dashboardName].forEach((filterObject) => {

    const filterValue = data.filter?.[filterObject.uniqueId];

    if (filterValue) {
      filterTextObject[filterObject.filterTextName] = filterValue;
    } else {
      filterTextObject[filterObject.filterTextName] = ['No filter detected'];
    }
  }
)

  return filterTextObject;
}