// React
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// Firebase
import { httpsCallable } from "firebase/functions";
import { functions } from '../services/firebase.config';
// Components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button"
import Table from 'react-bootstrap/Table';
import SignOutButton from "../components/Buttons/SignOutButton";
import Form from "react-bootstrap/Form";
import InputGroup from 'react-bootstrap/InputGroup';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import HemsonAnalyticsBrand from "../components/Icons/HemsonAnalyticsBrand";
import DownloadIcon from '../components/Icons/DownloadIcon';

/* -------------------- Firebase callable function setup -------------------- */

const dataFetch = httpsCallable(functions, 'adminAuth');

/* -------------------------------------------------------------------------- */
/*                                   HELPERS                                  */
/* -------------------------------------------------------------------------- */

/**
 * Generates a password 10 characters long containing at least one uppercase letter, one number, and one symbol (! or _).
 * @returns {string} A radomly generate password.
 */
const passwordStringGenerator = () => {
  const length = 10;
  const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';
  const numberChars = '0123456789';
  const symbolChars = '!_';
  
  const allChars = uppercaseChars + lowercaseChars + numberChars + symbolChars;

  const getRandomChar = (str) => str[Math.floor(Math.random() * str.length)];
  let generatedPassword = 
    getRandomChar(uppercaseChars) + 
    getRandomChar(numberChars) + 
    getRandomChar(symbolChars);

  for (let i = 3; i < length; i++) {
    generatedPassword += getRandomChar(allChars);
  }

  generatedPassword = generatedPassword
    .split('')
    .sort(() => 0.5 - Math.random())
    .join('');

  return generatedPassword;
}

/* -------------------------------------------------------------------------- */
/*                                 Components                                 */
/* -------------------------------------------------------------------------- */

/**
 * Submit button component for the admin page.
 * @components
 * @param {Object} props - The component accepts props.
 * @param {boolean} props.disabled - The disabled state.
 * @returns A submit button.
 */
const SubmitButton = ({
  disabled,
}) => {
  return (
    <Button 
      variant="outline-primary"
      size="sm" 
      type="submit" 
      disabled={disabled}
      className='fw-semibold icon-link icon-link-hover'
    >
      {disabled ? 
        <Spinner size="sm" className='ms-auto'/>
        :
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-return-right ms-auto" viewBox="0 0 16 16">
          <path fillRule="evenodd" d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5"/>
        </svg>
      }
      <span className='me-auto'>Submit</span>
    </Button>
  );
}

/* -------------------------------------------------------------------------- */
/*                              User Query Forms                              */
/* -------------------------------------------------------------------------- */

/**
 * Form to query user data by email.
 * @components
 * @param {Object} props - The component accepts props.
 * @param {function(Event): void} props.handleSubmit - Callback to handle form submission.
 * @param {function(Event): void} props.handleChange - Callback to handle change in query form selection.
 * @param {boolean} props.dataLoading - The dataLoading state.
 * @returns A form component.
 */
const QueryUserForm = ({ handleSubmit, handleChange, dataLoading }) => {
  return (
    <Form onSubmit={event => handleSubmit(event)}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control required type="email" placeholder="example@example.com" name='email' disabled={dataLoading} onChange={event => handleChange(event)} />
        <Form.Text className="text-muted">
          Query user data and authentication for selected email. Email must match exactly.
        </Form.Text>
      </Form.Group>

      <SubmitButton disabled={dataLoading}/>
    </Form>
  );
}

/**
 * Form to query user data by UID.
 * @components
 * @param {Object} props - The component accepts props.
 * @param {function(Event): void} props.handleSubmit - Callback to handle form submission.
 * @param {function(Event): void} props.handleChange - Callback to handle change in query form selection.
 * @param {boolean} props.dataLoading - The dataLoading state.
 * @returns A form component.
 */
const QueryUserWithUidForm = ({ handleSubmit, handleChange, dataLoading }) => {
  return (
    <Form onSubmit={event => handleSubmit(event)}>
      <Form.Group className="mb-3" controlId="uid">
        <Form.Label>UID</Form.Label>
        <Form.Control required placeholder="Enter UID" name='uid' disabled={dataLoading} onChange={event => handleChange(event)} />
        <Form.Text className="text-muted">
          Query user data and authentication for selected UID. UID must match exactly.
        </Form.Text>
      </Form.Group>

      <SubmitButton disabled={dataLoading}/>
    </Form>
  );
}

/**
 * Form to create a new user.
 * @components
 * @param {Object} props - The component accepts props.
 * @param {function(Event): void} props.handleSubmit - Callback to handle form submission.
 * @param {function(Event): void} props.handleChange - Callback to handle change in query form selection.
 * @param {boolean} props.dataLoading - The dataLoading state.
 * @returns A form component.
 */
const CreateUserForm = ({ handleSubmit, handleChange, dataLoading }) => {

  /* ------------------- Admin/Trial are mutually exclusive ------------------- */
  
  const [admin, setAdmin] = useState(false);
  const [trial, setTrial] = useState(false);
  const [password, setPassword] = useState('');

  /**
   * Callback to handle mutual exclusivity of the admin and trial options for the admin checkbox.
   * @param {Event} event - Event object.
   */
  const handleAdminChange = (event) => {
    const checked = event?.target.checked;

    if (checked) {
      
      if (trial) {
        setTrial(false);
      }

      setAdmin(true);

    } else {
      setAdmin(false);
    }
  }

  /**
   * Callback to handle mutual exclusivity of the admin and trial options for the trial checkbox.
   * @param {Event} event - Event object.
   */
  const handleTrialChange = (event) => {
    const checked = event?.target.checked;

    if (checked) {
      
      if (admin) {
        setAdmin(false);
      }

      setTrial(true);

    } else {
      setTrial(false);
    }
  }

  /**
   * Generate a password, trigger the handleChange event and set the password state/value. This also triggers the handleChange which sets the password in the query object.
   */
  const generatePassword = () => {
    const generatedPassword = passwordStringGenerator();

    setPassword(generatedPassword);

    // Trigger the handleChange event to update the form
    const passwordInputEvent = { target: { name: 'password', value: generatedPassword } };
    handleChange(passwordInputEvent);
  };

  /**
   * Used to handle the onChange event. This sets the password state/value. This also triggers the handleChange which sets the password in the query object.
   * @param {Event} event - Event object.
   */
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    handleChange(event); // Ensure the parent component's state is updated
  };

  return (
    <Form onSubmit={event => handleSubmit(event)}>

      <Row className="mb-3">
        <Form.Group as={Col} controlId="displayName">
          <Form.Label>Display Name</Form.Label>
          <Form.Control required name='displayName' placeholder="Display Name" disabled={dataLoading} onChange={event => handleChange(event)} />
        </Form.Group>
        
        <Form.Group as={Col} controlId="municipality">
          <Form.Label>Municipality/Organization</Form.Label>
          <Form.Control required name='municipality' placeholder="Municipality/Organization" disabled={dataLoading} onChange={event => handleChange(event)} />
        </Form.Group>
      </Row>

      <Row className="mb-3">
        <Form.Group as={Col} controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control required type="email" name='email' placeholder="example@example.com" disabled={dataLoading} onChange={event => handleChange(event)} />
        </Form.Group>

        <Form.Group as={Col} controlId="password">
          <Form.Label>Password</Form.Label>
          <InputGroup>
            <Form.Control required type="text" name='password' placeholder="Password" disabled={dataLoading} onChange={handlePasswordChange} value={password}/>
            <Button variant="outline-secondary" id="create-password-newuser" onClick={generatePassword}>
              Create PS
            </Button>
          </InputGroup>
          <Form.Text className="text-muted">
            Password must be set for all users.
          </Form.Text>
        </Form.Group>
      </Row>

      <Row className="mb-3">
        <Form.Group as={Col} controlId="phoneNumber">
          <Form.Label>Phone Number</Form.Label>
          <Form.Control name='phoneNumber' placeholder="exp. +11231231234" disabled={dataLoading} onChange={event => handleChange(event)} />
          <Form.Text className="text-muted">
            Must have "+1" and no spaces/symbols
          </Form.Text>
        </Form.Group>

        <Form.Group as={Col} controlId="photoURL">
          <Form.Label>Photo URL</Form.Label>
          <Form.Control name='photoURL' placeholder="Photo URL" disabled={dataLoading} onChange={event => handleChange(event)} />
        </Form.Group>
      </Row>

      <Form.Group className="mb-3" id="emailVerified">
        <Form.Check type="checkbox" name='emailVerified' label="Email Verified" disabled={dataLoading} onChange={event => handleChange(event)} />
        <Form.Text className="text-muted">
          Check this if the user comes from a municipality and provides a municipal email.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3" id="disabled">
        <Form.Check type="checkbox" name='disabled' label="Disabled" disabled={dataLoading} onChange={event => handleChange(event)} />
        <Form.Text className="text-muted">
          Check this to disable the user's account. Should be unchecked for most cases.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3" id="admin">
        <Form.Check type="checkbox" name='admin' label="Admin" disabled={dataLoading} onChange={event => {handleChange(event); handleAdminChange(event);}} checked={admin} />
        <Form.Text className="text-muted">
          Check this to enable admin priviledges for a user. Only for Hemson staff.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3" id="trial">
        <Form.Check type="checkbox" name='trial' label="Trial Account" disabled={dataLoading} onChange={event => {handleChange(event); handleTrialChange(event);}} checked={trial}/>
        <Form.Text className="text-muted">
          Check this to create a trial account for 10 days. Leave unchecked for a full access account.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3" id="autoCreation">
        <Form.Check type="checkbox" name='autoCreation' label="Auto Creation" disabled={dataLoading} onChange={event => handleChange(event)} />
        <Form.Text className="text-muted">
          Check this to allow the user to set their own password. Otherwise the password set above is sent to the user directly.
        </Form.Text>
      </Form.Group>

      <SubmitButton disabled={dataLoading}/>
    </Form>
  );
}

/**
 * Form to update an existing user based on existing email.
 * @components
 * @param {Object} props - The component accepts props.
 * @param {function(Event): void} props.handleSubmit - Callback to handle form submission.
 * @param {function(Event): void} props.handleChange - Callback to handle change in query form selection.
 * @param {boolean} props.dataLoading - The dataLoading state.
 * @returns A form component.
 */
const UpdateUserForm = ({ handleSubmit, handleChange, dataLoading, data }) => {
 
  const [displayName, setDisplayName] = useState('');
  const [organization, setOrganization] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [photoURL, setPhotoURL] = useState('');
  const [email, setEmail] = useState('');
  const [emailVerified, setEmailVerified] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [trial, setTrial] = useState(false);

  const [password, setPassword] = useState('');

  // Fill form when a user is queried and data is available
  useEffect(() => {
    setDisplayName(data[0]?.displayName || '');
    setOrganization(data[0]?.municipality || '');
    setPhoneNumber(data[0]?.phoneNumber || '');
    setPhotoURL(data[0]?.photoURL || '');
    setEmail(data[0]?.email || '');
    setEmailVerified(data[0]?.emailVerified || false);
    setDisabled(data[0]?.disabled || false);
    setAdmin(data[0]?.admin || false);
    setTrial(data[0]?.trial || false);
  }, [data]);

  /* ------------------- Admin/Trial are mutually exclusive ------------------- */

  /**
   * Callback to handle mutual exclusivity of the admin and trial options for the admin checkbox.
   * @param {Event} event - Event object.
   */
  const handleAdminChange = (event) => {
    const checked = event?.target.checked;

    if (checked) {
      
      if (trial) {
        setTrial(false);
      }

      setAdmin(true);

    } else {
      setAdmin(false);
    }
  }

  /**
   * Callback to handle mutual exclusivity of the admin and trial options for the trial checkbox.
   * @param {Event} event - Event object.
   */
  const handleTrialChange = (event) => {
    const checked = event?.target.checked;

    if (checked) {
      
      if (admin) {
        setAdmin(false);
      }

      setTrial(true);

    } else {
      setTrial(false);
    }
  }

  /**
   * Generate a password, trigger the handleChange event and set the password value. This also triggers the handleChange which sets the password in the query object.
   */
  const generatePassword = () => {
    const generatedPassword = passwordStringGenerator();

    setPassword(generatedPassword);

    // Trigger the handleChange event to update the form
    const passwordInputEvent = { target: { name: 'password', value: generatedPassword } };
    handleChange(passwordInputEvent);
  };

  /**
   * Used to handle the onChange event. This sets the password state/value. This also triggers the handleChange which sets the password in the query object.
   * @param {Event} event - Event object.
   */
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    handleChange(event); // Ensure the parent component's state is updated
  };

  return (
    <Form onSubmit={event => handleSubmit(event)}>

      <Row className="mb-3">
        <Form.Group as={Col} controlId="displayName">
          <Form.Label>Update Display Name</Form.Label>
          <Form.Control name='displayName' placeholder="Display Name" disabled={dataLoading} onChange={event => {handleChange(event); setDisplayName(event.target.value)}} value={displayName}/>
        </Form.Group>
        
        <Form.Group as={Col} controlId="municipality">
          <Form.Label>Update Municipality/Organization</Form.Label>
          <Form.Control name='municipality' placeholder="Municipality/Organization" disabled={dataLoading} onChange={event => {handleChange(event); setOrganization(event.target.value)}} value={organization}/>
        </Form.Group>
      </Row>

      <Row className="mb-3">
        <Form.Group as={Col} controlId="email">
          <Form.Label>Existing Email</Form.Label>
          <Form.Control required type="email" name='email' placeholder="example@example.com" disabled={dataLoading} onChange={event => {handleChange(event); setEmail(event.target.value)}} value={email}/>
        </Form.Group>

        <Form.Group as={Col} controlId="newEmail">
          <Form.Label>Update Email</Form.Label>
          <Form.Control type="email" name='newEmail' placeholder="example@example.com" disabled={dataLoading} onChange={event => handleChange(event)} />
        </Form.Group>

        <Form.Group as={Col} controlId="password">
          <Form.Label>Update Password</Form.Label>
          <InputGroup>
            <Form.Control type="text" name='password' placeholder="Password" disabled={dataLoading} onChange={handlePasswordChange} value={password}/>
            <Button variant="outline-secondary" id="create-password-newuser" onClick={generatePassword}>
              Create PS
            </Button>
          </InputGroup>
        </Form.Group>
      </Row>

      <Row className="mb-3">
        <Form.Group as={Col} controlId="phoneNumber">
          <Form.Label>Update Phone Number</Form.Label>
          <Form.Control name='phoneNumber' placeholder="exp. +11231231234" disabled={dataLoading} onChange={event => {handleChange(event); setPhoneNumber(event.target.value)}} value={phoneNumber}/>
          <Form.Text className="text-muted">
            Must have "+1" and no spaces/symbols
          </Form.Text>
        </Form.Group>

        <Form.Group as={Col} controlId="photoURL">
          <Form.Label>Update Photo URL</Form.Label>
          <Form.Control name='photoURL' placeholder="Photo URL" disabled={dataLoading} onChange={event => {handleChange(event); setPhotoURL(event.target.value)}} value={photoURL}/>
        </Form.Group>
      </Row>

      <Form.Group className="mb-3" id="emailVerified">
        <Form.Check type="checkbox" name='emailVerified' label="Update Email Verified" disabled={dataLoading} onChange={event => {handleChange(event); setEmailVerified(event.target.checked)}} checked={emailVerified}/>
        <Form.Text className="text-muted">
          Check this if the user comes from a municipality and provides a municipal email.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3" id="disabled">
        <Form.Check type="checkbox" name='disabled' label="Update Disabled" disabled={dataLoading} onChange={event => {handleChange(event); setDisabled(event.target.checked)}} checked={disabled}/>
        <Form.Text className="text-muted">
          Check this to disable the user's account. Should be unchecked for most cases.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3" id="admin">
        <Form.Check type="checkbox" name='admin' label="Update Admin" disabled={dataLoading} onChange={event => {handleChange(event); handleAdminChange(event);}} checked={admin} />
        <Form.Text className="text-muted">
          Check this to enable admin priviledges for a user. Only for Hemson staff.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3" id="trial">
        <Form.Check type="checkbox" name='trial' label="Update Trial" disabled={dataLoading} onChange={event => {handleChange(event); handleTrialChange(event);}} checked={trial} />
        <Form.Text className="text-muted">
          Check this to create a trial account for 10 days. Leave unchecked for a full access account.
        </Form.Text>
      </Form.Group>

      <SubmitButton disabled={dataLoading}/>
    </Form>
  );
}

/**
 * Form to delete a user based on email.
 * @components
 * @param {Object} props - The component accepts props.
 * @param {function(Event): void} props.handleSubmit - Callback to handle form submission.
 * @param {function(Event): void} props.handleChange - Callback to handle change in query form selection.
 * @param {boolean} props.dataLoading - The dataLoading state.
 * @returns A form component.
 */
const DeleteUserForm = ({ handleSubmit, handleChange, dataLoading }) => {
  return (
    <Form onSubmit={event => handleSubmit(event)}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control required type="email" placeholder="example@example.com" name='email' disabled={dataLoading} onChange={event => handleChange(event)} />
        <Form.Text className="text-muted">
          Delete user data and authentication for selected email. Case sensitive. Email must match exactly.
        </Form.Text>
      </Form.Group>

      <SubmitButton disabled={dataLoading}/>
    </Form>
  );
}

/**
 * Form to query all users.
 * @components
 * @param {Object} props - The component accepts props.
 * @param {function(Event): void} props.handleSubmit - Callback to handle form submission.
 * @param {function(Event): void} props.handleChange - Callback to handle change in query form selection.
 * @param {boolean} props.dataLoading - The dataLoading state.
 * @returns A form component.
 */
const ListAllUsersForm = ({ handleSubmit, handleChange, dataLoading }) => {
  return (
    <Form onSubmit={event => handleSubmit(event)}>
      <Form.Group className="mb-3" controlId="list">
        <Form.Label>Check the box and click Submit to get a list of all users.</Form.Label>
        <Form.Check type="checkbox" name='list' label="Show me all users" disabled={dataLoading} onChange={event => handleChange(event)} />
      </Form.Group>      

      <SubmitButton disabled={dataLoading}/>
    </Form>
  );
}

/**
 * Form to reset a user's password.
 * @components
 * @param {Object} props - The component accepts props.
 * @param {function(Event): void} props.handleSubmit - Callback to handle form submission.
 * @param {function(Event): void} props.handleChange - Callback to handle change in query form selection.
 * @param {boolean} props.dataLoading - The dataLoading state.
 * @returns A form component.
 */
const ResetPasswordForm = ({ handleSubmit, handleChange, dataLoading }) => {
  return (
    <Form onSubmit={event => handleSubmit(event)}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control required type="email" placeholder="example@example.com" name='email' disabled={dataLoading} onChange={event => handleChange(event)} />
        <Form.Text className="text-muted">
          Send a password reset email to the selected email. Email must match exactly.
        </Form.Text>
      </Form.Group>

      <SubmitButton disabled={dataLoading}/>
    </Form>
  );
}

/* -------------------------------------------------------------------------- */
/*                                   Helpers                                  */
/* -------------------------------------------------------------------------- */

/**
 * Function to convert table data to CSV format
 * @param {object} event - The onClick event object.
 * @param {ref} tableRef - A React useRef reference to a table.
 * @returns string
 */
const downloadTableToCSV = (event, tableRef) => {
  const table = tableRef.current;
  if (!table) return;

  const rows = table.querySelectorAll('tr');
  let csvContent = 'data:text/csv;charset=utf-8,';

  rows.forEach((row, index) => {
    const rowData = [];
    row.querySelectorAll('td, th').forEach((cell) => {
      rowData.push(cell.textContent.trim());
    });
    csvContent += index === 0 ? rowData.join(',') : '\n' + rowData.join(',');
  });

  // Add footnote
  csvContent += `\n\nExported on ${new Date()} from app.hemsonanalytics.com admin dashboard.\nPowered by Hemson`;

  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.href = encodedUri;
  link.download = `hemson_user_data.csv`;
  link.click();
};

/**
   * Checks if an object is empty, returns true if it is.
   * @param {Object} object - An object.
   * @returns boolean
   */
const isObjectEmpty = (object) => {
  return (
    object &&
    Object.keys(object).length === 0 &&
    object.constructor === Object
  ); 
}

/* -------------------------------------------------------------------------- */
/*                            Admin Page Component                            */
/* -------------------------------------------------------------------------- */

/**
 * The admin page at route '/admin'.
 * @component
 * @returns The admin page component.
 */
const Admin = () => {
 
  const tableRef = useRef(null);
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [query, setQuery] = useState({});
  const [dataLoading, setDataLoading] = useState(false);
  const [dataError, setDataError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  /* ---------------------------- Handler Callbacks --------------------------- */

  /**
   * Callback to handle the user query.
   * @param {Event} event - Event object.
   */
  const queryHandler = async (event) => {
    event.preventDefault();
    setDataLoading(true);
    setDataError(false);

    try {
      let response = await dataFetch(query);
  
      setData(response.data)
      //console.log(response)
    
    } catch (error) {
      
      setDataError(true);
      
      const code = error?.code || 'None';
      const message = error?.message || 'None';
      const details = error?.details || {};
      const detailedCode = details?.detailedCode || 'None';
      const detailedMessage = details?.detailedMessage || 'None';
      
      setErrorMessage(
        <div>
          <p className="h5">Error in action.</p>
          <p><span className="fw-semibold">Code:</span> {code}</p>
          <p><span className="fw-semibold">Message:</span> {message}</p>
          <p><span className="fw-semibold">Detailed Code:</span> {detailedCode}</p>
          <p><span className="fw-semibold">Detailed Message:</span> {detailedMessage}</p>
        </div>
      );
    
    } finally {
      
      setDataLoading(false);
    
    }
  }
  
  /**
   * Callback to handle change in query form selection.
   * @param {Event} event - Event object.
   */
  const formChangeHandler = (event) => {
    const { name, value, checked, type } = event.target;
    
    // check if value is from a checkbox
    let verifiedValue = type === 'checkbox' ? checked : value;
    
    // Check that admin and trial are mutually exclusive
    let checkedQuery = structuredClone(query);

    if (name === 'admin' && verifiedValue === true) {
      checkedQuery.trial = false;
    }

    if (name === 'trial' && verifiedValue === true) {
      checkedQuery.admin = false;
    }

    setQuery({
      ...checkedQuery,
      [name]: verifiedValue,
      action: action
    });
  }

  /**
   * Callback to specifically handle query of a user by email for the "Update User" action.
   * @param {Event} event - Event object.
   */
  const handleUpdateUserEmailChange = (event) => {
    const { name, value } = event.target;
    setQuery({
      [name]: value,
      action: 'Query User'
    });
  }

  /* ----------------------------- Form Components ---------------------------- */

  const actions = [
    {
      action: 'Create User',
      form: <CreateUserForm handleSubmit={queryHandler} handleChange={formChangeHandler} disabled={dataLoading}/>,
    },
    {
      action: 'Update User',
      form: (
        <>
          <div className="mb-2"><h4>Search User</h4></div>
          <QueryUserForm handleSubmit={queryHandler} handleChange={handleUpdateUserEmailChange} disabled={dataLoading}/>
          <hr className="border border-primary-subtle opacity-75"/>
          <div className="mt-4 mb-2"><h4>Update User</h4></div>
          <UpdateUserForm data={data} handleSubmit={queryHandler} handleChange={formChangeHandler} disabled={dataLoading}/>
        </>
      ),
    }, 
    {
      action: 'Query User',
      form: <QueryUserForm handleSubmit={queryHandler} handleChange={formChangeHandler} disabled={dataLoading}/>,
    },
    {
      action: 'Query UID',
      form: <QueryUserWithUidForm handleSubmit={queryHandler} handleChange={formChangeHandler} disabled={dataLoading}/>,
    },
    {
      action: 'Delete User',
      form: <DeleteUserForm handleSubmit={queryHandler} handleChange={formChangeHandler} disabled={dataLoading}/>,
    },
    {
      action: 'List Users',
      form: <ListAllUsersForm handleSubmit={queryHandler} handleChange={formChangeHandler} disabled={dataLoading}/>,
    },
    {
      action: 'Reset Password',
      form: <ResetPasswordForm handleSubmit={queryHandler} handleChange={formChangeHandler} disabled={dataLoading}/>
    }
  ];

  const [action, setAction] = useState(actions[0].action);

  /**
   * Function that returns a query form component based on action selection.
   * @returns {React.JSX.Element} A query form.
   */
  const actionForm = () => {
    const actionObjectChosen = actions.find(actionObject => actionObject.action === action);
    return actionObjectChosen.form;
  }

  // Output table headers
  const dataKeys = [
    'uid',
    'displayName',
    'email',
    'municipality',
    'phoneNumber',
    'emailVerified',
    'disabled',
    'admin',
    'trial',
    'message'
  ];

  const expand = 'lg';

  /* ------------------------- Data Download Component ------------------------ */

  /**
   * Returns a button component or message based on several states: dataLoading, dataError or data.
   * @returns {React.JSX.Element} A button component.
   */
  const queryResultText = () => {
    if (dataLoading) {
      return 'Query Result: Loading...'
    } else if (dataError) {
      return (<span>Query Result: <span className="text-danger">Error</span></span>)
    } else if (data.length > 0) {
      return (
        <Row>
          <Col><span>Query Result: <span className="text-success">Success</span></span></Col>
          <Col className="text-end">
            <Button 
              variant="outline-primary" 
              size="sm"  
              className="fw-semibold icon-link icon-link-hover" 
              onClick={event => downloadTableToCSV(event, tableRef)}
            >
              <DownloadIcon width={16}/>
              Export
            </Button>
          </Col>
        </Row>
      )
    } else {
      return 'Query Result';
    }
  }

  /* -------------------------------- Component ------------------------------- */

  return (
    <>
      <Navbar key={expand} expand={expand} className="bg-primary-subtle mb-3 sticky-top">
        <Container>
          <HemsonAnalyticsBrand text={'Admin Dashboard'}/>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${expand}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <HemsonAnalyticsBrand text={'Admin Dashboard'}/>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1">
                <Button size='sm' variant="outline-primary" className='me-lg-2 mb-2 mb-lg-0 fw-bold icon-link icon-link-hover' onClick={() => navigate('/')}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-graph-up ms-auto" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M0 0h1v15h15v1H0zm14.817 3.113a.5.5 0 0 1 .07.704l-4.5 5.5a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61 4.15-5.073a.5.5 0 0 1 .704-.07"/>
                  </svg>
                  <span className="me-auto">Dashboards</span>
                </Button>
                <SignOutButton/>
              </Nav>

            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
      
      <Container>
        
        <Row className="mb-5">
          <Col lg={4} className="mb-3">
            <Card className="bg-primary-subtle">
            <Card.Header as='h5'>
                Action Menu
              </Card.Header>
              <Card.Body>
                <Form.Group className="mb-4">
                  <Form.Label>Select an action</Form.Label>
                  <Form.Select
                    disabled={dataLoading}
                    aria-label="Select an Action."
                    onChange={(event) => {
                      setAction(event.target.value);
                      setQuery({});
                      setData([]);
                      setDataError(false);
                      setErrorMessage('');
                    }}
                  >
                    { actions.map(actionObject => <option key={actionObject.action} value={actionObject.action}>{actionObject.action}</option>) }
                  </Form.Select>
                </Form.Group>
              </Card.Body>
            </Card>
          </Col>

          <Col lg={8}>
            <Card className="bg-primary-subtle">
              <Card.Body>{ actionForm() }</Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mb-5">
          <Col>
            <Card className="bg-primary-subtle">
              <Card.Header as='h5'>
                {queryResultText()}
              </Card.Header>
              <Card.Body>
                <Row>
                  
                  {dataError ? 
                    
                    <Col>
                      {errorMessage}
                    </Col>
                    
                    :
                    
                    <Col className="overflow-auto">
                      {dataLoading ? 
                        <Spinner/> 
                        :
                        data.length === 0 ? '' : 
                        <Table striped bordered hover responsive='lg' ref={tableRef}>
                          <thead>
                            <tr key={Math.random()}>
                              {dataKeys.map(keyValue => <th key={Math.random()}>{keyValue}</th>)}
                            </tr>
                          </thead>
                          <tbody>
                            {data.map(row => {
                                return (
                                  <tr key={Math.random()}>
                                    {dataKeys.map(keyValue => <td key={Math.random()}>{`${row[keyValue]}`}</td>)}
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </Table>
                      }
                    </Col>
                  
                  }
                
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>

      </Container>
    </>
  );
}

export default Admin;