// Styles
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';
// Components
import DashboardSelect from "../Forms/DashboardSelect";
import MunicipalitySelect from "../Forms/MunicipalitySelect";
import YearSelect from '../Forms/YearSelect';
import FilterTypeahead from '../Forms/FilterTypeahead';
import { dashboardObject } from '../../configuration/dashboardNames';
// Helpers
import { 
  masterFilterObject
} from '../../configuration/filterCategories';

/**
 * The dashboard menu containing dashboard controls.
 * @components
 * @param {Object} props - The component accepts props.
 * @param {string} props.dashboard - Dashboard selection value.
 * @param {function(Event): void} props.handleDashboardChange - Callback to handle dashboard change.
 * @param {Objects[]} props.municipality - Municipality selection value.
 * @param {function(Event): void} props.handleMunicipalityChange - Callback to handle municipality change.
 * @param {Objects[]} props.municipalities - Municipalities selection value.
 * @param {function(Event): void} props.handleMunicipalitiesChange - Callback to handle municipalities change.
 * @param {number[]} props.years - Array of start and end years.
 * @param {function(Event): void} props.handleStartYearChange - Callback to handle start year change.
 * @param {function(Event): void} props.handleEndYearChange - Callback to handle end year change. 
 * @param {boolean} props.dashboardLoading - Dashboard loading spinner state.
 * @param {Objects[]} props.preset - The preset municipalities array.
 * @param {object} props.filter - A custom filter object.
 * @param {function(Event): void} props.handleFilterChange - Callback to handle filter change.
 * @returns The menu component for the dashboard. 
 */
const DashboardMenu = ({ 
  dashboard, 
  handleDashboardChange,
  municipality,
  handleMunicipalityChange,
  municipalities,
  handleMunicipalitiesChange,
  years,
  handleStartYearChange,
  handleEndYearChange,
  dashboardLoading,
  preset,
  filter,
  handleFilterChange
}) => {

  const FilterGenerator = () => {
    
    switch (dashboard) {
      
      case 'Operating Expenditure':
        
        return (
          <>
            <FilterTypeahead
              filter={filter}
              handleFilterChange={handleFilterChange}
              dashboard={dashboard}
              dashboardLoading={dashboardLoading}
              uniqueId={masterFilterObject['Operating Expenditure'][0].uniqueId}
              typeaheadOptions={masterFilterObject['Operating Expenditure'][0].typeaheadOptions}
              formLabel={masterFilterObject['Operating Expenditure'][0].filterTextName}
              placeholder={'Select service areas...'}
              tooltipText={'✔ This filter allows for multiple selections. The filter will automatically change if mutually exclusive options are selected.'}
              category={true}
            />
            <FilterTypeahead
              filter={filter}
              handleFilterChange={handleFilterChange}
              dashboard={dashboard}
              dashboardLoading={dashboardLoading}
              uniqueId={masterFilterObject['Operating Expenditure'][1].uniqueId}
              typeaheadOptions={masterFilterObject['Operating Expenditure'][1].typeaheadOptions}
              formLabel={masterFilterObject['Operating Expenditure'][1].filterTextName}
              placeholder={'Select expenditure type....'}
              tooltipText={'✔ This filter allows for multiple selections. The filter will automatically change if mutually exclusive options are selected.'}
            />
          </>
        );
      
      case 'User Fees':
        
        return (
          <>
            <FilterTypeahead
              filter={filter}
              handleFilterChange={handleFilterChange}
              dashboard={dashboard}
              dashboardLoading={dashboardLoading}
              uniqueId={masterFilterObject['User Fees'][0].uniqueId}
              typeaheadOptions={masterFilterObject['User Fees'][0].typeaheadOptions}
              formLabel={masterFilterObject['User Fees'][0].filterTextName}
              placeholder={'Select service areas...'}
              tooltipText={'✔ This filter allows for a set number of single selections.'}
            />
          </>
        );

      case 'Workforce':

        return (
          <>
            <FilterTypeahead
              filter={filter}
              handleFilterChange={handleFilterChange}
              dashboard={dashboard}
              dashboardLoading={dashboardLoading}
              uniqueId={masterFilterObject['Workforce'][0].uniqueId}
              typeaheadOptions={masterFilterObject['Workforce'][0].typeaheadOptions}
              formLabel={masterFilterObject['Workforce'][0].filterTextName}
              placeholder={'Select service areas...'}
              tooltipText={'✔ This filter allows for multiple selections. The filter will automatically change if mutually exclusive options are selected.'}
              category={true}
            />
            <FilterTypeahead
              filter={filter}
              handleFilterChange={handleFilterChange}
              dashboard={dashboard}
              dashboardLoading={dashboardLoading}
              uniqueId={masterFilterObject['Workforce'][1].uniqueId}
              typeaheadOptions={masterFilterObject['Workforce'][1].typeaheadOptions}
              formLabel={masterFilterObject['Workforce'][1].filterTextName}
              placeholder={'Select employee type....'}
              tooltipText={'✔ This filter allows for multiple selections. The filter will automatically change if mutually exclusive options are selected.'}
            />
          </>
        );
      
      case 'Region Operating Expenditure':
        
        return (
          <>
            <FilterTypeahead
              filter={filter}
              handleFilterChange={handleFilterChange}
              dashboard={dashboard}
              dashboardLoading={dashboardLoading}
              uniqueId={masterFilterObject['Operating Expenditure'][0].uniqueId}
              typeaheadOptions={masterFilterObject['Operating Expenditure'][0].typeaheadOptions}
              formLabel={masterFilterObject['Operating Expenditure'][0].filterTextName}
              placeholder={'Select service areas...'}
              tooltipText={'✔ This filter allows for multiple selections. The filter will automatically change if mutually exclusive options are selected.'}
              category={true}
            />
            <FilterTypeahead
              filter={filter}
              handleFilterChange={handleFilterChange}
              dashboard={dashboard}
              dashboardLoading={dashboardLoading}
              uniqueId={masterFilterObject['Operating Expenditure'][1].uniqueId}
              typeaheadOptions={masterFilterObject['Operating Expenditure'][1].typeaheadOptions}
              formLabel={masterFilterObject['Operating Expenditure'][1].filterTextName}
              placeholder={'Select expenditure type....'}
              tooltipText={'✔ This filter allows for multiple selections. The filter will automatically change if mutually exclusive options are selected.'}
            />
          </>
        );
    
      /* ------------------- Insert New Filters Above This Line ------------------- */
      default:
        return '';
    }
  }

  return (
    <>
      <DashboardSelect
        dashboard={dashboard}
        handleDashboardChange={handleDashboardChange}
        dashboardLoading={dashboardLoading}
      />
      <MunicipalitySelect
        dashboard={dashboard}
        municipality={municipality}
        handleMunicipalityChange={handleMunicipalityChange}
        municipalities={municipalities}
        handleMunicipalitiesChange={handleMunicipalitiesChange}
        dashboardLoading={dashboardLoading}
        preset={preset}
        upperTier={dashboardObject[dashboard].upperTier}
      />
      <YearSelect
        dashboard={dashboard}
        years={years}
        handleStartYearChange={handleStartYearChange}
        handleEndYearChange={handleEndYearChange}
        dashboardLoading={dashboardLoading}
        hide={dashboardObject[dashboard].hideYearFilter}
      />
      <FilterGenerator/>
    </>
  );
}

export default DashboardMenu;